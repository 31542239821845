import { Button, Col, Image, Popconfirm, Row } from "antd";
import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { deleteFile } from "../../features/deleteFileSlice";
import css from "../../styles/showFile.module.scss";

const ShowFile = ({ file, id, from, dis, imgSrc }) => {
  const dispatch = useDispatch();
  return (
    <div className={css.container}>
      <Col className={css.main}>
        {imgSrc ? (
          <Image
            src={URL.createObjectURL(imgSrc)}
            style={{ objectFit: "cover" }}
          />
        ) : (
          <Image src={file?.url} style={{ objectFit: "cover" }} />
        )}
      </Col>
      <Popconfirm
        className={css.deleteIconContainer}
        overlayClassName={css.pop}
        title="Are you sure?"
        okText="Yes"
        cancelText="No"
        onConfirm={() =>
          dispatch(
            deleteFile({
              fileId: file?.uid,
              Id: id,
              dis: dis,
              from: from,
            })
          )
        }
      >
        {!imgSrc && (
          <Row align={"middle"} className={css.deleteContent}>
            <Col>
              <Button danger icon={<RiDeleteBin6Line />}>
                Delete
              </Button>
            </Col>
          </Row>
        )}
      </Popconfirm>
    </div>
  );
};

export default ShowFile;
