import { Button, Row } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { activateUser, deactivateUser } from "../../features/userSlice";
import css from "../../styles/addModal.module.scss";

const DeleteConfirmation = (props) => {
  const dispatch = useDispatch();

  const handleCancel = () => {
    props.setDeleteModal(false);
  };
  const handleOk = () => {
    if (props.fromDelete) {
      dispatch(props.delete(props?.id));
    } else if (props.fromDeactivate) {
      dispatch(deactivateUser({ id: props?.id, from: props.from }));
    } else if (props.fromActivate) {
      dispatch(activateUser({ id: props?.id, from: props.from }));
    }
    props.setDeleteModal(false);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        marginTop: "2rem",
      }}
    >
      {props.fromDeactivate && <h6>Are you sure you want to deactivate?</h6>}
      {props.fromActivate && <h6>Are you sure you want to activate?</h6>}{" "}
      {props.fromDelete && <h6>Are you sure you want to delete?</h6>}
      <Row justify={"end"} style={{ gap: "1rem" }}>
        <Button danger onClick={handleCancel} className={css.cancelButton}>
          Cancel
        </Button>
        <Button onClick={handleOk} type="primary">
          {props.fromDeactivate && "Deactivate"}
          {props.fromActivate && "Activate"}
          {!props.fromDeactivate && !props.fromActivate && "Delete"}
        </Button>
      </Row>
    </div>
  );
};

export default DeleteConfirmation;
