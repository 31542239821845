import { Button, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReferUserCount } from "../../features/badgeSlice";
import { verifyDocument } from "../../features/IDVerificationSlice";
import { getBadges } from "../../features/rewardSlice";
import css from "../../styles/addModal.module.scss";

const VerifyConfirmation = (props) => {
  useEffect(() => {
    dispatch(getBadges());

    if (props?.referBy) {
      dispatch(getReferUserCount(props.referBy));
    }
  }, []);

  const badges = useSelector((state) => state.reward.badges);
  const userReferCount = useSelector((state) => state.badge.userReferCount);

  const referBadges = badges?.filter(
    (item) => item?.attributes?.metaData?.related === "refer friend"
  );

  const dispatch = useDispatch();

  const handleCancel = () => {
    props.setVerifyModal(false);
  };

  const handleOk = () => {
    dispatch(
      verifyDocument({
        id: props.id,
        userId: props.userId,
        badges,
        body: props.body,
        phoneVerified: props.phoneVerified,
        referBy: props.referBy,
        userReferCount,
        referBadges,
      })
    );
    props.setVerifyModal(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        marginTop: "2rem",
      }}
    >
      <h6>Are you sure you want to verify?</h6>

      <Row justify={"end"} style={{ gap: "1rem" }}>
        <Button danger onClick={handleCancel} className={css.cancelButton}>
          Cancel
        </Button>
        <Button onClick={handleOk} type="primary">
          Verify
        </Button>
      </Row>
    </div>
  );
};

export default VerifyConfirmation;
