import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import css from "../../styles/Details/userDetail.module.scss";
import BadgeTab from "../detailTabs/BadgeTab";
import FollowerTab from "../detailTabs/FollowerTab";
import DonationTab from "../detailTabs/DonationTab";
import AttachmentTab from "../detailTabs/AttachmentTab";
import Questionares from "../detailTabs/Questionares";
import FollowingTab from "../detailTabs/FollowingTab";
import { api } from "../../Api";
import { BASEURL } from "../../Url";
import DonorForm from "../detailTabs/DonorForm";
const qs = require("qs");

const DonorDetailTabs = ({ userId, userSettings }) => {
  const donorInfo = useSelector((state) => state.donorList.donorDetail);
  const [followingInfo, setFollwoingInfo] = useState();
  const dispatch = useDispatch();


  useEffect(() => {
    const getFollwingInfo = async () => {
      const query = qs.stringify({
        populate: ["followedBy", "followedTo.avatar"],
        filters: {
          followedBy: {
            id: userId,
          },
        },
      });
      const res = await api.get(`${BASEURL}/followers?${query}`);
      setFollwoingInfo(res.data);
    };
    getFollwingInfo();
  }, [dispatch, userId]);

  console.log(followingInfo, "fool");

  // console.log(donorInfo?.data?.attributes?.user?.data?.attributes);

  const tabItems = [
    {
      label: "Donation",
      key: "1",
      children: (
        <DonationTab userId={donorInfo?.data?.attributes?.user?.data?.id} />
      ),
    },

    {
      label: `Badges(${donorInfo?.data?.attributes?.user?.data?.attributes?.badges?.data.length})`,
      key: "2",
      children: (
        <BadgeTab
          info={
            donorInfo?.data?.attributes?.user?.data?.attributes?.badges?.data
          }
        />
      ),
    },

    {
      label: "Donor Form",
      key: "3",
      children: (
        <DonorForm donorData={donorInfo?.data} userSettings={userSettings} />
      ),
    },

    {
      label: "Attachments",
      key: "4",
      children: (
        <AttachmentTab
          attachments={donorInfo?.data?.attributes?.attachments?.data}
        />
      ),
    },

    {
      label: `Followers(${donorInfo?.data?.attributes?.user?.data?.attributes?.followers?.data?.length})`,
      key: "6",
      children: (
        <FollowerTab
          info={
            donorInfo?.data?.attributes?.user?.data?.attributes?.followers?.data
          }
        />
      ),
    },
    {
      label: `Followings(${followingInfo?.data?.length})`,
      key: "7",
      children: <FollowingTab info={followingInfo?.data} />,
    },
  ];
  return (
    <div className={css.tabs}>
      <Tabs defaultActiveKey="1" items={tabItems} />
    </div>
  );
};

export default DonorDetailTabs;
