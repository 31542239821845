import axios from "axios";
import { collection, getDocs, query, where } from "firebase/firestore";
import { api } from "../Api";
import { db } from "../FirebaseConfig";
import { BASEURL } from "../Url";

export const getFCM = async (userId, title, body, screen, id) => {
  console.log(userId, title, body, screen, id, "hello");
  const q = query(collection(db, "tokens"), where("uid", "==", userId));
  await getDocs(q).then((querySnapshot) => {
    querySnapshot.docs?.map((item) => {
      SendPushNotification(item?.data()?.token, title, body, screen, id);
    });
  });
};

export const SendPushNotification = (
  token,
  title = "Hamro blood bank.",
  body = "Hamro blood bank sent notification.",
  screen,
  id
) => {
  console.log(token, "token", screen, id, "hello");
  api
    .get(`${BASEURL}/firebases/cred/get-access-token`)
    .then((response) => {
      const genToken = response.data; // Adjust this line according to the actual structure of your response

      var config = {
        method: "post",
        url: "https://fcm.googleapis.com/v1/projects/hamrobloodbank-f3a95/messages:send",
        headers: {
          Authorization: `Bearer ${genToken}`,
          "Content-Type": "application/json",
        },
        data: {
          message: {
            ...(token?.includes("topics")
              ? { topic: token.replace("/topics/", "") }
              : { token }),
            notification: {
              body,
              title,
            },
            data: {
              ...(id && { id: JSON.stringify(id) }),
              ...(screen && { screen }),
            },
          },
        },
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data), "push notification sent");
        })
        .catch(function (error) {
          console.log("error", JSON.stringify(error));
        });
    })
    .catch((error) => {
      console.error("Failed to retrieve notification token:", error);
    });
};
