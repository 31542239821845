import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginForm from "./components/Layout/LoginForm";
import HomePage from "./pages/HomePage";
import PreventLogin from "./pages/PreventLogin";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route
          path="/dashboard/*"
          element={
            <PreventLogin>
              <HomePage />
            </PreventLogin>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
