import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Row,
  Select,
  Typography,
  Upload,
} from "antd";
import React, { useState } from "react";
import css from "../../styles/addModal.module.scss";
import addCamera from "../../Images/addCamera.svg";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  getTestimonials,
  postTestimonials,
} from "../../features/testimonialSlice";
import TextArea from "antd/es/input/TextArea";
import { BiArrowBack } from "react-icons/bi";
import ShowFile from "../showFile/ShowFile";

const AddTestimonial = (props) => {
  const dispatch = useDispatch();
  const [imageError, setimageError] = useState(null);
  const [formInstance] = Form.useForm();
  props.form.resetFields = formInstance.resetFields;

  const [imgSrc, setImgSrc] = useState("");

  const onSubmit = (data) => {
    const formData = new FormData();
    let imgValid = true;
    if (imgSrc === "") {
      imgValid = false;
      setimageError("please Provide a valid image");
    }
    if (imgValid) {
      if (imgSrc !== "") {
        formData.append("files.image", imgSrc, imgSrc.name);
      }
      formData.append("data", JSON.stringify(data));
      props.setIsModalOpen(false);
      formInstance.resetFields();
      dispatch(postTestimonials(formData));
      setImgSrc("");
    }
  };

  const handleCancel = () => {
    formInstance.resetFields();
    setImgSrc("");
    props.setIsModalOpen(false);
  };

  return (
    <div className={css.Container}>
      <Row className={css.title}>Add Testimonial</Row>
      <Form layout="vertical" form={formInstance} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Name is required",
                },
              ]}
            >
              <Input className={css.input} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Designation"
              name="designation"
              rules={[
                {
                  required: true,
                  message: "Designation is required",
                },
              ]}
            >
              <Input className={css.input} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Organization"
              name="organization"
              rules={[
                {
                  required: true,
                  message: "Organization is required",
                },
              ]}
            >
              <Input className={css.input} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Message"
              name="message"
              rules={[
                {
                  required: true,
                  message: "Message is required",
                },
              ]}
            >
              <TextArea showCount maxLength={150} />
            </Form.Item>
          </Col>

          <Divider />
          <Row justify={"center"}>
            <Row style={{ marginRight: "8px" }}>
              {imgSrc !== "" && <ShowFile imgSrc={imgSrc} />}
            </Row>
            <Col>
              <Upload
                showUploadList={false}
                listType="picture-card"
                accept=".jpeg, .png"
                beforeUpload={(file) => {
                  setImgSrc({ file }.file);
                  return false;
                }}
                onRemove={() => setImgSrc("")}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ display: "flex", gap: "0.5rem" }}>
                    <div className={css.uploadImgDiv}>
                      <img src={addCamera} alt="addCamera" />
                    </div>
                    <span className={css.uploadBtn}>Attachment Upload</span>
                  </div>
                  <span className={css.uploadText}>Upload Picture</span>
                  <span className={css.uploadText}>
                    Only in jpg and png format.
                  </span>
                </div>
              </Upload>
              <Typography.Text style={{ color: "red" }}>
                {imageError}
              </Typography.Text>
            </Col>
          </Row>
          <Divider />
        </Row>
        <Row justify={"end"} style={{ gap: "1rem" }}>
          <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary">
            Add
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default AddTestimonial;
