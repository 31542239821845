import { Button, Col, Form, Input, Row, Typography } from "antd";
import JoditEditor from "jodit-react";
import React, { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { sendEmail } from "../../features/email";
import css from "../../styles/addModal.module.scss";

const SendEmail = ({ emails, open, setSelectedRowKeys }) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    open(false);
  };
  const dispatch = useDispatch();

  const editor = useRef(null);
  const [detail, setDetail] = useState(null);

  const config = useMemo(
    () => ({
      buttons: [
        "bold",
        "italic",
        "underline",
        "|",
        "|",
        "center",
        "left",
        "right",
        "justify",
        "|",
      ],
      uploader: { insertImageAsBase64URI: true },
      removeButtons: ["brush", "file"],
      showXPathInStatusbar: false,
      showCharsCounter: false,
      showWordsCounter: false,
      toolbarAdaptive: false,
    }),
    []
  );

  const onSubmit = (data) => {
    const emailData = { ...data, to: emails, html: detail };
    dispatch(sendEmail(emailData));
    open(false);
    Swal.fire({
      icon: "success",
      title: "Email sent",
      confirmButtonColor: "#4BB543",
    });
    setSelectedRowKeys([]);
  };

  const loading = useSelector((state) => state.sendEmail.loading);
  return (
    <Form layout="vertical" onFinish={onSubmit}>
      <Typography.Title level={4} style={{ textAlign: "center" }}>
        Send Email
      </Typography.Title>
      <Row gutter={10}>
        {/* <Col span={24}>
          <Form.Item label="To" name={"to"}>
            <Input.TextArea readOnly value={emails} />
          </Form.Item>
        </Col> */}

        <Col span={24}>
          <Form.Item
            label="Subject"
            name={"subject"}
            rules={[
              {
                required: true,
                message: "Subject is required",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="Body"
            name={"html"}
            // rules={[
            //   {
            //     required: true,
            //     message: "Body is required",
            //   },
            // ]}
          >
            {/* <Input.TextArea rows={5} /> */}
            <JoditEditor
              className={css.editor}
              ref={editor}
              value={detail}
              config={config}
              onChange={(newContent) => setDetail(newContent)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row justify={"end"} style={{ gap: "1rem" }}>
        <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
          Cancel
        </Button>
        <Button htmlType="submit" type="primary" loading={loading}>
          Send
        </Button>
      </Row>
    </Form>
  );
};

export default SendEmail;
