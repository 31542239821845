import { Button, Col, Form, Input, Row, Upload } from "antd";
import React, { useState } from "react";
import css from "../../styles/addModal.module.scss";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { getbadges } from "../../features/badgeSlice";

const Filter = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onSubmit = (data) => {
    // TODO: sending only data which needs to be filtered
    const filterData = {};
    for (let index = 0; index < Object.keys(data).length; index++) {
      if (data[Object.keys(data)[index]] !== undefined) {
        filterData[Object.keys(data)[index]] = data[Object.keys(data)[index]];
      }
    }

    dispatch(
      getbadges({
        params: {
          page: 1,
          pageSize: 8,
        },
        filter: filterData,
      })
    );

    props.setFilterModal(false);
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    props.setFilterModal(false);
  };

  return (
    <div className={css.Container}>
      <Row className={css.title}>Filter</Row>
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Row gutter={[32, 0]} justify="space-between">
          <Col span={24}>
            <Form.Item label="Badge Title" name={"name"}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row justify={"end"} style={{ gap: "1rem" }}>
          <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary">
            Filter
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default Filter;
