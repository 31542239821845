import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "../../components/Layout/TopBar";
import css from "../../styles/Users/users.module.scss";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Dropdown,
  Modal,
  Row,
  Table,
  Tooltip,
} from "antd";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { BsChevronDown, BsSearch } from "react-icons/bs";
import searchIcon from "../../Images/Topbar/Search.svg";
import { ImFilter } from "react-icons/im";
import FilterHistory from "../../components/Layout/FilterHistory";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { api } from "../../Api";
import { BASEURL } from "../../Url";
import { getHistory } from "../../features/historySlice";
import moment from "moment";
import FilterSearch from "../../components/common/FilterSearch";

const History = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // handle search
  const [search, setSearch] = useState("");

  const [filterModal, setFilterModal] = useState(false);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    dispatch(
      getHistory({
        params: {
          page: 1,
          pageSize: 8,
        },
      })
    );
  }, [dispatch]);

  let historyData = useSelector((state) => state.history.history);
  let historyLoading = useSelector((state) => state.history.loading);

  const dataSource = historyData?.data?.map((f, i) => ({
    key: f?.id,
    id: f?.id,
    donors: f?.attributes?.acceptors?.data?.filter(
      (item) => item?.attributes?.status === "Donated"
    ),
    noOfUnits: f?.attributes?.noOfUnits,
    hospital: f?.attributes?.hospital,
    bloodType: f?.attributes?.bloodType,
    donatedTo: f?.attributes?.requestedBy?.data?.attributes?.username,
    requestReason: f?.attributes?.caseDescription,
    requestDate: moment.utc(f?.attributes?.createdAt).format("YYYY-MM-DD"),
  }));

  const columns = [
    {
      title: "Donors",
      key: "donors",
      dataIndex: "donors",
      render: (row) => {
        return (
          <>
            <Avatar.Group
              maxCount={2}
              maxPopoverTrigger="click"
              size="large"
              maxStyle={{
                color: "#f56a00",
                backgroundColor: "#fde3cf",
                cursor: "pointer",
              }}
            >
              {row?.map((d, i) => {
                return (
                  <Avatar
                    key={i}
                    src={
                      d?.attributes?.acceptor?.data?.attributes?.avatar?.data
                        ?.attributes?.url
                    }
                  />
                );
              })}
            </Avatar.Group>

            <br />
          </>
        );
      },
    },
    {
      title: "Blood Type",
      dataIndex: "bloodType",
      key: "bloodType",
    },

    {
      title: "Seeker",
      dataIndex: "donatedTo",
      key: "donatedTo",
    },

    {
      title: "Units needed",
      key: "noOfUnits",
      dataIndex: "noOfUnits",
    },
    {
      title: "Donated Location",
      key: "hospital",
      dataIndex: "hospital",
    },

    {
      title: "Request Reason",
      key: "requestReason",
      dataIndex: "requestReason",
    },
    {
      title: "Request date",
      key: "requestDate",
      dataIndex: "requestDate",
    },
  ];

  //row selection
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSelectChange = (newSelectedRowKeys) => {
    // console.log("newSelectedRowKeys", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  // console.log("selectedRowKeys changed: ", selectedRowKeys);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const start = (key) => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      alert(key);
      setLoading(false);
    }, 1000);
  };

  // dropdown functions
  function handleMenuClick(e) {
    start(e.key);
    // console.log(e)
  }

  /* dropdown action */
  const items = [
    {
      label: "Send Email",
      key: "1",
    },
    {
      label: "Delete",
      key: "2",
    },
  ];
  const menu = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div>
      <TopBar topic={"History"} />
      <div className={css.userContent}>
        {/* same css for history and user */}
        <Row
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <Col>
            <Dropdown menu={menu} disabled={!hasSelected}>
              <Button
                loading={loading}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                Actions <BsChevronDown />
              </Button>
            </Dropdown>
          </Col>

          {/* search filter and add new */}
          <FilterSearch
            history="true"
            handleSearch={handleSearch}
            setFilterModal={setFilterModal}
          />
        </Row>
        {/* table here */}
        <Table
          loading={historyLoading}
          rowSelection={rowSelection}
          dataSource={dataSource}
          columns={columns}
          bordered
          pagination={{
            showTitle: false,
            responsive: true,
            nextIcon: <GrLinkNext />,
            prevIcon: <GrLinkPrevious />,
          }}
          onRow={(record, _) => {
            return {
              onClick: (_) => navigate(`${record?.id}`),
            };
          }}
        />
      </div>
      <Modal
        open={filterModal}
        footer={null}
        className={css.modalSignup}
        centered={true}
        keyboard={true}
        closable={false}
        maskClosable={false}
      >
        <FilterHistory setFilterModal={setFilterModal} />
      </Modal>
    </div>
  );
};

export default History;
