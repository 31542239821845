import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TopBar from "../../components/Layout/TopBar";
import { useEffect } from "react";
import { getDonorDetail } from "../../features/donorListSlice";
import moment from "moment";
import Loading from "../../components/common/Loading";
import DonorInformation from "../../components/donorDetail/DonorInformation";

const DonorDetail = () => {
  const { donorId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDonorDetail(donorId));
  }, [donorId, dispatch]);

  const userInfo = useSelector((state) => state.donorList.donorDetail);

  const loading = useSelector((state) => state.donorList.loading);

  const today = moment.utc();
  const dob = moment.utc(userInfo?.data?.attributes?.dob).format("YYYY-MM-DD");
  const age = today.diff(dob, "years");

  return (
    <div>
      <TopBar topic="Donor Detail" />
      {loading ? (
        <Loading />
      ) : (
        <DonorInformation userInfo={userInfo} age={age} />
      )}
    </div>
  );
};

export default DonorDetail;
