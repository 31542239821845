import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Upload,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import TopBar from "../../components/Layout/TopBar";
import css from "../../styles/addModal.module.scss";
import addCamera from "../../Images/addCamera.svg";
import { getDonorDetail, updateDonorList } from "../../features/donorListSlice";
import ShowFile from "../../components/showFile/ShowFile";
const Option = { Select };

const Edit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // form.setFieldsValue({});

  useEffect(() => {
    dispatch(getDonorDetail(id));
  }, [dispatch, id]);

  // TODO: get data from store to show for editing
  let donorListData = useSelector((state) => state.donorList.donorDetail);
  let loading = useSelector((state) => state.donorList.loading);

  const [images, setImages] = useState([]);

  const onFinish = (data) => {
    const questionares = {
      everReceivedBlood: data.everReceivedBlood,
      donatedBloodBefore: data.donatedBloodBefore,
      lastDonatedDate: data.lastDonatedDate,
      victimOfAnyDisease: data.victimOfAnyDisease,
      hadVaccinationLast3Month: data.hadVaccinationLast3Month,
      receiveDirectCall: data.receiveDirectCall,
      receiveDirectMessage: data.receiveDirectMessage,
    };
    const info = {
      weight: data.weight,
      height: data.height,
      dob: data.dob,
      gender: data.gender,
      occupation: data.occupation,
      lastDonationDate: data.lastDonationDate,
      user: data.user,
      questionares,
    };

    const formData = new FormData();

    if (images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        formData.append(
          "files.attachments",
          images[i]?.file,
          images[i]?.file?.name
        );
      }
    }

    formData.append("data", JSON.stringify(info));
    dispatch(updateDonorList({ formData, id: id }));
    setImages([]);
  };

  const handleCancel = () => {
    setImages([]);
    navigate(-1);
  };

  form.setFieldsValue({
    user: donorListData?.data?.attributes?.user?.data?.id,
    weight: donorListData?.data?.attributes?.weight,
    height: donorListData?.data?.attributes?.height,
    dob: moment.utc(donorListData?.data?.attributes?.dob).format("YYYY-MM-DD"),
    gender: donorListData?.data?.attributes?.gender,
    urgency: donorListData?.data?.attributes?.urgency,
    occupation: donorListData?.data?.attributes?.occupation,
    lastDonationDate: moment
      .utc(donorListData?.data?.attributes?.lastDonationDate)
      .format("YYYY-MM-DD"),
    lastDonatedDate: moment
      .utc(donorListData?.data?.attributes?.lastDonatedDate)
      .format("YYYY-MM-DD"),
    needBefore: moment
      .utc(donorListData?.data?.attributes?.needBefore)
      .format("YYYY-MM-DD"),
    donatedBloodBefore:
      donorListData?.data?.attributes?.questionares?.donatedBloodBefore,
    everReceivedBlood:
      donorListData?.data?.attributes?.questionares?.everReceivedBlood,
    hadVaccinationLast3Month:
      donorListData?.data?.attributes?.questionares?.hadVaccinationLast3Month,
    receiveDirectMessage:
      donorListData?.data?.attributes?.questionares?.receiveDirectMessage,
    receiveDirectCall:
      donorListData?.data?.attributes?.questionares?.receiveDirectCall,
    victimOfAnyDisease:
      donorListData?.data?.attributes?.questionares?.victimOfAnyDisease,
  });

  const fileList = Array.from(
    { length: donorListData?.data?.attributes?.attachments?.data?.length },
    (_, index) => ({
      uid: String(
        donorListData?.data?.attributes?.attachments?.data?.[index]?.id
      ),
      url: donorListData?.data?.attributes?.attachments?.data?.[index]
        ?.attributes?.url,
    })
  );

  return (
    <div>
      <TopBar topic="Edit Donor" />
      <div className={css.editContent}>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item label="User ID" name="user">
                <Input className={css.input} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Form.Item label="Weight" name="weight">
                <Input className={css.input} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Form.Item label="Height" name="height">
                <Input className={css.input} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Form.Item label="DOB" name="dob">
                <Input type="date" className={css.input} />
              </Form.Item>
            </Col>

            <Col xs={24} md={12} xl={8}>
              <Form.Item label="Gender" name="gender">
                <Select
                  options={[
                    {
                      value: "Male",
                      label: "Male",
                    },
                    {
                      value: "Female",
                      label: "Female",
                    },
                    {
                      value: "Others",
                      label: "Others",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Form.Item label="Occupation" name="occupation">
                <Input className={css.input} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Form.Item label="Last Donation Date" name="lastDonationDate">
                <Input className={css.input} type="date" />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={10}>
            <Col xs={24} md={12} xl={8}>
              <Form.Item
                label="Ever Received Blood"
                name="everReceivedBlood"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Form.Item
                label="Donated Blood Before"
                name="donatedBloodBefore"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Form.Item
                label="Victim of any disease"
                name="victimOfAnyDisease"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>

            <Col xs={24} md={12} xl={8}>
              <Form.Item
                label="Had Vaccination in last 3 month"
                name="hadVaccinationLast3Month"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Form.Item
                label="Receive Direct Call"
                name="receiveDirectCall"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Form.Item
                label="Receive Direct Message"
                name="receiveDirectMessage"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Form.Item label="Last Donated Date" name="lastDonatedDate">
                <Input type="date" className={css.input} />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Row style={{ marginRight: "8px" }}>
              {fileList?.length !== 0 && (
                <>
                  {fileList?.map((file) => {
                    return (
                      <ShowFile
                        file={file}
                        id={id}
                        from={"getDonorDetail"}
                        dis={getDonorDetail}
                      />
                    );
                  })}
                </>
              )}
            </Row>
            <Col>
              <Upload
                multiple={true}
                listType="picture-card"
                accept=".jpg, .png"
                beforeUpload={(file) => {
                  setImages([...images, { file }]);
                  return false;
                }}
                onRemove={(file) => {
                  images.splice(
                    images.findIndex((x) => x.file.uid === file.uid),
                    1
                  );
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ display: "flex", gap: "0.5rem" }}>
                    <div className={css.uploadImgDiv}>
                      <img src={addCamera} alt="addCamera" />
                    </div>
                    <span className={css.uploadBtn}>Attachment Upload</span>
                  </div>
                  <span className={css.uploadText}>Upload Picture</span>
                  <span className={css.uploadText}>
                    Only in jpg and png format.
                  </span>
                </div>
              </Upload>
            </Col>
          </Row>
          <Divider />
          <Row justify={"end"} style={{ gap: "1rem" }}>
            <Button
              type="primary"
              danger
              htmlType="reset"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button htmlType="submit" type="primary" loading={loading}>
              Save Changes
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default Edit;
