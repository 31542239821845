import { Col, Row } from "antd";
import css from "../../styles/addModal.module.scss";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { postRoles } from "../../features/roleSlice";

const AddRole = (props) => {
  const dispatch = useDispatch();

  const [imgSrc, setImgSrc] = useState("");

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    props.setIsModalOpen(false);

    // dispatch(postRoles(data));
    reset();
    setImgSrc("");
  };

  const handleCancel = () => {
    clearErrors();
    setImgSrc("");
    props.setIsModalOpen(false);
  };

  return (
    <form className={css.Container} onSubmit={handleSubmit(onSubmit)}>
      <Row className={css.title}>Add Role</Row>
      <Row gutter={[10, 10]}>
        <Col span={24} className={css.subTitle}>
          Name
        </Col>
        <Col span={24}>
          <input
            type={"text"}
            {...register("name", {
              required: "This field is required",
            })}
          />
          <span className={css.error}> {errors?.name?.message}</span>
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={24} className={css.subTitle}>
          Description
        </Col>
        <Col span={24}>
          <input
            type={"text"}
            {...register("description", {
              required: "This field is required",
            })}
          />
          <span className={css.error}> {errors?.description?.message}</span>
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={24} className={css.subTitle}>
          Permissions
        </Col>
        <Col span={12}>
          <select
            {...register("permissions", {
              required: "This field is required",
            })}
          >
            <option value="permission1">permission1</option>
            <option value="permission2">permission2</option>
            <option value="permission3">permission3</option>
          </select>
          <span className={css.error}> {errors?.permissions?.message}</span>
        </Col>
      </Row>
      <div className={css.Btns}>
        <button className={css.cancelBtn} type="reset" onClick={handleCancel}>
          Cancel
        </button>
        <button className={css.okBtn} type="submit">
          OK
        </button>
      </div>
    </form>
  );
};

export default AddRole;
