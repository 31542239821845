import { Col, Form, Modal, Pagination, Row, Space, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FaqCard from "../../components/Layout/FaqCard";
import TopBar from "../../components/Layout/TopBar";
import css from "../../styles/Faq/faq.module.scss";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import AddFaq from "../../components/Add/AddFaq";
import { deleteFaq, getFaq } from "../../features/faqSlice";
import moment from "moment";
import editIcon from "../../Images/Users/editIcon.svg";
import { BiAddToQueue, BiEdit } from "react-icons/bi";
import DeleteConfirmation from "../../components/Layout/DeleteConfirmation";
import EditFaq from "../../components/Edit/EditFaq";
import { limit } from "../../utils/truncatingString";
import EditDots from "../../components/common/EditDots";
import { ImFilter } from "react-icons/im";
import Filter from "../../components/Layout/FilterFaq";
import FilterSearch from "../../components/common/FilterSearch";

const Faq = () => {
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState();
  // TODO: get faq data when render
  const [params, setParams] = useState({
    page: 1,
    pageSize: 8,
  });
  useEffect(() => {
    dispatch(getFaq({ params, filter: filteredData }));
  }, [dispatch, params]);

  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false); //adding faq
  const [editModal, setEditModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [edit, setEdit] = useState({
    key: 0,
  });
  // get data from file
  let faqData = useSelector((state) => state.faq.faqs);
  let isLoading = useSelector((state) => state.faq.loading);
  // TODO: adding 'key' attribute in faqData.data as it is needed for table

  // handle search
  const [search, setSearch] = useState("");

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleCancel = () => {
    form.resetFields();
    setEditModal(false);
    setIsModalOpen(false);
    setFilterModal(false);
  };

  const fields = ["question", "answer", "category"];

  let dataSource = faqData?.data?.map((f, i) => ({
    id: f?.id,
    question: f?.attributes?.question,
    answer: f?.attributes?.answer,
    category: f?.attributes?.type,
    date: moment.utc(f?.attributes?.createdAt).format("YYYY-MM-DD"),
  }));

  if (search) {
    const filteredData = dataSource?.filter((data) => {
      return fields.some((field) =>
        String(data?.[field])?.toLowerCase().includes(search.toLowerCase())
      );
    });
    dataSource = filteredData;
  }

  // TODO: data columns

  const columns = [
    {
      title: "Question",
      key: "question",
      render: (row) => {
        return (
          <Tooltip title={row?.question}>{limit(row?.question, 50)}</Tooltip>
        );
      },
    },
    {
      title: "Answer",
      key: "answer",
      render: (row) => {
        return <Tooltip title={row?.answer}>{limit(row?.answer, 80)}</Tooltip>;
      },
      width: "40%",
    },
    {
      title: "Category",
      key: "category",
      dataIndex: "category",
    },
    {
      title: "Action",
      render: (row) => {
        return (
          <EditDots
            icon={editIcon}
            setEdit={setEdit}
            row={row}
            edit={edit}
            setEditModal={setEditModal}
            setDeleteModal={setDeleteModal}
          />
        );
      },
    },
  ];
  return (
    <div>
      <TopBar topic="FAQ" link="category" category={true} />
      <div className={css.testimonialContainer}>
        {/* search filter and add new */}
        <div className={css.searchFilterNew}>
          <FilterSearch
            show={true}
            handleSearch={handleSearch}
            setFilterModal={setFilterModal}
            setIsModalOpen={setIsModalOpen}
            title="Add FAQ"
            filterModal={filterModal}
            content={
              <Filter
                setFilterModal={setFilterModal}
                filterModal={filterModal}
                setFilteredData={setFilteredData}
              />
            }
            icon={<BiAddToQueue className={css.filterIcon} />}
          />
        </div>
        <Table
          bordered
          scroll={{
            x: 1500,
          }}
          pagination={{
            showTitle: false,
            responsive: true,
            prevIcon: <GrLinkPrevious />,
            nextIcon: <GrLinkNext />,
            current: params.page,
            total: faqData?.meta?.pagination?.total,
            pageSize: params.pageSize,
            showTitle: false,
            onChange: (page) => {
              setParams({
                ...params,
                page,
              });
            },
          }}
          loading={isLoading}
          columns={columns}
          dataSource={dataSource}
        />
      </div>

      <Modal
        open={isModalOpen}
        footer={null}
        className={css.modalSignup}
        centered={true}
        keyboard={false}
        onCancel={handleCancel}
        maskClosable={false}
      >
        <AddFaq setIsModalOpen={setIsModalOpen} form={form} />
      </Modal>

      {/* modal */}

      <Modal
        open={editModal}
        footer={null}
        className={css.modalSignup}
        centered={true}
        keyboard={false}
        onCancel={handleCancel}
      >
        <EditFaq setEditModal={setEditModal} id={edit?.key} form={form} />
      </Modal>

      {/* <Modal
        open={filterModal}
        footer={null}
        className={css.modalSignup}
        centered={true}
        keyboard={false}
        onCancel={() => setFilterModal(false)}
      >
        <Filter setFilterModal={setFilterModal} />
      </Modal> */}

      <Modal
        title={"Delete Confirmation"}
        open={deleteModal}
        footer={null}
        centered={true}
        keyboard={false}
        onCancel={handleCancel}
      >
        <DeleteConfirmation
          setDeleteModal={setDeleteModal}
          id={edit?.key}
          delete={deleteFaq}
          fromDelete={true}
        />
      </Modal>
    </div>
  );
};

export default Faq;
