import { Button, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTeamMember } from "../../features/userSlice";
import { getRoles } from "../../features/roleSlice";
import css from "../../styles/addModal.module.scss";

const Filter = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [params, setParams] = useState({
    page: 1,
    pageSize: 8,
  });

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  let roleData = useSelector((state) => state.role.roles);

  roleData = roleData?.roles?.filter(
    (role) => role.name === "Super Admin" || role.name === "Admin"
  );

  const onSubmit = (data) => {
    // TODO: sending only data which needs to be filtered
    const filterData = {};
    for (let index = 0; index < Object.keys(data).length; index++) {
      if (data[Object.keys(data)[index]] !== undefined) {
        filterData[Object.keys(data)[index]] = data[Object.keys(data)[index]];
      }
    }
    props.setFilteredData(filterData);
    if (Object.keys(filterData).length === 0) {
      dispatch(
        getTeamMember({
          params,
        })
      );
    } else {
      dispatch(
        getTeamMember({
          params: {
            page: 1,
            pageSize: 8,
          },
          filter: filterData,
        })
      );
    }
    props.setFilterModal(false);
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    props.setFilterModal(false);
  };

  return (
    <div className={css.Container}>
      <Row className={css.title}>Filter</Row>
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Row gutter={[32, 0]} justify="space-between">
          {/* <Col span={12}>
            <Form.Item name={"username"} label="Full Name">
              <Input />
            </Form.Item>
          </Col> */}
          <Col span={12}>
            <Form.Item label="Role" name={"name"}>
              <Select>
                {roleData?.map((r, i) => {
                  return (
                    <Select.Option key={i} value={r.name}>
                      {r.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          {/* <Col span={12}>
            <Form.Item label="Email" name={"email"}>
              <Input type="email" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Phone" name={"phone"}>
              <Input />
            </Form.Item>
          </Col> */}
        </Row>
        <Row justify={"end"} style={{ gap: "1rem" }}>
          <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary">
            Filter
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default Filter;
