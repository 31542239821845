import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "../../components/Layout/TopBar";
import css from "../../styles/BloodNeeds/bloodNeeds.module.scss";
import editIcon from "../../Images/Users/editIcon.svg";
import { BiAddToQueue } from "react-icons/bi";
import { Button, Col, Dropdown, Modal, Row, Table } from "antd";
import BloodNeedCard from "../../components/Layout/BloodNeedCard";
import icon1 from "../../Images/BloodNeeds/allNeeds.svg";
import icon2 from "../../Images/BloodNeeds/verifiedNeeds.svg";
import icon3 from "../../Images/BloodNeeds/completeNeeds.svg";
import icon4 from "../../Images/BloodNeeds/verifiedNeeds.svg";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { BsChevronDown } from "react-icons/bs";
import FilterBloodNeeds from "../../components/Layout/FilterBloodNeeds";
import { useNavigate } from "react-router-dom";
import {
  completeCount,
  deleteBloodNeeds,
  getBloodNeeds,
} from "../../features/bloodNeedsSlice";
import EditDots from "../../components/common/EditDots";
import DeleteConfirmation from "../../components/Layout/DeleteConfirmation";
import FilterSearch from "../../components/common/FilterSearch";
import moment from "moment";
import { realBloodNeed } from "../../features/reviewSlice";
import { getBloodNeedCount } from "../../features/dashboardSlice";
import { GoUnverified, GoVerified } from "react-icons/go";

const BloodNeeds = () => {
  const [filteredData, setFilteredData] = useState();

  // TODO: fetch partners data
  const dispatch = useDispatch();
  // TODO: get faq data when render
  const [params, setParams] = useState({
    page: 1,
    pageSize: 8,
  });

  useEffect(() => {
    dispatch(getBloodNeeds({ params, filter: filteredData }));
    dispatch(realBloodNeed());
    dispatch(completeCount());
  }, [dispatch, params]);

  useEffect(() => {
    dispatch(getBloodNeedCount());
  }, [dispatch]);

  const totalBloodNeeds = useSelector(
    (state) => state.dashboard.totalBloodNeeds
  );

  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterModal, setFilterModal] = useState(false);

  const [edit, setEdit] = useState({
    key: 0,
  });

  // bring user data from store
  let bloodNeedData = useSelector((state) => state.bloodNeed.bloodNeed);

  const [totalNeeds, setTotalNeeds] = useState(0);

  let completeNeedCount = useSelector(
    (state) => state.bloodNeed.cardValues.completeCount
  );
  let realBloodNeedCount = useSelector((state) => state.review.realBloodNeed);

  let isLoading = useSelector((state) => state.bloodNeed.loading);

  // handle search
  const [search, setSearch] = useState("");

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const fields = [
    "name",
    "bloodType",
    "gender",
    "phone",
    "hospital",
    "urgency",
    "noOfUnits",
  ];

  let dataSource = bloodNeedData?.data?.map((f, i) => ({
    id: f?.id,
    name: f?.attributes?.requestedBy?.data?.attributes?.username,
    patientName: f?.attributes?.patientName,
    bloodType: f?.attributes?.requestedBloodGroup?.data?.attributes?.name,
    gender: f?.attributes?.patientGender,
    phone: f?.attributes?.patientPhone,
    hospital: f?.attributes?.hospital,
    noOfUnits: f?.attributes?.noOfUnits,
    urgency: f?.attributes?.urgency,
    acceptors: f?.attributes?.acceptors,
    verified: f?.attributes?.verified,
    date: moment.utc(f?.attributes?.createdAt).format("YYYY-MM-DD"),
    verifiedAt: moment
      .utc(f?.attributes?.verificationNotes?.verifiedAt)
      .format("YYYY-MM-DD"),

    deadline: moment
      .utc(f?.attributes?.needBefore)
      .format("YYYY-MM-DD hh:mm A"),
  }));

  if (search) {
    const filteredData = dataSource?.filter((data) => {
      return fields.some((field) =>
        String(data?.[field])?.toLowerCase().includes(search.toLowerCase())
      );
    });
    dataSource = filteredData;
  }
  const navigate = useNavigate();
  const columns = [
    {
      title: "Requested By",
      key: "name",
      render: (row) => {
        return (
          <div
            className={css.profileImage}
            onClick={() => navigate(`${row?.id}`)}
          >
            {/* <Avatar src={row?.avatarUrl} alt="" /> */}
            <span>{row?.name}</span>
          </div>
        );
      },
    },

    {
      title: "Patient name",
      key: "patientName",
      dataIndex: "patientName",
    },
    {
      title: "Blood Type",
      dataIndex: "bloodType",
      key: "bloodType",
      filters: [
        {
          text: "Plasma",
          value: "Pasma",
        },
        {
          text: "Power Blood",
          value: "Power Blood",
        },
        {
          text: "Whole Blood",
          value: "Whole Blood",
        },
      ],
      onFilter: (value, record) => record?.bloodType === value,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      filters: [
        {
          text: "Male",
          value: "Male",
        },
        {
          text: "Female",
          value: "Female",
        },
        {
          text: "Other",
          value: "Other",
        },
      ],
      onFilter: (value, record) => record?.patientGender === value,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Location",
      dataIndex: "hospital",
      key: "hospital",
    },
    {
      title: "Units Needed",
      dataIndex: "noOfUnits",
      key: "noOfUnits",
    },
    {
      title: "Units Fulfilled",
      key: "unitsFulfilled",
      render: (row) => {
        let unitsFulfilled = 0;
        row?.acceptors?.data?.map(
          (a, i) => (unitsFulfilled += a?.attributes?.donatedUnits)
        );
        return <span>{unitsFulfilled}</span>;
      },
    },

    {
      title: "Verified",
      key: "verified",
      render: (row) => {
        return (
          <div>
            {row?.verified ? (
              <GoVerified style={{ color: "#296DE5", fontSize: "1.02rem" }} />
            ) : (
              <GoUnverified style={{ color: "#F52A3B", fontSize: "1.02rem" }} />
            )}
          </div>
        );
      },
      filters: [
        {
          text: "Verified",
          value: true,
        },
        {
          text: "UnVerified",
          value: false,
        },
      ],
      onFilter: (value, record) => record?.verified === value,
    },

    {
      title: "Urgency",
      key: "urgency",
      render: (row) => {
        if (row?.urgency === "Urgent") {
          return <span className={css.urgency}>{row?.urgency}</span>;
        }
        if (row?.urgency === "Medium") {
          return <span className={css.moderate}>{row?.urgency}</span>;
        }
      },
      filters: [
        {
          text: "Urgent",
          value: "Urgent",
        },
        {
          text: "Medium",
          value: "Medium",
        },
      ],
      onFilter: (value, record) => record?.urgency === value,
    },

    { title: "Created At", key: "date", dataIndex: "date" },
    { title: "Verified At", key: "verifiedAt", dataIndex: "verifiedAt" },
    { title: "Deadline Date", key: "deadline", dataIndex: "deadline" },

    {
      title: "Action",
      render: (row) => {
        return (
          <EditDots
            icon={editIcon}
            setEdit={setEdit}
            row={row}
            edit={edit}
            setEditModal={setEditModal}
            setDeleteModal={setDeleteModal}
            from="bloodNeeds"
            link="/dashboard/edit-blood-need"
          />
        );
      },
    },
  ];

  //row selection
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSelectChange = (newSelectedRowKeys) => {
    // console.log("newSelectedRowKeys", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  // console.log("selectedRowKeys changed: ", selectedRowKeys);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const [hasSelected, setHasSelected] = useState(false);
  useEffect(() => {
    setHasSelected(selectedRowKeys.length > 0);
  }, [selectedRowKeys?.length]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [params]);
  const start = (key) => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      alert(key);
      setLoading(false);
    }, 1000);
  };

  // dropdown functions
  function handleMenuClick(e) {
    // message.info("Click on menu item.");
    // console.log("click", e);
    start(e.key);
  }

  /* dropdown action */

  const items = [
    {
      label: "Send Email",
      key: "1",
    },
    {
      label: "Delete",
      key: "2",
    },
  ];
  const menu = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div>
      <TopBar topic={"Blood Needs"} />
      <div className={css.bloodNeedContent}>
        {/* cards here */}

        <Row justify={"space-between"} className={css.cardContainer}>
          <Col span={5} className={css.card}>
            <BloodNeedCard
              value={totalBloodNeeds}
              icon={icon1}
              title={"Total Needs"}
            />
          </Col>
          <Col span={5} className={css.card}>
            <BloodNeedCard
              value={realBloodNeedCount}
              icon={icon2}
              title={"Verified Needs"}
            />
          </Col>
          <Col span={5} className={css.card}>
            <BloodNeedCard
              value={completeNeedCount}
              icon={icon3}
              title={"Completed Needs"}
            />
          </Col>
          <Col span={5} className={css.card}>
            <BloodNeedCard
              value={totalBloodNeeds - completeNeedCount}
              icon={icon4}
              title={"Pending Needs"}
            />
          </Col>
        </Row>

        <Row
          style={{
            justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          {/* <Col>
            <Dropdown menu={menu} disabled={!hasSelected}>
              <Button
                loading={loading}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                Actions <BsChevronDown />
              </Button>
            </Dropdown>
          </Col> */}

          {/* search filter and add new */}
          <FilterSearch
            handleSearch={handleSearch}
            setFilterModal={setFilterModal}
            setIsModalOpen={setIsModalOpen}
            title="Add Blood Need"
            show={false}
            filterModal={filterModal}
            content={
              <FilterBloodNeeds
                setFilterModal={setFilterModal}
                filterModal={filterModal}
                setFilteredData={setFilteredData}
              />
            }
            icon={<BiAddToQueue className={css.filterIcon} />}
          />
        </Row>

        {/* table here */}

        <Table
          scroll={{
            x: 1500,
          }}
          loading={isLoading}
          // rowSelection={rowSelection}
          dataSource={dataSource}
          columns={columns}
          bordered
          pagination={{
            showTitle: false,
            responsive: true,
            prevIcon: <GrLinkPrevious />,
            nextIcon: <GrLinkNext />,
            current: params.page,
            total: bloodNeedData?.meta?.pagination?.total,
            pageSize: 8,
            onChange: (page) => {
              setParams({
                ...params,
                page,
              });
            },
          }}
        />
      </div>
      {/* modal */}

      <Modal
        title={"Delete Confirmation"}
        open={deleteModal}
        footer={null}
        maskClosable={false}
        centered={true}
        keyboard={true}
        onCancel={() => setDeleteModal(false)}
      >
        <DeleteConfirmation
          setDeleteModal={setDeleteModal}
          id={edit?.key}
          delete={deleteBloodNeeds}
          get={getBloodNeeds}
          fromDelete={true}
        />
      </Modal>
    </div>
  );
};

export default BloodNeeds;
