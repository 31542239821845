import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Upload,
} from "antd";
import JoditEditor from "jodit-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import slugify from "slugify";
import { api } from "../../Api";
import addCamera from "../../Images/addCamera.svg";
import { BASEURL } from "../../Url";
import TopBar from "../../components/Layout/TopBar";
import ShowFile from "../../components/showFile/ShowFile";
import { getBlogDetail, updateBlog } from "../../features/blogSlice";
import { deleteFile } from "../../features/deleteFileSlice";
import { getBadges } from "../../features/rewardSlice";
import css from "../../styles/addModal.module.scss";

const EditBlog = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getBlogDetail(id));
    dispatch(getBadges());
  }, [dispatch, id]);

  const badges = useSelector((state) => state.reward.badges);

  // TODO: for editor
  const editor = useRef(null);
  const [detail, setDetail] = useState(null);

  const config = useMemo(
    () => ({
      uploader: { insertImageAsBase64URI: true },
      showXPathInStatusbar: false,
      showCharsCounter: false,
      showWordsCounter: false,
      toolbarAdaptive: false,
    }),
    []
  );

  const loading = useSelector((state) => state.blog.loading);

  const [imgSrc, setImgSrc] = useState("");
  let blogData = useSelector((state) => state.blog.blogDetail);

  const [searchUser, setSearchUser] = useState("");
  const [userList, setUserList] = useState([]);

  let fileList = [
    {
      uid: String(blogData?.data?.attributes?.attachment?.data?.id),
      url: blogData?.data?.attributes?.attachment?.data?.attributes?.url,
    },
  ];

  useEffect(() => {
    const filterUser = async () => {
      const res = await api.get(
        `${BASEURL}/users/?filters[username][$containsi]=${searchUser}`
      );
      const options = res.data?.map((item) => ({
        value: item?.id,
        label: item?.username,
      }));

      setUserList(options);
    };
    if (searchUser.length > 2) {
      filterUser();
    } else {
      setUserList([]);
    }
  }, [dispatch, searchUser]);

  // TODO: onSubmit
  const onSubmit = (data) => {
    const newData = {
      ...data,
      detail: detail,
      slug: slugify(data.title, { lower: true }),
    };
    const formData = new FormData();

    if (imgSrc !== "") {
      formData.append("files.attachment", imgSrc, imgSrc.name);
    }
    formData.append("data", JSON.stringify(newData));

    dispatch(
      updateBlog({
        formData,
        id: id,
        badges,
        userId: blogData?.data?.attributes?.user?.data?.id,
        pin: data?.pin,
      })
    );
    setImgSrc("");
    navigate(-1);
  };

  const handleCancel = () => {
    navigate(-1);
    setImgSrc("");
    // props.setShowData(!props.showData);
  };

  const [formInstance] = Form.useForm();

  useEffect(() => {
    formInstance.setFieldsValue({
      user: blogData?.data?.id,
      title: blogData?.data?.attributes?.title,
      type: blogData?.data?.attributes?.type,
      // verified: blogData?.data?.attributes?.verified,
      detail: blogData?.data?.attributes?.detail,
      pin: blogData?.data?.attributes?.pin,
    });
  }, [blogData]);

  return (
    <div>
      <TopBar topic="Edit Blog" />
      <div className={css.editContent}>
        <Form layout="vertical" form={formInstance} onFinish={onSubmit}>
          <Row gutter={10}>
            {/* <Col span={24}>
            <Form.Item
              label="User ID"
              name="user"
              rules={[
                {
                  required: true,
                  message: "User is required",
                },
              ]}
            >
             
              <AutoComplete
                options={userList}
                onSearch={(value) => setSearchUser(value)}
              >
                {userList?.map((user, i) => {
                  return (
                    <AutoComplete.Option value={user?.value} key={i}>
                      {user?.label}
                    </AutoComplete.Option>
                  );
                })}
              </AutoComplete>
            </Form.Item>
          </Col> */}

            <Col span={24}>
              <Form.Item
                label="Blog Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Title is required",
                  },
                ]}
              >
                <Input className={css.input} />
              </Form.Item>
            </Col>

            <Col xs={24} md={12} xl={8}>
              <Form.Item
                label="Category"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Category is required",
                  },
                ]}
              >
                <Select
                  options={[
                    {
                      label: "Blog",
                      value: "blog",
                    },
                    {
                      label: "Story",
                      value: "story",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            {/* <Col xs={24} md={12} xl={3}>
              <Form.Item
                label="Verified"
                name="verified"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col> */}
            <Col xs={24} md={12} xl={3}>
              <Form.Item label="Pin" name="pin" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col>
            <Divider />
            <Row justify={"center"}>
              <Row style={{ marginRight: "8px" }}>
                {(fileList[0]?.uid !== "undefined" || imgSrc?.uid) && (
                  <>
                    {fileList?.map((file) => {
                      return (
                        <ShowFile
                          file={file}
                          id={id}
                          from={"getBlogDetail"}
                          dis={getBlogDetail}
                          imgSrc={imgSrc}
                        />
                      );
                    })}
                  </>
                )}
              </Row>
              <Col>
                <Upload
                  showUploadList={false}
                  listType="picture-card"
                  accept=".jpg, .png"
                  beforeUpload={(file) => {
                    if (fileList[0]?.uid !== "undefined") {
                      dispatch(
                        deleteFile({
                          fileId: fileList[0]?.uid,
                          Id: id,
                          dis: getBlogDetail,
                          from: "getBlogDetail",
                        })
                      );
                    }
                    setImgSrc({ file }.file);
                    return false;
                  }}
                  onRemove={() => setImgSrc("")}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ display: "flex", gap: "0.5rem" }}>
                      <div className={css.uploadImgDiv}>
                        <img src={addCamera} alt="addCamera" />
                      </div>
                      <span className={css.uploadBtn}>Attachment Upload</span>
                    </div>
                    <span className={css.uploadText}>Upload Picture</span>
                    <span className={css.uploadText}>
                      Only in jpg and png format.
                    </span>
                  </div>
                </Upload>
              </Col>
            </Row>
            <Divider />

            <Col span={24}>
              <Form.Item label="Blog Description" name="detail">
                <JoditEditor
                  className={css.editor}
                  ref={editor}
                  value={detail}
                  config={config}
                  onChange={(newContent) => setDetail(newContent)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={"end"} style={{ gap: "1rem" }}>
            <Button
              type="primary"
              danger
              htmlType="reset"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button htmlType="submit" type="primary" loading={loading}>
              Save Changes
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default EditBlog;
