import { Button, Row } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { verifyBloodNeed } from "../../features/bloodNeedsSlice";
import css from "../../styles/addModal.module.scss";

const VerifyNeed = (props) => {
  const dispatch = useDispatch();

  const handleCancel = () => {
    props.setVerifyModal(false);
  };

  const handleOk = () => {
    dispatch(verifyBloodNeed(props));
    props.setVerifyModal(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        marginTop: "2rem",
      }}
    >
      <h6>Are you sure you want to verify?</h6>

      <Row justify={"end"} style={{ gap: "1rem" }}>
        <Button danger onClick={handleCancel} className={css.cancelButton}>
          Cancel
        </Button>
        <Button onClick={handleOk} type="primary">
          Verify
        </Button>
      </Row>
    </div>
  );
};

export default VerifyNeed;
