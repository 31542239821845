import {
  Button,
  Col,
  Row,
  Form,
  AutoComplete,
  Select,
  Avatar,
  Image,
  Input,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { api } from "../../Api";
import { getRoles } from "../../features/roleSlice";
import { postUsers, updateUsers } from "../../features/userSlice";
import css from "../../styles/addModal.module.scss";
import { BASEURL } from "../../Url";

const AddTeam = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  const roleData = useSelector((state) => state.role.roles);
  const loading = useSelector((state) => state.role.loading);

  const onSubmit = (data) => {
    const userInfo = {
      username: data.username,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
      role: data.role,
      blocked: false,
      emailVerified: true,
    };
    props.setIsModalOpen(false);
    dispatch(postUsers(userInfo));
    form.resetFields();
  };

  const handleCancel = () => {
    props.setIsModalOpen(false);
  };

  const [form] = Form.useForm();

  return (
    <>
      <Row className={css.title}>Add Team Member</Row>
      <Form
        className={css.Container}
        onFinish={onSubmit}
        layout="vertical"
        form={form}
      >
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              label="First Name"
              name={"firstName"}
              rules={[
                {
                  required: true,
                  message: "First name is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Last Name"
              name={"lastName"}
              rules={[
                {
                  required: true,
                  message: "Last name is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Username"
              name={"username"}
              rules={[
                {
                  required: true,
                  message: "Username is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Email"
              name={"email"}
              rules={[
                {
                  required: true,
                  message: "Email is required",
                },
              ]}
            >
              <Input type="email" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Role"
              name="role"
              rules={[
                {
                  required: true,
                  message: "Please select a role",
                },
              ]}
            >
              <Select placeholder="Select Role">
                {roleData?.roles?.map((r, i) => {
                  return (
                    <Select.Option value={r?.id} key={i}>
                      {r?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Password"
              name={"password"}
              rules={[
                {
                  required: true,
                  message: "Password is required",
                },
                {
                  min: 6,
                  message: "pasword must be at least 6 characters",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>

        <Row justify={"end"} style={{ gap: "1rem" }}>
          <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={loading}>
            Add
          </Button>
        </Row>
      </Form>
    </>
  );
};

export default AddTeam;
