import { Col, Form, Input, Row, Checkbox, Button, Divider } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import TopBar from "../../components/Layout/TopBar";
import { sendNotification } from "../../features/appSettingSlice";
import css from "../../styles/Admin/settings.module.scss";

const SendNotification = (props) => {
  const dispatch = useDispatch();
  const [formInstance] = Form.useForm();
  props.form.resetFields = formInstance.resetFields;

  const [selectedValues, setSelectedValues] = useState([]);
  const [disable, setDisable] = useState(false);

  const handleSubmit = (data) => {
    const notificationData = {
      title: data.title,
      description: data.description,
      groups: selectedValues,
    };
    dispatch(sendNotification(notificationData));
    props.setIsModalOpen(false);
    formInstance.resetFields();
  };

  const onChange = (checkedValues) => {
    if (checkedValues.includes("All")) {
      // If "All" is selected, disable the other checkboxes
      setDisable(true);
      setSelectedValues(["Users", "Team Members", "Donors"]);
    } else {
      // If "All" is not selected, enable the other checkboxes
      setDisable(false);
      setSelectedValues(checkedValues.filter((value) => value !== "All"));
    }
  };

  const handleCancel = () => {
    props.setIsModalOpen(false);
  };

  return (
    <>
      <Form layout="vertical" onFinish={handleSubmit} form={formInstance}>
        <Form.Item label="Title" name={"title"}>
          <Input />
        </Form.Item>
        <Form.Item label="Description" name={"description"}>
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item label="Select group">
          <Checkbox.Group
            style={{
              width: "100%",
            }}
            onChange={onChange}
          >
            <Row gutter={32}>
              <Col>
                <Checkbox value="All">All</Checkbox>
              </Col>
              <Col>
                <Checkbox value="Users" disabled={disable}>
                  Users
                </Checkbox>
              </Col>
              <Col>
                <Checkbox value="Team Members" disabled={disable}>
                  Team members
                </Checkbox>
              </Col>
              <Col>
                <Checkbox value="Donors" disabled={disable}>
                  Donors
                </Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <Row justify={"end"} style={{ gap: "1rem" }}>
          <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary">
            Send Notification
          </Button>
        </Row>
      </Form>
    </>
  );
};

export default SendNotification;
