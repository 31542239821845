import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TopBar from "../../components/Layout/TopBar";
import { useEffect } from "react";
import { getUsersById } from "../../features/userSlice";
import moment from "moment";
import Loading from "../../components/common/Loading";
import UserInformation from "../../components/userDetail/UserInformation";

const FeedbackDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsersById(id));
  }, [dispatch, id]);

  const userInfo = useSelector((state) => state.users.singleUser);
  const loading = useSelector((state) => state.users.loading);

  const today = moment.utc();
  const dob = moment.utc(userInfo?.donorProfile?.dob).format("YYYY-MM-DD");
  const age = today.diff(dob, "years");

  return (
    <div>
      <TopBar topic="User Detail" />
      {loading ? (
        <Loading />
      ) : (
        <UserInformation userInfo={userInfo} age={age} />
      )}
    </div>
  );
};

export default FeedbackDetail;
