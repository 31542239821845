import { Avatar, Space, Table, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { limit } from "../../utils/truncatingString";

const AcceptorsTab = ({ acceptors }) => {
  const navigate = useNavigate();

  const dataSource = acceptors?.map((f) => ({
    id: f?.attributes?.acceptor?.data?.id,
    name: f?.attributes?.acceptor?.data?.attributes?.username,
    imgUrl:
      f?.attributes?.acceptor?.data?.attributes?.avatar?.data?.attributes
        ?.url || f?.attributes?.acceptor?.data?.attributes?.avatarUrl,
    donatedUnits: f?.attributes?.donatedUnits,
    status: f?.attributes?.status,
    cancelledAt: moment.utc(f?.attributes?.cancelledAt).format("YYYY-MM-DD"),
    cancelledReason: f?.attributes?.cancelledReason,
    donatedAt: f?.attributes?.donatedAt
      ? moment.utc(f?.attributes?.donatedAt).format("YYYY-MM-DD")
      : "N/A",
    requestedAt: f?.attributes?.requestedAt
      ? moment.utc(f?.attributes?.requestedAt).format("YYYY-MM-DD")
      : "N/A",
    acceptedAt: f?.attributes?.acceptedAt
      ? moment.utc(f?.attributes?.acceptedAt).format("YYYY-MM-DD")
      : "N/A",
    acceptedAt: f?.attributes?.acceptedAt
      ? moment.utc(f?.attributes?.acceptedAt).format("YYYY-MM-DD")
      : "N/A",
    cancelledAt: f?.attributes?.cancelledAt
      ? moment.utc(f?.attributes?.cancelledAt).format("YYYY-MM-DD")
      : "N/A",
  }));

  const columns = [
    {
      title: "Name",
      //   dataIndex: "name",
      key: "name",
      render: (row) => {
        return (
          <Space>
            <Avatar src={row?.imgUrl} size={"large"} />
            <span>{row?.name}</span>
          </Space>
        );
      },
    },
    {
      title: "Donated Units",
      dataIndex: "donatedUnits",
      key: "donatedUnits",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Cancelled Reason",
      //   dataIndex: "cancelledReason",
      key: "cancelledReason",
      render: (row) => {
        return row?.cancelledReason ? (
          <Tooltip title={row?.cancelledReason}>
            {limit(row?.cancelledReason, 30)}
          </Tooltip>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Cancelled At",
      dataIndex: "cancelledAt",
      key: "cancelledAt",
    },
    {
      title: "Requested At",
      dataIndex: "requestedAt",
      key: "requestedAt",
    },
    {
      title: "Accepted At",
      dataIndex: "acceptedAt",
      key: "acceptedAt",
    },
    {
      title: "Donated At",
      dataIndex: "donatedAt",
      key: "donatedAt",
    },
  ];

  return (
    <div>
      <Table
        bordered
        columns={columns}
        dataSource={dataSource}
        pagination={{
          responsive: true,
          prevIcon: <GrLinkPrevious />,
          nextIcon: <GrLinkNext />,
          total: acceptors?.length,
          pageSize: 8,
        }}
        onRow={(row) => {
          return {
            onClick: () => {
              navigate(`/dashboard/users/${row?.id}`);
            },
          };
        }}
      />
    </div>
  );
};

export default AcceptorsTab;
