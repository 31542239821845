import React from "react";
import css from "../../styles/addModal.module.scss";
import Loading from "../../components/common/Loading";
import hidePwdImg from "../../Images/hide-password.svg";
import showPwdImg from "../../Images/show-password.svg";
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { BASEURL } from "../../Url";
import { Button, Form, Modal, Popover } from "antd";
import { useNavigate } from "react-router-dom";
import ChangePwForm from "../../components/common/ChangePwForm";

const AdminSettings = () => {
  const [error, setError] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // TODO: ==========================Change password function ================

  return (
    <div className={css.Container}>
      <h4>Admin Settings</h4>
      <Popover
        placement="bottom"
        trigger={["click"]}
        content={<ChangePwForm setIsModalVisible={setIsModalVisible} />}
        overlayStyle={{ width: "15%" }}
        destroyTooltipOnHide={true}
        open={isModalVisible}
      >
        <Button
          style={{ width: "fit-content" }}
          type="primary"
          onClick={() => setIsModalVisible(!isModalVisible)}
        >
          Change Password
        </Button>
      </Popover>
    </div>
  );
};

export default AdminSettings;
