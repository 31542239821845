import React from "react";
import { Navigate } from "react-router-dom";
import checkTokenExpiry from "../utils/JWTExpiryCheck";

const PreventLogin = ({ children }) => {
  const token = localStorage.getItem("token");

  return (
    <>{token && checkTokenExpiry(token) ? children : <Navigate to={"/"} />}</>
  );
};

export default PreventLogin;
