import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASEURL } from "../Url";
import { api } from "../Api";

const qs = require("qs");
const initialState = {
  totalDonors: 0,
  totalBloodNeeds: 126,
  storyCount: 0,
  pageCount: 0,
  blogCount: 0,
  totalUsers: 0,
  growth: 16,
  verifiedDonor: 0,
  nonVerifiedDonor: 0,
  loading: false,
  fulfilledBlood: 0,
  idVerifiedUsers: 0,
  phoneVerifiedUsers: 0,
  orgVerifiedUsers: 0,
  allblood: 0,
};
export const getDonorCount = createAsyncThunk("getDonorCount", async () => {
  const res = await api.get(`${BASEURL}/donor-profiles`);
  return res.data;
});
export const getVerifiedDonor = createAsyncThunk(
  "getVerifiedDonor",
  async (filter) => {
    const query = qs.stringify({
      filters: {
        status: filter?.status,
      },
    });
    const res = await api.get(`${BASEURL}/donor-profiles?populate=*&${query}`);
    return res.data;
  }
);
export const getNonVerifiedDonor = createAsyncThunk(
  "getNonVerifiedDonor",
  async (filter) => {
    const query = qs.stringify({
      filters: {
        status: filter?.status,
      },
    });
    const res = await api.get(`${BASEURL}/donor-profiles?populate=*&${query}`);
    return res.data;
  }
);
//blood need shown in dashboard
export const getBloodNeedCount = createAsyncThunk(
  "getBloodNeedCount",
  async () => {
    const res = await api.get(`${BASEURL}/blood-requests`);
    return res.data;
  }
);
//isFulFilled
export const getFulfilledBloodCount = createAsyncThunk(
  "getFulfilledBloodCount",
  async (filter) => {
    const query = qs.stringify({
      filters: {
        isFulFilled: filter?.isFulFilled,
      },
    });
    const res = await api.get(`${BASEURL}/blood-requests?populate=*&${query}`);
    return res.data;
  }
);

export const getUserCount = createAsyncThunk(
  "getUserCount",
  async ({ filter, organizerCount, phoneUserCount, idUserCount }) => {
    const query = qs.stringify({
      filters: filter,
      populate: ["organizerProfile"],
    });
    const res = await api.get(`${BASEURL}/users?${query}`);
    return {
      data: res.data,
      organizerCount,
      phoneUserCount,
      idUserCount,
    };
  }
);

export const getPageCount = createAsyncThunk("getPageCount", async (filter) => {
  const query = qs.stringify({
    filters: {
      type: filter?.type,
    },
  });
  const res = await api.get(`${BASEURL}/blogs?populate=*&${query}`);
  return res.data;
});

export const getStoriesCount = createAsyncThunk(
  "getStoriesCount",
  async (filter) => {
    const query = qs.stringify({
      filters: {
        type: filter?.type,
      },
    });
    const res = await api.get(`${BASEURL}/blogs?populate=*&${query}`);
    return res.data;
  }
);

export const getBlogCount = createAsyncThunk("getBlogCount", async (filter) => {
  const query = qs.stringify({
    filters: {
      type: filter?.type,
    },
  });
  const res = await api.get(`${BASEURL}/blogs?populate=*&${query}`);
  return res.data;
});
export const getAllBloodCount = createAsyncThunk(
  "getAllBloodCount",
  async () => {
    const res = await api.get(
      `${BASEURL}/users-permissions/users/analytics/getUserCountGroupByBloodGroup`
    );
    return res.data;
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    // TODO: get donors  count

    builder.addCase(getDonorCount.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getDonorCount.fulfilled, (state, action) => {
      state.loading = false;
      state.totalDonors = action.payload?.meta?.pagination?.total;
    });
    builder.addCase(getDonorCount.rejected, (state, action) => {
      state.loading = true;
    });
    // TODO: get blood need count
    builder.addCase(getBloodNeedCount.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getBloodNeedCount.fulfilled, (state, action) => {
      state.loading = false;
      state.totalBloodNeeds = action.payload?.meta?.pagination?.total;
    });
    builder.addCase(getBloodNeedCount.rejected, (state, action) => {
      state.loading = true;
    });

    // TODO: get user count
    builder.addCase(getUserCount.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getUserCount.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.phoneUserCount) {
        state.phoneVerifiedUsers = action.payload.data?.length;
      } else if (action.payload.organizerCount) {
        state.orgVerifiedUsers = action.payload.data?.length;
      } else if (action.payload.idUserCount) {
        state.idVerifiedUsers = action.payload.data?.length;
      } else {
        state.totalUsers = action.payload.data?.length;
      }
    });

    builder.addCase(getUserCount.rejected, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getStoriesCount.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getStoriesCount.fulfilled, (state, action) => {
      state.loading = false;
      state.storyCount = action.payload?.data?.length;
    });

    builder.addCase(getStoriesCount.rejected, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getPageCount.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getPageCount.fulfilled, (state, action) => {
      state.loading = false;
      state.pageCount = action.payload?.data?.length;
    });

    builder.addCase(getPageCount.rejected, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getBlogCount.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getBlogCount.fulfilled, (state, action) => {
      state.loading = false;
      state.blogCount = action.payload?.data?.length;
    });

    builder.addCase(getBlogCount.rejected, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getVerifiedDonor.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getVerifiedDonor.fulfilled, (state, action) => {
      state.loading = false;
      state.verifiedDonor = action.payload?.meta?.pagination?.total;
    });

    builder.addCase(getVerifiedDonor.rejected, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getNonVerifiedDonor.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getNonVerifiedDonor.fulfilled, (state, action) => {
      state.loading = false;
      state.nonVerifiedDonor = action.payload?.meta?.pagination?.total;
    });

    builder.addCase(getNonVerifiedDonor.rejected, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getFulfilledBloodCount.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getFulfilledBloodCount.fulfilled, (state, action) => {
      state.loading = false;
      state.fulfilledBlood = action.payload?.meta?.pagination?.total;
    });

    builder.addCase(getFulfilledBloodCount.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getAllBloodCount.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getAllBloodCount.fulfilled, (state, action) => {
      state.loading = false;
      state.allblood = action.payload;
    });

    builder.addCase(getAllBloodCount.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default dashboardSlice.reducer;
