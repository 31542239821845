import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loading from "../../components/common/Loading";
import TopBar from "../../components/Layout/TopBar";
import UserInformation from "../../components/userDetail/UserInformation";
import { getUsersById } from "../../features/userSlice";

const UserDetail = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsersById(userId));
  }, [dispatch, userId]);

  const userInfo = useSelector((state) => state.users.singleUser);
  const loading = useSelector((state) => state.users.loading);

  const today = moment.utc();
  const dob = moment.utc(userInfo?.donorProfile?.dob).format("YYYY-MM-DD");
  const age = today.diff(dob, "years");

  return (
    <div>
      <TopBar topic="User Detail" />
      {loading ? (
        <Loading />
      ) : (
        <UserInformation userInfo={userInfo} age={age} id={userId} />
      )}
    </div>
  );
};

export default UserDetail;
