import { Column } from "@ant-design/plots";
import { Row } from "antd";
import React, { useEffect, useState } from "react";
import { ImDroplet } from "react-icons/im";
import { IoIosPeople } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import DbCard from "../../components/Layout/DbCard";
import TopBar from "../../components/Layout/TopBar";
import {
  getAllBloodCount,
  getBlogCount,
  getBloodNeedCount,
  getDonorCount,
  getFulfilledBloodCount,
  getNonVerifiedDonor,
  getPageCount,
  getStoriesCount,
  getUserCount,
  getVerifiedDonor,
} from "../../features/dashboardSlice";
import css from "../../styles/HomePage/homePage.module.scss";

const Dashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPageCount({ type: "page" }));
    dispatch(getStoriesCount({ type: "story" }));
    dispatch(getBlogCount({ type: "blog" }));
    dispatch(getVerifiedDonor({ status: "Verified" }));
    dispatch(getNonVerifiedDonor({ status: "Pending" }));
    dispatch(getDonorCount());
    dispatch(getBloodNeedCount());
    dispatch(getFulfilledBloodCount({ isFulFilled: true }));
    dispatch(getUserCount({}));
    dispatch(
      getUserCount({
        filter: { organizerProfile: { status: "Verified" } },
        organizerCount: true,
      })
    );
    dispatch(getAllBloodCount());
    dispatch(
      getUserCount({ filter: { phoneVerified: true }, phoneUserCount: true })
    );
    dispatch(
      getUserCount({
        filter: {
          idProfile: {
            status: "Verified",
          },
        },
        idUserCount: true,
      })
    );
  }, [dispatch]);

  const values = useSelector((state) => state.dashboard);

  const bloods = values?.allblood;

  const remaining = [
    {
      action: "A+",
      count: 0,
    },
    {
      action: "B+",
      count: 0,
    },
    {
      action: "O+",
      count: 0,
    },
    {
      action: "AB+",
      count: 0,
    },
    {
      action: "A-",
      count: 0,
    },
    {
      action: "B-",
      count: 0,
    },
    {
      action: "O-",
      count: 0,
    },
    {
      action: "AB-",
      count: 0,
    },
  ];

  const [data, setData] = useState([]);

  useEffect(() => {
    let data = [];
    data =
      bloods?.length > 0
        ? bloods?.map((value) => {
            return {
              action: value?.blood_group_name,
              count: parseInt(value?.user_count),
            };
          })
        : [];

    remaining?.forEach((item) => {
      const existingItem =
        data?.length > 0 &&
        data?.find((dataItem) => dataItem?.action === item?.action);

      if (!existingItem) {
        console.log("data0, data", data);
        data?.push(item);
      }
    });

    setData(data);
  }, [bloods]);

  const config = {
    data,
    columnWidthRatio: 0.4,
    xField: "action",
    yField: "count",
    xAxis: true,
    forceFit: true,
    label: {
      formatter: (count) => count.action,
      style: {
        fontWeight: "bold",
        fontSize: 22,
      },
      position: "middle",
      // rotate: 180,
    },
    columnStyle: {
      fill: "#6F6AF8",
      cursor: "pointer",
    },
  };

  return (
    <div>
      <TopBar topic="Dashboard" />
      <div className={css.homePageContainer}>
        <div className={css.mainDiv}>
          <div className={css.graphPart}>
            <div className={css.barRadial}>
              <div className={css.barGraph}>
                {/* bar graph */}
                <Row justify={"space-between"} align="middle">
                  <h3>User Count By Blood Group</h3>
                </Row>
                <Column {...config} />
              </div>
              {/* <div className={css.radialGraph}>
                <RadialGraph />
              </div> */}
            </div>
            <div className={css.cardContainer}>
              <DbCard
                title="Total Stories"
                bg="#fff"
                series={[
                  {
                    data: [
                      values?.blogCount,
                      values?.storyCount,
                      // values.pageCount,
                    ],
                  },
                ]}
                xaxis={{
                  categories: [
                    "Blogs",
                    "Stories",
                    // "Pages"
                  ],
                }}
                value={
                  values?.storyCount + values?.blogCount + values?.pageCount
                }
                growth={values.growth}
              />

              <DbCard
                title="Total Donors"
                bg="#fff"
                series={[
                  {
                    data: [values?.verifiedDonor, values?.nonVerifiedDonor],
                  },
                ]}
                xaxis={{
                  categories: ["Verified Donor", "Non Verified Donor"],
                }}
                value={values?.verifiedDonor + values?.nonVerifiedDonor}
                growth={values.growth}
              />

              <DbCard
                img={ImDroplet}
                title="Total Blood Need"
                bg="#fff"
                series={[
                  {
                    data: [
                      values?.fulfilledBlood,
                      values?.totalBloodNeeds - values?.fulfilledBlood,
                    ],
                  },
                ]}
                xaxis={{
                  categories: ["Fulfilled", "Unfulfilled"],
                }}
                value={values.totalBloodNeeds}
                growth={values.growth}
              />

              <DbCard
                img={IoIosPeople}
                title="Total Users"
                bg="#fff"
                series={[
                  {
                    data: [values?.idVerifiedUsers, values?.phoneVerifiedUsers],
                  },
                ]}
                xaxis={{
                  categories: ["Id Verified", "Phone Verified"],
                }}
                value={values.totalUsers}
                growth={values.growth}
                eachBg="#996BFB"
              />
            </div>
          </div>
          {/* <div className={css.recentBlog}>{recentBlogsContent}</div> */}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
