import { Avatar, Image, Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import { api } from "../../Api";
import { BASEURL } from "../../Url";
import css from "../../styles/Blogs/blog.module.scss";
import moment from "moment";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";

const qs = require("qs");

const BlogTab = ({ userId }) => {
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getBlogByUser = async () => {
      setLoading(true);
      const query = qs.stringify({
        filters: {
          user: {
            id: userId,
          },
        },
      });
      const res = await api.get(`${BASEURL}/blogs?populate=*&${query}`);
      setBlogData(res.data);
      setLoading(false);
    };
    getBlogByUser();
  }, [userId]);

  let dataSource = blogData?.data?.map((f, i) => ({
    id: f?.id,
    title: f?.attributes?.title,
    avatarUrl: f?.attributes?.attachment?.data?.attributes?.url,
    likes: f?.attributes?.blogLikes?.data?.length,
    viewCount: f?.attributes?.viewCount || 0,
    date: moment.utc(f?.attributes?.date).format("YYYY-MM-DD"),
    status: f?.attributes?.pin,
    type: f?.attributes?.type,
  }));

  const columns = [
    {
      title: "Blog Title",
      key: "title",
      render: (row) => {
        return (
          <div className={css.profileImage}>
            <Image
              src={row?.avatarUrl}
              alt="image"
              width={30}
              height={30}
              style={{ objectFit: "cover", borderRadius: "50%" }}
            />
            <span>{row?.title}</span>
          </div>
        );
      },
    },
    {
      title: "Type",
      key: "type",
      dataIndex: "type",
      filters: [
        {
          text: "blog",
          value: "blog",
        },
        {
          text: "story",
          value: "story",
        },
      ],
      onFilter: (value, record) => record?.type === value,
    },
    {
      title: "Likes",
      dataIndex: "likes",
      key: "likes",
    },

    {
      title: "Views count",
      dataIndex: "viewCount",
      key: "viewCount",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },

    {
      title: "Status",
      key: "status",
      render: (row) => {
        return <Switch checked={row?.status} />;
      },
    },
  ];

  return (
    <div>
      <Table
        bordered
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        pagination={{
          responsive: true,
          prevIcon: <GrLinkPrevious />,
          nextIcon: <GrLinkNext />,
          defaultCurrent: 1,
          total: blogData?.meta?.pagination?.total,
          pageSize: 10,
        }}
      />
    </div>
  );
};

export default BlogTab;
