import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { api } from "../../Api";
import css from "../../styles/Details/userDetail.module.scss";
import { BASEURL } from "../../Url";
import BadgeTab from "../detailTabs/BadgeTab";
import BlogTab from "../detailTabs/BlogTab";
import BloodRequestTab from "../detailTabs/BloodRequestTab";
import DonationTab from "../detailTabs/DonationTab";
import FollowerTab from "../detailTabs/FollowerTab";
import FollowingTab from "../detailTabs/FollowingTab";
import IdProfileTab from "../detailTabs/IdProfileTab";
const qs = require("qs");

const UserDetailTabs = ({ userId, referBy }) => {
  const userInfo = useSelector((state) => state.users.singleUser);
  const [info, setInfo] = useState();
  const [followingInfo, setFollwoingInfo] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const getFollwingInfo = async () => {
      const query = qs.stringify({
        populate: ["followedBy", "followedTo.avatar"],
        filters: {
          followedBy: {
            id: userId,
          },
        },
      });
      const res = await api.get(`${BASEURL}/followers?${query}`);
      setFollwoingInfo(res.data);
    };
    const getDonationInfo = async () => {
      const query = qs.stringify({
        populate: ["acceptor", "bloodRequest.requestedBy"],
        pagination: {
          page: 1,
          pageSize: 8,
        },
        filters: {
          acceptor: {
            id: userId,
          },
          status: "Donated",
        },
      });
      const res = await api.get(`${BASEURL}/b-request-acceptors?${query}`);
      setInfo(res.data);
    };
    getFollwingInfo();
    getDonationInfo();
  }, [dispatch, userId]);

  const tabItems = [
    {
      label: "Blood Request",
      key: "1",
      children: <BloodRequestTab info={userInfo?.bloodRequests} />,
    },
    {
      label: `Donation(${info?.meta?.pagination?.total})`,
      key: "2",
      children: <DonationTab userId={userId} donationInfo={info} />,
    },
    {
      label: "Blogs",
      key: "3",
      children: <BlogTab userId={userId} />,
    },
    {
      label: `Badges(${userInfo?.badges?.length})`,
      key: "4",
      children: <BadgeTab info={userInfo?.badges} />,
    },
    {
      label: `Followers(${userInfo?.followers?.length})`,
      key: "5",
      children: <FollowerTab info={userInfo?.followers} />,
    },
    {
      label: `Followings(${followingInfo?.data?.length})`,
      key: "6",
      children: <FollowingTab info={followingInfo?.data} />,
    },
    {
      label: `Id Profile`,
      key: "7",
      children: (
        <IdProfileTab
          info={userInfo?.idProfile}
          userId={userId}
          phoneVerified={userInfo?.phoneVerified}
          referBy={referBy}
        />
      ),
    },
  ];
  return (
    <div className={css.tabs}>
      <Tabs defaultActiveKey="1" items={tabItems} />
    </div>
  );
};
export default UserDetailTabs;
