import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import css from "../../styles/Details/userDetail.module.scss";
import AcceptorsTab from "../detailTabs/AcceptorsTab";
import AttachmentTab from "../detailTabs/AttachmentTab";
import BasicBloodNeedInfo from "../detailTabs/BasicBloodNeedInfo";
import DonorsTab from "../detailTabs/DonorsTab";
const qs = require("qs");

const HistoryDetailTabs = () => {
  const info = useSelector((state) => state.history.singleHistory);
  const tabItems = [
    {
      label: "Basic Information",
      key: "1",
      children: <BasicBloodNeedInfo info={info} />,
    },

    {
      label: "Donors",
      key: "2",
      children: <DonorsTab donors={info?.data?.attributes?.acceptors?.data} />,
    },

    {
      label: "Attachments",
      key: "3",
      children: (
        <AttachmentTab
          attachments={info?.data?.attributes?.attachments?.data}
        />
      ),
    },

    {
      label: "Reports",
      key: "4",
      children: (
        <AttachmentTab attachments={info?.data?.attributes?.reports?.data} />
      ),
    },
  ];
  return (
    <div className={css.tabs}>
      <Tabs defaultActiveKey="1" items={tabItems} />
    </div>
  );
};

export default HistoryDetailTabs;
