import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleOrganization } from "../../features/organizerSlice";
import Loading from "../common/Loading";
import TopBar from "../Layout/TopBar";
import OrganizerInformation from "./OrganizerInformation";

const OrganizerList = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSingleOrganization(id));
  }, [dispatch, id]);

  const organizer = useSelector((state) => state.organizer.singleOrganizer);
  const loading = useSelector((state) => state.organizer.loading);
  const organizerFollower = useSelector(
    (state) => state.organizer.organizerFollower
  );
  return (
    <div>
      <TopBar topic="Organizer Detail" />
      {loading ? (
        <Loading />
      ) : (
        <OrganizerInformation
          organizerInfo={organizer}
          id={id}
          organizerFollower={organizerFollower}
        />
      )}
    </div>
  );
};
export default OrganizerList;
