import { Col, Divider, Image, Row, Space, Tabs, Typography } from "antd";
import React, { useEffect } from "react";
import AdminSettings from "../../pages/adminSettings/AdminSettings";
import css from "../../styles/Details/userDetail.module.scss";
import DonorDetail from "./DonorDetailTabs";
import { GoVerified } from "react-icons/go";
import noProfile from "../../Images/Users/userProfile.png";

const DonorInformation = ({ userInfo, age, from }) => {
  return (
    <div className={css.userDetailContainer}>
      <div className={css.content}>
        <div className={css.imagePart}>
          <Row
            style={{ flexDirection: "column" }}
            align={"middle"}
            gutter={[0, 16]}
          >
            <Col>
              <Image
                src={
                  userInfo?.data?.attributes?.user?.data?.attributes?.avatar
                    ?.data?.attributes?.url ||
                  userInfo?.data?.attributes?.user?.data?.attributes
                    ?.avatarUrl ||
                  noProfile
                }
                alt="profileImage"
                className={css.profileImage}
                width={150}
                height={150}
                preview={false}
              />
            </Col>
            <Row
              align={"center"}
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <Typography.Title level={3} style={{ marginBottom: "0" }}>
                {userInfo?.data?.attributes?.user?.data?.attributes?.username}
              </Typography.Title>
              {userInfo?.data?.attributes?.status === "Verified" && (
                <GoVerified style={{ color: "#296DE5", fontSize: "1.02rem" }} />
              )}
            </Row>
            <Typography.Text style={{ marginTop: "-1rem" }}>
              {
                userInfo?.data?.attributes?.user?.data?.attributes?.role?.data
                  ?.attributes?.name
              }
            </Typography.Text>
            <Col>
              <Row gutter={[8]} align={"middle"}>
                <Col>
                  {
                    userInfo?.data?.attributes?.user?.data?.attributes
                      ?.followers?.data?.length
                  }
                </Col>
                <Col>Followers</Col>
                <Divider
                  type="vertical"
                  style={{ borderWidth: "2px", height: "25px" }}
                />
                <Col>
                  {
                    userInfo?.data?.attributes?.user?.data?.attributes?.badges
                      ?.data?.length
                  }
                </Col>
                <Col>Badges</Col>
              </Row>
            </Col>
            <Divider />
          </Row>

          <Row>
            <Space direction="vertical" size="middle">
              <Typography.Title level={5}>Basic Information</Typography.Title>
              <Row gutter={[8, 0]}>
                <Col>
                  <Typography.Text
                    style={{
                      color: "#808080",
                      marginBottom: "-0.5rem",
                      display: "block",
                    }}
                  >
                    Age:
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text>{age} years old</Typography.Text>
                </Col>
              </Row>

              <Row gutter={[8, 0]}>
                <Col>
                  <Typography.Text
                    style={{
                      color: "#808080",
                      marginBottom: "-0.5rem",
                      display: "block",
                    }}
                  >
                    Gender:
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text>
                    {userInfo?.data?.attributes?.gender}
                  </Typography.Text>
                </Col>
              </Row>

              <Row gutter={[8, 0]}>
                <Col>
                  <Typography.Text
                    style={{
                      color: "#808080",
                      marginBottom: "-0.5rem",
                      display: "block",
                    }}
                  >
                    Blood Group:
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text>
                    {
                      userInfo?.data?.attributes?.bloodGroup?.data?.attributes
                        ?.name
                    }
                  </Typography.Text>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col>
                  <Typography.Text
                    style={{
                      color: "#808080",
                      marginBottom: "-0.5rem",
                      display: "block",
                    }}
                  >
                    Occupation:
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text>
                    {userInfo?.data?.attributes?.occupation}
                  </Typography.Text>
                </Col>
              </Row>
              <Divider />

              <Row>
                <Space direction="vertical" size="middle">
                  <Typography.Title level={5}>
                    Contact Information
                  </Typography.Title>
                  <Row gutter={[8, 0]}>
                    <Col>
                      <Typography.Text
                        style={{
                          color: "#808080",
                          marginBottom: "-0.5rem",
                          display: "block",
                        }}
                      >
                        Email Address:
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text>
                        {
                          userInfo?.data?.attributes?.user?.data?.attributes
                            ?.email
                        }
                      </Typography.Text>
                    </Col>
                  </Row>

                  <Row gutter={[8, 0]}>
                    <Col>
                      <Typography.Text
                        style={{
                          color: "#808080",
                          marginBottom: "-0.5rem",
                          display: "block",
                        }}
                      >
                        Phone:
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text>
                        {
                          userInfo?.data?.attributes?.user?.data?.attributes
                            ?.phone
                        }
                      </Typography.Text>
                    </Col>
                  </Row>

                  <Row gutter={[8, 0]}>
                    <Col>
                      <Typography.Text
                        style={{
                          color: "#808080",
                          marginBottom: "-0.5rem",
                          display: "block",
                        }}
                      >
                        Address:
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text>
                        {
                          userInfo?.data?.attributes?.user?.data?.attributes
                            ?.currentAddress?.city
                        }
                      </Typography.Text>
                    </Col>
                  </Row>
                </Space>
              </Row>
            </Space>
          </Row>
        </div>

        <div className={css.descriptionPart}>
          {from === "admin" ? (
            <AdminSettings />
          ) : (
            <DonorDetail
              userId={userInfo?.data?.id}
              userSettings={
                userInfo?.data?.attributes?.user?.data?.attributes?.userSetting
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DonorInformation;
