import { Image, Table } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import css from "../../styles/Blogs/blog.module.scss";

const OrganizerEventTabList = ({ userId, organizerInfo }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let dataSource = organizerInfo?.data?.map((f, i) => ({
    id: f?.id,
    name: f?.attributes?.name,
    avatarUrl: f?.attributes?.featureImage?.data?.attributes?.url,
    location: f?.attributes?.mapLocation?.place_name || "--",
    noOfParticipants: f?.attributes?.noOfParticipants || "-",
    date: moment.utc(f?.attributes?.eventDateTime).format("YYYY-MM-DD"),
  }));
  const columns = [
    {
      title: "Event Name",
      key: "name",
      render: (row) => {
        return (
          <div className={css.profileImage}>
            <Image
              src={row?.avatarUrl}
              alt="image"
              width={30}
              height={30}
              style={{ objectFit: "cover", borderRadius: "50%" }}
            />
            <div onClick={() => navigate(`/dashboard/admin/events/${row?.id}`)}>
              {row?.name}
            </div>
          </div>
        );
      },
    },

    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },

    {
      title: "No of Participants",
      key: "noOfParticipants",
      dataIndex: "noOfParticipants",
    },
  ];

  return (
    <div>
      <Table
        bordered
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        pagination={{
          responsive: true,
          prevIcon: <GrLinkPrevious />,
          nextIcon: <GrLinkNext />,
          defaultCurrent: 1,
          total: organizerInfo?.data?.length,
          pageSize: 10,
        }}
      />
    </div>
  );
};

export default OrganizerEventTabList;
