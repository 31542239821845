import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Typography,
  Upload,
} from "antd";
import React, { useState } from "react";
import css from "../../styles/addModal.module.scss";
import addCamera from "../../Images/addCamera.svg";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "antd/es/input/TextArea";
import { postPartners } from "../../features/partnerSlice";
import ShowFile from "../showFile/ShowFile";

const AddPartner = (props) => {
  const dispatch = useDispatch();
  const [formInstance] = Form.useForm();
  props.form.resetFields = formInstance.resetFields;
  const [imageError, setImageError] = useState(null);
  const [imgSrc, setImgSrc] = useState("");

  const onSubmit = (data) => {
    let valid = true;
    if (imgSrc === "") {
      valid = false;
      setImageError("Feature image is required");
    }
    if (valid) {
      const formData = new FormData();
      
      // if (imgSrc !== "") {
      formData.append("files.logo", imgSrc, imgSrc.name);
      // }
      formData.append("data", JSON.stringify(data));
      formInstance.resetFields();
      dispatch(postPartners(formData));
      setImgSrc("");
    }
  };
  const handleCancel = () => {
    setImgSrc("");
    props.setIsModalOpen(false);
  };

  const loading = useSelector((state) => state.partners.loading);

  return (
    <div className={css.Container}>
      <Row className={css.title}>Add Partner</Row>
      <Form layout="vertical" form={formInstance} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Name is required",
                },
              ]}
            >
              <Input className={css.input} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email is required",
                },
              ]}
            >
              <Input type="email" className={css.input} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Organization URL"
              name="website"
              rules={[
                {
                  required: true,
                  message: "URL is required",
                },
              ]}
            >
              <Input className={css.input} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Category"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Category is required",
                },
              ]}
            >
              <Select
                options={[
                  {
                    label: "Business",
                    value: "business",
                  },
                  {
                    label: "Technical",
                    value: "technical",
                  },
                  {
                    label: "Health",
                    value: "health",
                  },
                  {
                    label: "Media",
                    value: "media",
                  },
                  {
                    label: "Social",
                    value: "social",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Phone"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Phone is required",
                },
              ]}
            >
              <Input className={css.input} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={2}>
            <Form.Item label="Verified" name="verified" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={2}>
            <Form.Item label="Pin" name="pin" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Message" name="message">
              <TextArea className={css.input} />
            </Form.Item>
          </Col>

          <Divider />
          <Col>
            <Row justify={"center"}>
              <Row style={{ marginRight: "8px" }}>
                {imgSrc !== "" && <ShowFile imgSrc={imgSrc} />}
              </Row>
              <Col>
                <Upload
                  showUploadList={false}
                  listType="picture-card"
                  accept=".jpg, .png"
                  beforeUpload={(file) => {
                    setImgSrc({ file }.file);
                    return false;
                  }}
                  onRemove={() => setImgSrc("")}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ display: "flex", gap: "0.5rem" }}>
                      <div className={css.uploadImgDiv}>
                        <img src={addCamera} alt="addCamera" />
                      </div>
                      <span className={css.uploadBtn}>Attachment Upload</span>
                    </div>
                    <span className={css.uploadText}>Upload Picture</span>
                    <span className={css.uploadText}>
                      Only in jpg and png format.
                    </span>
                  </div>
                </Upload>
              </Col>
            </Row>
            <Typography.Text style={{color:"red"}}>{imageError}</Typography.Text>
          </Col>
          <Divider />
        </Row>
        <Row justify={"end"} style={{ gap: "1rem" }}>
          <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={loading}>
            Add
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default AddPartner;
