import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import css from "../../styles/Details/userDetail.module.scss";
import AcceptorsTab from "../detailTabs/AcceptorsTab";
import AttachmentTab from "../detailTabs/AttachmentTab";
import BasicBloodNeedInfo from "../detailTabs/BasicBloodNeedInfo";
import ReportsTab from "../detailTabs/ReportsTab";
const qs = require("qs");

const BloodNeedDetailTab = () => {
  const info = useSelector((state) => state.bloodNeed.bloodNeedDetail);
  const bloodNeedInfo = useSelector((state) => state.bloodNeed.bloodNeedDetail);

  const tabItems = [
    {
      label: "Basic Information",
      key: "1",
      children: <BasicBloodNeedInfo info={info} />,
    },

    {
      label: "Acceptors",
      key: "2",
      children: (
        <AcceptorsTab acceptors={info?.data?.attributes?.acceptors?.data} />
      ),
    },

    {
      label: "Attachments",
      key: "3",
      children: (
        <AttachmentTab
          attachments={info?.data?.attributes?.attachments?.data}
        />
      ),
    },

    {
      label: "Reports",
      key: "4",
      children: (
        <ReportsTab
          attachments={bloodNeedInfo?.data?.attributes?.reports?.data}
        />
      ),
    },
  ];
  return (
    <div className={css.tabs}>
      <Tabs defaultActiveKey="1" items={tabItems} />
    </div>
  );
};

export default BloodNeedDetailTab;
