import {
  Button,
  Col,
  Divider,
  Image,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";
import React, { useState } from "react";
import { getUsersById } from "../../features/userSlice";
import noProfile from "../../Images/Users/userProfile.png";
import AdminSettings from "../../pages/adminSettings/AdminSettings";
import css from "../../styles/Details/userDetail.module.scss";
import VerifyDonor from "../Layout/VerifyDonor";
import UserDetailTabs from "./UserDetailTabs";

const UserInformation = ({ userInfo, age, from, id }) => {
  const [verifyModal, setVerifyModal] = useState(false);
  return (
    <div className={css.userDetailContainer}>
      <div className={css.content}>
        <div className={css.imagePart}>
          <Row
            style={{ flexDirection: "column" }}
            align={"middle"}
            gutter={[0, 16]}
          >
            <Col>
              <Image
                src={userInfo?.avatar?.url || userInfo?.avatarUrl || noProfile}
                alt="profileImage"
                className={css.profileImage}
                width={150}
                height={150}
                preview={false}
              />
            </Col>
            <Typography.Title level={3} style={{ marginBottom: "-1rem" }}>
              {userInfo?.firstName} {userInfo.lastName}
            </Typography.Title>
            <Typography.Text style={{ marginTop: "0" }}>
              {userInfo?.role?.name}
            </Typography.Text>
            <Col>
              <Row gutter={[8]} align={"middle"}>
                <Col>{userInfo?.followers?.length}</Col>
                <Col>Followers</Col>
                <Divider
                  type="vertical"
                  style={{ borderWidth: "2px", height: "25px" }}
                />
                <Col>{userInfo?.badges?.length}</Col>
                <Col>Badges</Col>
              </Row>
            </Col>
            <Divider />
          </Row>

          <Row>
            <Space direction="vertical" size="middle">
              <Typography.Title level={5}>Basic Information</Typography.Title>
              <Row gutter={[8, 0]}>
                <Col>
                  <Typography.Text
                    style={{
                      color: "#808080",
                      marginBottom: "-0.5rem",
                      display: "block",
                    }}
                  >
                    Age:
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text>
                    {userInfo?.donorProfile ? `${age} years old` : "-"}
                  </Typography.Text>
                </Col>
              </Row>

              <Row gutter={[8, 0]}>
                <Col>
                  <Typography.Text
                    style={{
                      color: "#808080",
                      marginBottom: "-0.5rem",
                      display: "block",
                    }}
                  >
                    Gender:
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text>
                    {userInfo?.donorProfile
                      ? userInfo?.donorProfile?.gender
                      : "-"}
                  </Typography.Text>
                </Col>
              </Row>

              <Row gutter={[8, 0]}>
                <Col>
                  <Typography.Text
                    style={{
                      color: "#808080",
                      marginBottom: "-0.5rem",
                      display: "block",
                    }}
                  >
                    Blood Group:
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text>
                    {userInfo?.donorProfile
                      ? userInfo?.donorProfile?.bloodGroup?.name
                      : "-"}
                  </Typography.Text>
                </Col>
              </Row>

              <Row>
                <Space direction="vertical" size="middle">
                  <Typography.Title level={5}>
                    Contact Information
                  </Typography.Title>
                  <Row gutter={[8, 0]}>
                    <Col>
                      <Typography.Text
                        style={{
                          color: "#808080",
                          marginBottom: "-0.5rem",
                          display: "block",
                        }}
                      >
                        Email Address:
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text>{userInfo?.email}</Typography.Text>
                    </Col>
                  </Row>

                  <Row gutter={[8, 0]}>
                    <Col>
                      <Typography.Text
                        style={{
                          color: "#808080",
                          marginBottom: "-0.5rem",
                          display: "block",
                        }}
                      >
                        Phone:
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text>{userInfo?.phone}</Typography.Text>
                    </Col>
                  </Row>

                  <Row gutter={[8, 0]}>
                    <Col>
                      <Typography.Text
                        style={{
                          color: "#808080",
                          marginBottom: "-0.5rem",
                          display: "block",
                        }}
                      >
                        Address:
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text>
                        {userInfo?.currentAddress
                          ? userInfo?.currentAddress?.city
                          : "-"}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Space>
              </Row>
            </Space>
          </Row>
          <Divider />
          <Space direction="vertical" size={"large"}>
            <Typography.Title level={5} style={{ margin: "0" }}>
              Verify Phone:
            </Typography.Title>
            {/* <Typography.Text>{info?.IdType}</Typography.Text> */}
          </Space>
          <br />
          <br />
          <Row justify={"start"} style={{ gap: "1rem" }}>
            <Button
              disabled={userInfo?.phoneVerified === true}
              htmlType="submit"
              type="primary"
              onClick={() => setVerifyModal(true)}
            >
              {userInfo?.phoneVerified === true ? "Verified" : "Verify"}
            </Button>
            <Modal
              title={"Verify Confirmation"}
              open={verifyModal}
              footer={null}
              centered={true}
              maskClosable={false}
              keyboard={false}
              onCancel={() => setVerifyModal(false)}
            >
              <VerifyDonor
                setVerifyModal={setVerifyModal}
                path="users"
                id={id}
                name={true}
                reloadall={getUsersById}
                userId={
                  userInfo?.attributes?.organizer?.data?.attributes?.owner?.data
                    ?.id
                }
                body="Congratulations, your user's phone number has been verified."
                screen="EventDetail"
                // paramId={userInfo?.id}
                allUserInfo={userInfo}
              />
            </Modal>
          </Row>
        </div>
        <div className={css.descriptionPart}>
          {from === "admin" ? (
            <AdminSettings />
          ) : (
            <UserDetailTabs
              userId={userInfo?.id}
              referBy={userInfo?.referBy?.id}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserInformation;
