import { Button, Col, Form, Row } from "antd";
import React from "react";
import css from "../../styles/filter.module.scss";
import { useDispatch } from "react-redux";
import { getFeedback } from "../../features/feedbackSlice";

const Filter = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch()

  const onSubmit = (data) => {
    // TODO: sending only data which needs to be filtered
    const filterData = {};
    for (let index = 0; index < Object.keys(data).length; index++) {
      if (data[Object.keys(data)[index]] !== undefined) {
        filterData[Object.keys(data)[index]] = data[Object.keys(data)[index]];
      }
    }
    props.setFilteredData(filterData)

    dispatch(
      getFeedback({
        params: {
          page: 1,
          pageSize: 8,
        },
        filter: filterData,
      })
    );

    props.setFilterModal(false);
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    props.setFilterModal(false);
  };

  return (
    <div className={css.filterContainer}>
      <Row className={css.title}>Filter</Row>
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Row gutter={[32, 0]} justify="space-between">
          <Col span={12}>
            <Row gutter={[10, 10]}>
              <Col span={24} className={css.subTitle}>
                Full Name
              </Col>
              <Col span={24}>
                <input type="text" />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[10, 10]}>
              <Col span={24} className={css.subTitle}>
                Review
              </Col>
              <Col span={24}>
                <input type="text" />
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row gutter={[10, 10]}>
              <Col span={24} className={css.subTitle}>
                Rating
              </Col>
              <Col span={24}>
                <input type="number" />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[10, 10]}>
              <Col span={24} className={css.subTitle}>
                Date
              </Col>
              <Col span={24}>
                <input type="date" />
              </Col>
            </Row>
          </Col>
        </Row>


        <Row justify={"end"} style={{ gap: "1rem", marginTop: '20px' }}>
          <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary">
            Filter
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default Filter;
