import { BASEURL } from "./Url";

import axios from "axios";


const apiConfig = {
  baseURL: BASEURL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

export const api = axios.create(apiConfig);
