import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recentBlogs: [
    {
      title: "Ganga Lal Shrestha donated 4 units to Mithun Dhakal",
      date: "Nov 11",
      time: "10:15 am",
    },
    {
      title:
        "Steven Gautam Posted urgent request of 6 units B+ blood group until Nov, 24 10 am",
      date: "Nov 11",
      time: "10:15 am",
    },
    {
      title: "Ganga Lal Shrestha donated 4 units to Mithun Dhakal",
      date: "Nov 11",
      time: "10:15 am",
    },
    {
      title:
        "Steven Gautam Posted urgent request of 6 units B+ blood group until Nov, 24 10 am",
      date: "Nov 11",
      time: "10:15 am",
    },
    {
      title: "Ganga Lal Shrestha donated 4 units to Mithun Dhakal",
      date: "Nov 11",
      time: "10:15 am",
    },
    {
      title: "Ganga Lal Shrestha donated 4 units to Mithun Dhakal",
      date: "Nov 11",
      time: "10:15 am",
    },
    {
      title:
        "Steven Gautam Posted urgent request of 6 units B+ blood group until Nov, 24 10 am",
      date: "Nov 11",
      time: "10:15 am",
    },
    {
      title: "Ganga Lal Shrestha donated 4 units to Mithun Dhakal",
      date: "Nov 11",
      time: "10:15 am",
    },
  ],
};

const recentBlogSlice = createSlice({
    name:"recentBlogs",
    initialState,
    reducers:{}
})

export default recentBlogSlice.reducer