import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { api } from "../Api";
import { BASEURL } from "../Url";
import { getFCM } from "../components/SendPushNotification";
import { sendReward } from "./rewardSlice";

const initialState = {
  blog: [],
  loading: false,
  error: "",
  blogDetail: [],
  storyCount: 0,
};

// TODO: get Blog
const qs = require("qs");

export const getBlog = createAsyncThunk(
  "getBlog",
  async ({ params, filter }) => {
    const query = qs.stringify({
      pagination: params,
      filters: { type: { $eqi: filter?.type }, pin: filter?.pin },
      sort: ["updatedAt:desc"],
    });
    const res = await api.get(`${BASEURL}/blogs?populate=*&${query}`);
    return res.data;
  }
);

export const getBlogDetail = createAsyncThunk("getBlogDetail", async (id) => {
  const query = qs.stringify({
    populate: ["user", "attachment"],
    sort: ["updatedAt:desc"],
  });
  const res = await api.get(`${BASEURL}/blogs/${id}?populate=*&${query}`);
  return res.data;
});

// TODO: post Blog
export const postBlog = createAsyncThunk("postBlog", async (data, thunkApi) => {
  try {
    const res = await api.post(`${BASEURL}/blogs`, data);
    thunkApi.dispatch(
      getBlog({
        params: { page: 1, pageSize: 8 },
        filter: { type: ["blog", "story"] },
      })
    );
    return res.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data.error.message);
  }
});

// TODO: post page
export const postPage = createAsyncThunk("postPage", async (data, thunkApi) => {
  try {
    const res = await api.post(`${BASEURL}/blogs`, data);
    thunkApi.dispatch(
      getBlog({ params: { page: 1, pageSize: 8 }, filter: { type: "page" } })
    );
    return res.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data.error.message);
  }
});

// TODO: pin unpin blog
export const pinUnpin = createAsyncThunk(
  "pinUnpin",
  async ({ data, id, authorId }, thunkApi) => {
    const res = await api.put(`${BASEURL}/blogs/${id}`, data);
    thunkApi.dispatch(
      getBlog({
        params: { page: 1, pageSize: 8 },
        filter: { type: ["blog", "story"] },
      })
    );

    if (data?.data?.pin) {
      getFCM(
        authorId,
        "HBB Story Notification",
        "Your Story has been verified.",
        "Detail",
        id
      );
    }

    return res.data;
  }
);

// TODO: pin unpin blog
export const pinUnpinPage = createAsyncThunk(
  "pinUnpin",
  async ({ data, id, authorId }, thunkApi) => {
    const res = await api.put(`${BASEURL}/blogs/${id}`, data);
    thunkApi.dispatch(
      getBlog({
        params: { page: 1, pageSize: 8 },
        filter: { type: ["page"] },
      })
    );

    return res.data;
  }
);

// TODO: update Blog

export const updateBlog = createAsyncThunk(
  "updateBlog",
  async ({ formData, id, badges, userId, pin }, thunkApi) => {
    try {
      const res = await api.put(`${BASEURL}/blogs/${id}`, formData);
      thunkApi.dispatch(getBlogDetail(id));

      if (pin) {
        thunkApi.dispatch(getStoriesOfSingleUser({ id: userId, badges }));
      }

      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response);
    }
  }
);

export const updatePage = createAsyncThunk(
  "updatePage",
  async ({ formData, id }, thunkApi) => {
    try {
      const res = await api.put(`${BASEURL}/blogs/${id}`, formData);
      thunkApi.dispatch(getBlogDetail(id));
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response);
    }
  }
);

// TODO: delete Blog

export const deleteBlog = createAsyncThunk(
  "deleteBlog",
  async (id, thunkApi) => {
    const res = await api.delete(`${BASEURL}/blogs/${id}`);
    thunkApi.dispatch(
      getBlog({
        params: { page: 1, pageSize: 8 },
        filter: { type: ["blog", "story"] },
      })
    );
    return res.data;
  }
);

export const deletePage = createAsyncThunk(
  "deletePage",
  async (id, thunkApi) => {
    const res = await api.delete(`${BASEURL}/blogs/${id}`);
    thunkApi.dispatch(
      getBlog({ params: { page: 1, pageSize: 8 }, filter: { type: "page" } })
    );
    return res.data;
  }
);

// TODO: get stories of a user

export const getStoriesOfSingleUser = createAsyncThunk(
  "getStoriesOfSingleUser",
  async ({ id, badges }, thunkApi) => {
    const query = qs.stringify({
      filters: {
        user: id,
      },
    });
    try {
      const res = await api.get(`${BASEURL}/blogs/?${query}`);

      const donationBadges = badges?.filter(
        (item) => item?.attributes?.metaData?.related === "story"
      );
      donationBadges?.forEach((item) => {
        if (item?.attributes?.metaData?.criteria == res.data?.data?.length) {
          thunkApi.dispatch(
            sendReward({
              badgeId: item?.id,
              reason: item?.attributes?.description,
              rewardPoint: item?.attributes?.bonusRewards,
              userId: id,
              body: `Congratulations!!! You got ${item?.attributes?.name} badge.`,
              screen: "Badges",
            })
          );
        }
      });
      return res.data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

const blogSlice = createSlice({
  name: "blogs",
  initialState,
  extraReducers: {
    // TODO: get
    [getBlog.pending]: (state, action) => {
      state.loading = true;
    },
    [getBlog.fulfilled]: (state, action) => {
      state.loading = false;
      state.blog = action.payload;
    },
    [getBlog.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.error.message,
      });
    },

    // TODO: get
    [getBlogDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [getBlogDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.blogDetail = action.payload;
    },
    [getBlogDetail.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: post
    [postBlog.pending]: (state, action) => {
      state.loading = true;
    },
    [postBlog.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Blog Added",
        confirmButtonColor: "#4BB543",
        text: "Blog is successfully added",
      });
    },
    [postBlog.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: post
    [postPage.pending]: (state, action) => {
      state.loading = true;
    },
    [postPage.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Page Created",
        confirmButtonColor: "#4BB543",
        text: "Page is successfully created",
      });
    },
    [postPage.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: Update
    [updateBlog.pending]: (state, action) => {
      state.loading = true;
    },
    [updateBlog.fulfilled]: (state, action) => {
      Swal.fire({
        icon: "success",
        title: "Blog Updated",
        confirmButtonColor: "#4BB543",
        text: "Blog is successfully updated",
      });
      state.loading = false;
    },
    [updateBlog.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.payload.data.error.message,
      });
    },

    [updatePage.pending]: (state, action) => {
      state.loading = true;
    },
    [updatePage.fulfilled]: (state, action) => {
      Swal.fire({
        icon: "success",
        title: "Page Updated",
        confirmButtonColor: "#4BB543",
        text: "Page is successfully updated",
      });
      state.loading = false;
    },
    [updatePage.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.payload.data.error.message,
      });
    },

    // TODO: Delete
    [deleteBlog.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteBlog.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Blog Deleted",
        confirmButtonColor: "#4BB543",
        text: "Blog is successfully deleted",
      });
    },
    [deleteBlog.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    [deletePage.pending]: (state, action) => {
      state.loading = true;
    },
    [deletePage.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Page Deleted",
        confirmButtonColor: "#4BB543",
        text: "Page is successfully deleted",
      });
    },
    [deletePage.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    //TODO: pinUnpin blogs

    [pinUnpin.pending]: (state, action) => {
      state.loading = true;
    },
    [pinUnpin.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [pinUnpin.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.error.message,
        confirmButtonColor: "#B00020",
      });
    },

    //TODO: get stories count of a user

    [getStoriesOfSingleUser.fulfilled]: (state, action) => {
      state.storyCount = action.payload?.data?.length;
    },
  },
});

export default blogSlice.reducer;
