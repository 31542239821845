import { Col, Modal, Popconfirm, Row, Switch, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BiAddToQueue } from "react-icons/bi";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import EditDots from "../../components/common/EditDots";
import FilterSearch from "../../components/common/FilterSearch";
import DeleteConfirmation from "../../components/Layout/DeleteConfirmation";
import Filter from "../../components/Layout/FilterPage";
import TopBar from "../../components/Layout/TopBar";
import { deletePage, getBlog, pinUnpinPage } from "../../features/blogSlice";
import editIcon from "../../Images/Users/editIcon.svg";
import css from "../../styles/Blogs/blog.module.scss";

const Page = () => {
  const parser = new DOMParser();
  const [filteredData, setFilteredData] = useState({
    type: "page",
  });

  // TODO: fetch Blog data
  const dispatch = useDispatch();
  // TODO: get faq data when render
  const [params, setParams] = useState({
    page: 1,
    pageSize: 8,
  });

  useEffect(() => {
    dispatch(getBlog({ params, filter: filteredData }));
  }, [dispatch, params]);

  const [editModal, setEditModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);

  // bring user data from store
  let blogData = useSelector((state) => state.blog.blog);
  let isLoading = useSelector((state) => state.blog.loading);

  // handle search
  const [search, setSearch] = useState("");

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const fields = ["title", "author", "type"];

  let dataSource = blogData?.data?.map((f, i) => ({
    id: f?.id,
    title: f?.attributes?.title,
    slug: f?.attributes?.slug,
    status: f?.attributes?.pin,
    date: moment.utc(f?.attributes?.createdAt).format("YYYY-MM-DD"),
    modifiedDate: moment.utc(f?.attributes?.updatedAt).format("YYYY-MM-DD"),
    authorId: f?.attributes?.user?.data?.id,
  }));

  if (search) {
    const filteredData = dataSource?.filter((data) => {
      return fields.some((field) =>
        data?.[field]?.toLowerCase().includes(search.toLowerCase())
      );
    });
    dataSource = filteredData;
  }

  const [edit, setEdit] = useState({
    key: 0,
  });

  const columns = [
    {
      title: "Page Title",
      key: "title",
      dataIndex: "title",
    },

    {
      title: "Page Slug",
      key: "slug",
      dataIndex: "slug",
    },

    {
      title: "Created At",
      dataIndex: "date",
      key: "date",
    },

    {
      title: "Modified At",
      dataIndex: "modifiedDate",
      key: "modifiedDate",
    },

    {
      title: "Pin",
      key: "status",
      render: (row, record) => {
        return (
          <Popconfirm
            overlayClassName={css.pop}
            title="Are you sure?"
            okText="Yes"
            cancelText="No"
            onConfirm={() =>
              dispatch(
                pinUnpinPage({
                  data: { data: { pin: !row?.status } },
                  id: row?.id,
                })
              )
            }
          >
            <Switch checked={row?.status} />
          </Popconfirm>
        );
      },
    },

    {
      title: "Action",
      render: (row) => {
        return (
          <EditDots
            icon={editIcon}
            setEdit={setEdit}
            row={row}
            edit={edit}
            setEditModal={setEditModal}
            setDeleteModal={setDeleteModal}
            from="page"
            link="/dashboard/edit-page"
          />
        );
      },
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSelectChange = (newSelectedRowKeys) => {
    // console.log("newSelectedRowKeys", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  // console.log("selectedRowKeys changed: ", selectedRowKeys);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const [hasSelected, setHasSelected] = useState(false);
  useEffect(() => {
    setHasSelected(selectedRowKeys.length > 0);
  }, [selectedRowKeys?.length]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [params]);

  const start = (key) => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      alert(key);
      setLoading(false);
    }, 1000);
  };

  // dropdown functions
  function handleMenuClick(e) {
    // message.info("Click on menu item.");
    // console.log("click", e);
    start(e.key);
  }

  /* dropdown action */
  const items = [
    {
      label: "Send Email",
      key: "1",
    },
    {
      label: "Delete",
      key: "2",
    },
  ];
  const menu = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div>
      <TopBar topic={"Pages"} link="category" category={true} />
      <div className={css.blogContent}>
        <Row
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <Col>
            {/* <Dropdown menu={menu} disabled={!hasSelected}>
              <Button
                loading={loading}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                Actions <BsChevronDown />
              </Button>
            </Dropdown> */}
          </Col>

          {/* search filter and add new */}
          <FilterSearch
            handleSearch={handleSearch}
            setFilterModal={setFilterModal}
            setIsModalOpen={setIsModalOpen}
            title="Add Page"
            show={true}
            showFilter={true}
            filterModal={filterModal}
            content={
              <Filter
                setFilterModal={setFilterModal}
                filterModal={filterModal}
                setFilteredData={setFilteredData}
              />
            }
            icon={<BiAddToQueue className={css.filterIcon} />}
            from="page"
          />
        </Row>

        {/* table here */}

        <Table
          scroll={{
            x: 1500,
          }}
          loading={isLoading}
          // rowSelection={rowSelection}
          dataSource={dataSource}
          bordered
          columns={columns}
          pagination={{
            responsive: true,
            prevIcon: <GrLinkPrevious />,
            nextIcon: <GrLinkNext />,
            current: params.page,
            showTitle: false,
            defaultCurrent: 1,
            total: blogData?.meta?.pagination?.total,
            pageSize: params.pageSize,
            onChange: (page) => {
              setParams({
                ...params,
                page,
              });
            },
          }}
        />
      </div>
      {/* modal */}

      <Modal
        title={"Delete Confirmation"}
        open={deleteModal}
        footer={null}
        centered={true}
        keyboard={false}
        onCancel={() => setDeleteModal(false)}
        maskClosable={false}
      >
        <DeleteConfirmation
          fromDelete={true}
          setDeleteModal={setDeleteModal}
          id={edit?.key}
          delete={deletePage}
          get={getBlog}
          pageSize={params.pageSize}
        />
      </Modal>
    </div>
  );
};

export default Page;
