import { Row } from "antd";
import React from "react";
import css from "../../styles/Loading.module.scss";
import logo from "../../Images/Navigation/icon.svg";

export default function Loading({ isSmall }) {
  return (
    <Row className={css.contentCenter}>
      <div className={isSmall ? css.smallSpinner : css.loadingSpinner}></div>
      {/* <img src={logo} className={css.logoLoading} /> */}
    </Row>
  );
}
