import { Col, Row } from "antd";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getHistory } from "../../features/historySlice";
import css from "../../styles/addModal.module.scss";

const Filter = (props) => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // TODO: sending only data which needs to be filtered
    const filterData = {};
    for (let index = 0; index < Object.keys(data).length; index++) {
      if (data[Object.keys(data)[index]] !== "") {
        filterData[Object.keys(data)[index]] = data[Object.keys(data)[index]];
      }
    }

    dispatch(
      getHistory({
        params: {
          page: 1,
          pageSize: 8,
        },
        filter: filterData,
      })
    );

    // TODO: Fill atleast one field

    if (Object.keys(filterData).length > 0) {
      props.setFilterModal(false);

      reset();
    }
  };

  const handleCancel = () => {
    props.setFilterModal(false);
    clearErrors();
  };

  return (
    <form className={css.Container} onSubmit={handleSubmit(onSubmit)}>
      <Row className={css.title}>Filter</Row>
      <Row gutter={[32, 10]} justify="space-between">
        <Col span={12}>
          <Row gutter={[10, 10]}>
            <Col span={24} className={css.subTitle}>
              Donated By
            </Col>
            <Col span={24}>
              <input type="text" />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={[10, 10]}>
            <Col span={24} className={css.subTitle}>
              Donated To
            </Col>
            <Col span={24}>
              <input type="text" />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[32, 10]} justify="space-between">
        <Col span={12}>
          <Row gutter={[10, 10]}>
            <Col span={24} className={css.subTitle}>
              Donor Age
            </Col>
            <Col span={24}>
              <input type="number" />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={[10, 10]}>
            <Col span={24} className={css.subTitle}>
              Patient Age
            </Col>
            <Col span={24}>
              <input type="number" />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[32, 10]} justify="space-between">
        <Col span={12}>
          <Row gutter={[10, 10]}>
            <Col span={24} className={css.subTitle}>
              Request Reason
            </Col>
            <Col span={24}>
              <input type="text" />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={[10, 10]}>
            <Col span={24} className={css.subTitle}>
              Donted Units
            </Col>
            <Col span={24}>
              <input type="number" />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[32, 10]} justify="space-between">
        <Col span={12}>
          <Row gutter={[32, 10]}>
            <Col span={24} className={css.subTitle}>
              Blood Type
            </Col>
            <Col span={24}>
              <select name="bloodType" id="bloodType">
                <option value="null" selected></option>
                <option value="O+">A+</option>
                <option value="B+">B+</option>
                <option value="AB+">O+</option>
                <option value="O+">AB+</option>
                <option value="B+">A-</option>
                <option value="AB+">B-</option>
                <option value="AB+">O-</option>
                <option value="AB+">AB-</option>
              </select>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={[32, 10]}>
            <Col span={24} className={css.subTitle}>
              Donated Location
            </Col>
            <Col span={24}>
              <input type="text" />
            </Col>
          </Row>
        </Col>
      </Row>
      <div className={css.Btns}>
        <button className={css.cancelBtn} type="reset" onClick={handleCancel}>
          Cancel
        </button>
        <button className={css.okBtn} type="submit">
          OK
        </button>
      </div>
    </form>
  );
};

export default Filter;
