import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";
import { api } from "../Api";
import { BASEURL } from "../Url";
const qs = require("qs");

const initialState = {
  loggedInUser: [],
  loading: false,
  token: "",
};

export const logIn = createAsyncThunk("login", async (data) => {
  const res = await axios.post(`${BASEURL}/auth/local/`, data);
  return res.data.jwt;
});

export const changePw = createAsyncThunk("changePw", async ({ data }) => {
  const res = await api.post(`${BASEURL}/auth/change-password`, data);
  return res.message;
});

const loginSlice = createSlice({
  name: "login",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logIn.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(logIn.fulfilled, (state, action) => {
      state.token = action.payload;
      state.loading = false;
    });
    builder.addCase(logIn.rejected, (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    });

    builder.addCase(changePw.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(changePw.fulfilled, (state, action) => {
      state.loading = false;
      localStorage.removeItem("token");
      localStorage.removeItem("avatarUrl");
      localStorage.removeItem("role");
      localStorage.removeItem("name");
    });
    builder.addCase(changePw.rejected, (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action?.error?.message,
        confirmButtonColor: "#B00020",
      });
    });
  },
});

export default loginSlice.reducer;
