import { Button, Col, Dropdown, Modal, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { ImFilter } from "react-icons/im";
import TopBar from "../../../components/Layout/TopBar";
import searchIcon from "../../../Images/Topbar/Search.svg";
import css from "../../../styles/Role/role.module.scss";
import addUser from "../../../Images/Topbar/addUser.svg";
import editIcon from "../../../Images/Users/editIcon.svg";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import AddRole from "../../../components/Add/AddRole";
import Filter from "../../../components/Layout/FilterRole";
import { BsChevronDown } from "react-icons/bs";
import { getRoles } from "../../../features/roleSlice";
import EditDots from "../../../components/common/EditDots";
import { AiOutlineUserAdd } from "react-icons/ai";
import FilterSearch from "../../../components/common/FilterSearch";

const Role = () => {
  // TODO: fetch testimonial data
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  // TODO: for edit icon

  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterModal, setFilterModal] = useState(false);

  const [edit, setEdit] = useState({
    key: 0,
    toggle: false,
  });

  const handleOk = () => {
    setIsModalOpen(false);
    setFilterModal(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setFilterModal(false);
  };

  let rolesData = useSelector((state) => state.role.roles);
  let isLoading = useSelector((state) => state.role.loading);

  let tableData = rolesData?.roles?.map((data, index) => {
    return { ...data, key: index + 1 };
  });

  // handle search
  const [search, setSearch] = useState("");

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const fields = ["name", "description", "type"];

  if (search) {
    const filteredData = rolesData?.roles?.filter((data) => {
      return fields.some((field) =>
        data[field]?.toLowerCase().includes(search.toLowerCase())
      );
    });
    tableData = filteredData;
  }

  const columns = [
    {
      title: "Role",
      render: (row, record) => {
        return <span>{record?.name}</span>;
      },
      width: "55%",
    },

    {
      title: "Description",
      render: (row, record) => {
        return <span>{record?.description}</span>;
      },
      width: "45%",
    },
    // {
    //   title: "Permissions",
    //   render: (row, record) => {
    //     return <span>{record.permission}</span>;
    //   },
    // },

    // {
    //   title: "Action",
    //   render: (row) => {
    //     return (
    //       <EditDots
    //         icon={editIcon}
    //         setEdit={setEdit}
    //         row={row}
    //         edit={edit}
    //         setEditModal={setEditModal}
    //         setDeleteModal={setDeleteModal}
    //         changePw={<MdOutlineModeEditOutline />}
    //       />
    //     );
    //   },
    // },
  ];

  //row selection
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSelectChange = (newSelectedRowKeys) => {
    // console.log("newSelectedRowKeys", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  // console.log("selectedRowKeys changed: ", selectedRowKeys);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const start = (key) => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      alert(key);
      setLoading(false);
    }, 1000);
  };

  // dropdown functions
  function handleMenuClick(e) {
    // message.info("Click on menu item.");
    // console.log("click", e);
    start(e.key);
  }

  /* dropdown action */
  const items = [
    {
      label: "Send Email",
      key: "1",
    },
    {
      label: "Delete",
      key: "2",
    },
  ];
  const menu = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div>
      <TopBar topic="Roles" />
      <div className={css.roleContainer}>
        {/* TODO: search filter add role */}
        <Row
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <Col>
            {/* <Dropdown menu={menu} disabled={!hasSelected}>
              <Button
                loading={loading}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                Actions <BsChevronDown />
              </Button>
            </Dropdown> */}
          </Col>
          <FilterSearch
            handleSearch={handleSearch}
            setFilterModal={setFilterModal}
            setIsModalOpen={setIsModalOpen}
            showFilter={true}
            title="Add Role"
            icon={<AiOutlineUserAdd className={css.filterIcon} />}
          />
        </Row>

        {/* table here */}

        <Table
          scroll={{
            x: 1500,
          }}
          bordered
          loading={isLoading}
          // rowSelection={rowSelection}
          dataSource={tableData}
          columns={columns}
        />
      </div>

      {/* modal */}
      <Modal
        open={isModalOpen}
        footer={null}
        className={css.modalSignup}
        centered={true}
        keyboard={true}
        onCancel={() => setIsModalOpen(false)}
      >
        <AddRole setIsModalOpen={setIsModalOpen} />
      </Modal>

      <Modal
        open={filterModal}
        footer={null}
        className={css.modalSignup}
        centered={true}
        keyboard={true}
        onCancel={() => setFilterModal(false)}
      >
        <Filter setFilterModal={setFilterModal} />
      </Modal>
    </div>
  );
};

export default Role;
