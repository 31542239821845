import { Button, Col, Divider, Form, Input, Row } from "antd";
import JoditEditor from "jodit-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import TopBar from "../../components/Layout/TopBar";
import Loading from "../../components/common/Loading";
import { getBlogDetail, updatePage } from "../../features/blogSlice";
import css from "../../styles/addModal.module.scss";

const EditPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getBlogDetail(id));
  }, [dispatch, id]);

  // TODO: for editor
  const editor = useRef(null);
  const [detail, setDetail] = useState(null);

  const config = useMemo(
    () => ({
      uploader: { insertImageAsBase64URI: true },
      removeButtons: ["brush", "file"],
      showXPathInStatusbar: false,
      showCharsCounter: false,
      showWordsCounter: false,
      toolbarAdaptive: false,
      tabs: true,
    }),
    []
  );

  const loading = useSelector((state) => state.blog.loading);

  let blogData = useSelector((state) => state.blog.blogDetail);

  // TODO: onSubmit
  const onSubmit = (data) => {
    const formData = new FormData();
    const newData = {
      ...data,
      detail: detail,
      // slug: slugify(data.title, { lower: true }),
    };
    formData.append("data", JSON.stringify(newData));
    dispatch(updatePage({ formData, id: id }));
    navigate(-1);
  };
  const handleCancel = () => {
    navigate(-1);
  };

  const [formInstance] = Form.useForm();
  formInstance.setFieldsValue({
    title: blogData?.data?.attributes?.title,
    detail: blogData?.data?.attributes?.detail,
  });

  return (
    <div>
      <TopBar topic="Edit Page" />
      {loading ? (
        <Loading />
      ) : (
        <div className={css.editContent}>
          <Form layout="vertical" form={formInstance} onFinish={onSubmit}>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  label="Page Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Title is required",
                    },
                  ]}
                >
                  <Input className={css.input} />
                </Form.Item>
              </Col>
              <Divider />

              <Col span={24}>
                <Form.Item label="Page Description" name="detail">
                  <JoditEditor
                    className={css.editor}
                    ref={editor}
                    value={detail}
                    config={config}
                    onChange={(newContent) => setDetail(newContent)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify={"end"} style={{ gap: "1rem" }}>
              <Button
                type="primary"
                danger
                htmlType="reset"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button htmlType="submit" type="primary" loading={loading}>
                Save Changes
              </Button>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
};

export default EditPage;
