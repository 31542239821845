import { Button, Col, Form, Input, Row, Select, Slider } from "antd";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getUsers } from "../../features/userSlice";
import css from "../../styles/addModal.module.scss";
import { getEvents } from "../../features/eventSlice";
import { getAllOrganizations } from "../../features/organizerSlice";

const FilterEvent = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
const [slider, setSlider]= useState()
const [from, setFrom] = useState();
const [to, setTo] = useState();

  const onSubmit = (data) => {

    // TODO: sending only data which needs to be filtered
    const filterData = {
      ...data,
      noOfParticipants: slider,
      fromdate: data.fromdate, // Include fromdate field
      todate: data.todate,
    };
   if (filterData?.noOfParticipants  === undefined) {
      filterData["noOfParticipants"] = [0, 400];
    }
  
    props.setFilteredData(filterData);
    dispatch(
      getEvents({
        params: {
          page: 1,
          pageSize: 8,
        },
        filter: filterData,
      })
    );
    props.setFilterModal(false);
    form.resetFields();
  };
  const onSubmitOrg = (data) => {
    // TODO: sending only data which needs to be filtered
    const filterData = {};
    for (let index = 0; index < Object.keys(data).length; index++) {
      if (data[Object.keys(data)[index]] !== undefined) {
        filterData[Object.keys(data)[index]] = data[Object.keys(data)[index]];
      }
    }
    if (filterData?.noOfParticipants === undefined) {
      filterData["noOfParticipants"] = [0, 400];
    }
    props.setFilteredData(filterData);
    dispatch(
      getAllOrganizations({
        params: {
          page: 1,
          pageSize: 8,
        },
        filter: filterData,
      })
    );
    props.setFilterModal(false);
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    props.setFilterModal(false);
  };


  return (
    <div className={css.Container}>
      <Row className={css.title}>Filter</Row>
      <Form
        layout="vertical"
        onFinish={(props.event && onSubmit) || (props.organizer && onSubmitOrg)}
        form={form}
      >
        <Row gutter={[32, 0]} justify="space-between">
          {props.event && (
            <Col span={12}>
              <Form.Item name={"noOfParticipants"} label="No of Participants">
                <br />
                <br />
                <Slider
                  range
                  defaultValue={[0, 400]}
                  max={500}
                  value={slider}
                  onChange={(value) => setSlider(value)}
                  tooltip={{
                    open: true,
                  }}
                  style={{ width: "30rem" }}
                />
              </Form.Item>
            </Col>
          )}

          {props.organizer && (
            <Col span={12}>
              <Form.Item label="Status" name={"status"}>
                <Select
                  options={[
                    {
                      label: "Verified",
                      value: "Verified",
                    },
                    {
                      label: "Pending",
                      value: "Pending",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        {props.event && (
          <>
            {/* <Row gutter={[12, 0]}>
              <Col span={12}>
                <Form.Item name={"fromdate"} label="From">
                  <Input type="date"/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name={"todate"} label="To">
                  <Input type="date" />
                </Form.Item>
              </Col>
            </Row> */}
            <Col span={12}>
              <Form.Item label="Status" name={"status"}>
                <Select
                  options={[
                    {
                      value: "Pending",
                      label: "Pending",
                    },
                    {
                      value: "Verified",
                      label: "Verified",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </>
        )}
        <Row justify={"end"} style={{ gap: "1rem" }}>
          <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary">
            Filter
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default FilterEvent;
