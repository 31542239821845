import { Col, Image, Row } from "antd";
import React from "react";

const AttachmentTab = ({ attachments }) => {
  return (
    <Row gutter={[16, 16]}>
      {attachments?.map((f, i) => {
        return (
          <Col key={i} span={8}>
            <Image
              src={
                f?.attributes?.url ||
                f?.attributes?.attachment?.data?.attributes?.url
              }
              style={{
                border: "1px solid #D14144",
                borderRadius: "1rem",
                width: "15rem",
                height: "15rem",
                objectFit: "cover",
              }}
            />
          </Col>
        );
      })}
    </Row>
  );
};

export default AttachmentTab;
