import { Button, Col, Form, Input, Row } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { getReview } from "../../features/reviewSlice";
import css from "../../styles/filter.module.scss";

const Filter = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onSubmit = (data) => {
    // TODO: sending only data which needs to be filtered
    const filterData = {};
    for (let index = 0; index < Object.keys(data).length; index++) {
      if (data[Object.keys(data)[index]] !== undefined) {
        filterData[Object.keys(data)[index]] = data[Object.keys(data)[index]];
      }
    }
    props.setFilteredData(filterData)

    dispatch(
      getReview({
        params: {
          page: 1,
          pageSize: 8,
        },
        filter: filterData,
      })
    );

    // TODO: Fill atleast one field

    props.setFilterModal(false);
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    props.setFilterModal(false);
  };

  return (
    <div className={css.filterContainer}>
      <Row className={css.title}>Filter</Row>
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Row gutter={[32, 0]} justify="space-between">
          <Col span={12}>
            <Form.Item name={"username"} label="Rater">
              <Input />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item name={"date"} label="Date">
              <Input type="date" />
            </Form.Item>
          </Col> */}
          <Col span={12}>
            <Form.Item name={"to"} label="To User">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row justify={"end"} style={{ gap: "1rem" }}>
          <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary">
            Filter
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default Filter;
