import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import css from "../../styles/Details/userDetail.module.scss";
import AcceptorsTab from "../detailTabs/AcceptorsTab";
import AttachmentTab from "../detailTabs/AttachmentTab";
import BadgeWinner from "../detailTabs/BadgeWinner";
import BasicBloodNeedInfo from "../detailTabs/BasicBloodNeedInfo";
import { getBadgeWinners } from "../../features/badgeSlice";
const qs = require("qs");

const BadgeDetailTabs = ({ badgeId, setParams }) => {




  const tabItems = [
    {
      label: "Winners",
      key: "1",
      children: <BadgeWinner id={badgeId} />,
    },

    // {
    //   label: "Near to Win",
    //   key: "2",
    //   children: (
    //     <AcceptorsTab acceptors={info?.data?.attributes?.acceptors?.data} />
    //   ),
    // },

    // {
    //   label: "Reward Claimed",
    //   key: "3",
    //   children: (
    //     <AttachmentTab
    //       attachments={info?.data?.attributes?.attachments?.data}
    //     />
    //   ),
    // },
  ];
  return (
    <div className={css.tabs}>
      <Tabs defaultActiveKey="1" items={tabItems} />
    </div>
  );
};

export default BadgeDetailTabs;
