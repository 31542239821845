import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BloodNeedDetailTab from "../../components/bloodNeedDetail/BloodNeedDetailTabs";
import BloodNeedInformation from "../../components/bloodNeedDetail/BloodNeedInformation";
import Loading from "../../components/common/Loading";
import Topbar from "../../components/Layout/TopBar";
import { getBloodNeedsDetail } from "../../features/bloodNeedsSlice";

const BloodNeedDetail = () => {
  const { bloodNeedId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBloodNeedsDetail(bloodNeedId));
  }, [dispatch, bloodNeedId]);
  const bloodNeedInfo = useSelector((state) => state.bloodNeed.bloodNeedDetail);

  const loading = useSelector((state) => state.bloodNeed.loading);
  const today = moment.utc();
  const dob = moment
    .utc(
      bloodNeedInfo?.data?.attributes?.requestedBy?.data?.attributes
        ?.donorProfile?.data?.attributes?.dob?.dob
    )
    .format("YYYY-MM-DD");

  const age = today.diff(dob, "years");
  return (
    <div>
      <Topbar topic="Blood Need Detail" />
      {loading ? (
        <Loading />
      ) : (
        <BloodNeedInformation
          info={bloodNeedInfo}
          age={age}
          detailTabs={<BloodNeedDetailTab info={bloodNeedInfo} />}
        />
      )}
    </div>
  );
};

export default BloodNeedDetail;
