import { Button, Form, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import { useDispatch } from "react-redux";
import { sendErrorMsg } from "../../features/IDVerificationSlice";
import css from "../../styles/addModal.module.scss";

const IssueModal = ({ setIsModalOpen, id, userId, message }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const onSubmit = (data) => {
    // TODO: sending only data which needs to be filtered
    dispatch(sendErrorMsg({ message: data.message, id, userId }));
    form.resetFields();
    setIsModalOpen(false);
  };

  form.setFieldsValue({
    message: message,
  });

  return (
    <div className={css.Container}>
      <Row className={css.title}>Error Message</Row>

      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item
          name={"message"}
          rules={[
            {
              required: true,
              message: "Message is required",
            },
          ]}
        >
          <TextArea placeholder="Write error message..." />
        </Form.Item>

        <Row justify={"end"} style={{ gap: "1rem" }}>
          <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default IssueModal;
