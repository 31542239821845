import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Spin,
  Upload,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import TopBar from "../../components/Layout/TopBar";
import { getBloodGroup } from "../../features/bloodGroupSlice";
import {
  getBloodNeedsDetail,
  updateBloodNeeds,
} from "../../features/bloodNeedsSlice";
import css from "../../styles/addModal.module.scss";
import addCamera from "../../Images/addCamera.svg";
import TextArea from "antd/es/input/TextArea";
import { deleteFile } from "../../features/deleteFileSlice";
import Loading from "../../components/common/Loading";
import { Card, Image, List } from "antd/es";
import { RiDeleteBin6Line } from "react-icons/ri";
import ShowFile from "../../components/showFile/ShowFile";
const Option = { Select };

const Edit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // form.setFieldsValue({});

  useEffect(() => {
    dispatch(getBloodNeedsDetail(id));
    dispatch(getBloodGroup());
  }, [dispatch, id]);

  // TODO: get data from store to show for editing
  let bloodNeedData = useSelector((state) => state.bloodNeed.bloodNeedDetail);
  let loading = useSelector((state) => state.bloodNeed.loading);
  let bloodGroup = useSelector((state) => state.bloodGroup.bloodGroup);

  const [images, setImages] = useState([]);

  const onFinish = (data) => {
    const actualData = {
      ...data,
      requestedBloodGroup: data.requestedBloodGroup,
    };

    const formData = new FormData();
    if (images?.length > 0) {
      for (let i = 0; i < images.length; i++) {
        formData.append(
          "files.attachments",
          images[i]?.file,
          images[i]?.file?.name
        );
      }
    }

    formData.append("data", JSON.stringify(actualData));
    dispatch(updateBloodNeeds({ formData, id }));
    setImages([]);
    navigate(-1);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    form.setFieldsValue({
      patientName: bloodNeedData?.data?.attributes?.patientName,
      patientAge: bloodNeedData?.data?.attributes?.patientAge,
      patientPhone: bloodNeedData?.data?.attributes?.patientPhone,
      patientGender: bloodNeedData?.data?.attributes?.patientGender,
      bloodType: bloodNeedData?.data?.attributes?.bloodType,
      urgency: bloodNeedData?.data?.attributes?.urgency,
      hospital: bloodNeedData?.data?.attributes?.hospital,
      noOfUnits: bloodNeedData?.data?.attributes?.noOfUnits,
      caseDescription: bloodNeedData?.data?.attributes?.caseDescription,
      needBefore: moment
        .utc(bloodNeedData?.data?.attributes?.needBefore)
        .format("YYYY-MM-DD"),
      requestedBloodGroup:
        bloodNeedData?.data?.attributes?.requestedBloodGroup?.data?.id,
    });
  }, [id]);

  const fileList = Array.from(
    { length: bloodNeedData?.data?.attributes?.attachments?.data?.length },
    (_, index) => ({
      uid: String(
        bloodNeedData?.data?.attributes?.attachments?.data?.[index]?.id
      ),
      url: bloodNeedData?.data?.attributes?.attachments?.data?.[index]
        ?.attributes?.url,
    })
  );
  return (
    <div>
      <TopBar topic="Edit Blood Need" />
      {loading ? (
        <Loading />
      ) : (
        <div className={css.editContent}>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Row gutter={10}>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="Patient Name" name="patientName">
                  <Input className={css.input} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="Patient Age" name="patientAge">
                  <Input className={css.input} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="Blood Type" name="bloodType">
                  <Select
                    options={[
                      {
                        label: "Plasma",
                        value: "Pasma",
                      },
                      {
                        label: "Whole Blood",
                        value: "Whole Blood",
                      },
                      {
                        label: "Power Blood",
                        value: "Power Blood",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="Blood Group" name="requestedBloodGroup">
                  <Select>
                    {bloodGroup?.data?.map((b, i) => {
                      return (
                        <Option key={i} value={b?.id}>
                          {b?.attributes?.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="Phone" name="patientPhone">
                  <Input className={css.input} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="Gender" name="patientGender">
                  <Select
                    options={[
                      {
                        value: "Male",
                        label: "Male",
                      },
                      {
                        value: "Female",
                        label: "Female",
                      },
                      {
                        value: "Others",
                        label: "Others",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="Units Needed" name="noOfUnits">
                  <Input className={css.input} type="number" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="Urgency" name="urgency">
                  <Select
                    options={[
                      {
                        label: "Urgent",
                        value: "Urgent",
                      },
                      {
                        label: "Medium",
                        value: "Medium",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} xl={8}>
                <Form.Item label="Hospital" name="hospital">
                  <Input className={css.input} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="Need Before" name="needBefore">
                  <Input className={css.input} type="date" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="Case Description" name="caseDescription">
                  <TextArea className={css.input} />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Row style={{ marginRight: "8px" }}>
                {fileList?.length !== 0 && (
                  <>
                    {fileList?.map((file) => {
                      return (
                        <ShowFile
                          file={file}
                          id={id}
                          from={"getBloodNeedsDetail"}
                          dis={getBloodNeedsDetail}
                        />
                      );
                    })}
                  </>
                )}
              </Row>
              <Col>
                <Upload
                  multiple={true}
                  listType="picture-card"
                  accept=".jpeg, .png"
                  beforeUpload={(file) => {
                    setImages([...images, { file }]);
                    return false;
                  }}
                  onRemove={(file) => {
                    images.splice(
                      images.findIndex((x) => x.file.uid === file.uid),
                      1
                    );
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ display: "flex", gap: "0.5rem" }}>
                      <div className={css.uploadImgDiv}>
                        <img src={addCamera} alt="addCamera" />
                      </div>
                      <span className={css.uploadBtn}>Attachment Upload</span>
                    </div>
                    <span className={css.uploadText}>Upload Picture</span>
                    <span className={css.uploadText}>
                      Only in jpg and png format.
                    </span>
                  </div>
                </Upload>
              </Col>
            </Row>
            <Divider />
            <Row justify={"end"} style={{ gap: "1rem" }}>
              <Button
                type="primary"
                danger
                htmlType="reset"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button htmlType="submit" type="primary" loading={loading}>
                Save Changes
              </Button>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
};

export default Edit;
