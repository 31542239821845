import { Button, Form, Modal, Row, Space, Typography } from "antd";
import React, { useState } from "react";
import css from "../../styles/Users/users.module.scss";
import NoData from "../common/NoData";
import IssueModal from "../Layout/IssueModal";
import VerifyConfirmation from "../Layout/VerifyConfirmarion";
import AttachmentTab from "./AttachmentTab";

const IdProfileTab = ({ info, userId, phoneVerified, referBy }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [verifyModal, setVerifyModal] = useState(false);
  const [form] = Form.useForm();
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  return (
    <>
      {info?.IdType ? (
        <Space direction="vertical" size={"large"}>
          <Space size={"large"}>
            <Typography.Title level={5} style={{ margin: "0" }}>
              ID type:
            </Typography.Title>
            <Typography.Text>{info?.IdType}</Typography.Text>
          </Space>
          <Space direction="horizontal" size={"large"}>
            <Space direction="vertical">
              <Typography.Title level={5} style={{ margin: "0" }}>
                Back Photo
              </Typography.Title>
              <AttachmentTab
                attachments={[
                  {
                    attributes: {
                      attachment: {
                        data: { attributes: { url: info?.IdBackImage?.url } },
                      },
                    },
                  },
                ]}
              />
            </Space>
            <Space direction="vertical">
              <Typography.Title level={5} style={{ margin: "0" }}>
                Front Photo
              </Typography.Title>

              <AttachmentTab
                attachments={[
                  {
                    attributes: {
                      attachment: {
                        data: { attributes: { url: info?.IdFrontImage?.url } },
                      },
                    },
                  },
                ]}
              />
            </Space>
          </Space>

          <Row justify={"start"} style={{ gap: "1rem" }}>
            <Button
              disabled={info?.status === "Verified"}
              type="primary"
              danger
              onClick={() => setIsModalOpen(true)}
            >
              Submit Issue
            </Button>
            <Button
              disabled={info?.status === "Verified"}
              htmlType="submit"
              type="primary"
              onClick={() => setVerifyModal(true)}
            >
              {info?.status === "Verified" ? "Verified" : "Verify"}
            </Button>
          </Row>

          {/* TODO: modal */}

          <Modal
            title={"Verify Confirmation"}
            open={verifyModal}
            footer={null}
            centered={true}
            maskClosable={false}
            keyboard={false}
            onCancel={() => setVerifyModal(false)}
          >
            <VerifyConfirmation
              phoneVerified={phoneVerified}
              setVerifyModal={setVerifyModal}
              id={info?.id}
              userId={userId}
              body="Your ID has been verified."
              referBy={referBy}
            />
          </Modal>

          <Modal
            maskClosable={false}
            open={isModalOpen}
            footer={null}
            className={css.modalSignup}
            centered={true}
            keyboard={true}
            closable={true}
            onCancel={handleCancel}
          >
            <IssueModal
              setIsModalOpen={setIsModalOpen}
              id={info?.id}
              userId={userId}
              message={info?.verificationNotes?.message}
            />
          </Modal>
        </Space>
      ) : (
        <NoData message={"No Id verification documents"} />
      )}
    </>
  );
};

export default IdProfileTab;
