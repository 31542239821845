import { Avatar, Button, Col, Dropdown, Form, Popover, Row, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FilterSearch from "../../components/common/FilterSearch";
import FilterEvent from "../../components/Layout/FilterEvent";
import TopBar from "../../components/Layout/TopBar";
import SendEmail from "../../components/SendEmail/SendEmail";
import { getEvents } from "../../features/eventSlice";
import css from "../../styles/Users/users.module.scss";

const Event = () => {
  const [emailBox, setEmailBox] = useState(false);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 8,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState();
  useEffect(() => {
    dispatch(getEvents({ params, filter: filteredData }));
  }, [dispatch, params]);

  const [form] = Form.useForm();

  //row selection
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.deactivate,
    }),
  };

  const [hasSelected, setHasSelected] = useState(false);
  useEffect(() => {
    setHasSelected(selectedRowKeys.length > 0);
  }, [selectedRowKeys?.length]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [params]);

  const [search, setSearch] = useState("");
  const [filterModal, setFilterModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  let eventData = useSelector((state) => state.event.events);
  const loadingUser = useSelector((state) => state.event.loading);

  const handleCancel = () => {
    form.resetFields();
    setEditModal(false);
    setIsModalOpen(false);
    setFilterModal(false);
  };

  const [edit, setEdit] = useState({
    key: 0,
  });

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const fields = ["name", "organizer", "location"];

  let dataSource = eventData?.data?.map((f, i) => ({
    key: f?.attributes,
    id: f?.id,
    avatarUrl: f?.attributes?.featureImage?.data?.attributes?.url,
    name: f?.attributes?.name,
    organizer: f?.attributes?.organizer?.data?.attributes?.name,
    location: f?.attributes?.mapLocation?.place_name,
    eventDateTime: moment
      .utc(f?.attributes?.eventDateTime)
      .format("YYYY-MM-DD hh:mm:ss A"),
    noOfParticipants: f?.attributes?.noOfParticipants,
    status: f?.attributes?.status,
    eventCreated: moment.utc(f?.attributes?.createdAt).format("YYYY-MM-DD"),
    verifiedAt: moment
      .utc(f?.attributes?.verificationNotes?.verifiedAt)
      .format("YYYY-MM-DD"),
  }));
  if (search) {
    const filteredData = dataSource?.filter((data) => {
      return fields.some((field) =>
        String(data?.[field])?.toLowerCase().includes(search.toLowerCase())
      );
    });
    dataSource = filteredData;
  }
  const columns = [
    {
      title: "Name",
      key: "name",
      render: (row) => {
        return (
          <div
            className={css.profileImage}
            onClick={() => navigate(`${row.id}`)}
          >
            <Avatar src={row?.avatarUrl} />
            <span>{row?.name}</span>
          </div>
        );
      },
    },
    {
      title: "Organizer",
      key: "organizer",
      dataIndex: "organizer",
    },
    { title: "Location", key: "location", dataIndex: "location" },
    {
      title: "Event Date Time",
      key: "eventDateTime",
      dataIndex: "eventDateTime",
    },
    {
      title: "No of Participants",
      key: "noOfParticipants",
      dataIndex: "noOfParticipants",
    },

    {
      title: "Created At",
      key: "eventCreated",
      dataIndex: "eventCreated",
    },

    {
      title: "Verified At",
      key: "verifiedAt",
      dataIndex: "verifiedAt",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (row) => {
        return (
          <>
            {row === "Verified" ? (
              <span className={css.activeDonor}>Verified</span>
            ) : (
              <span className={css.inactiveDonor}>Pending</span>
            )}
          </>
        );
      },
    },
  ];
  const items = [
    {
      label: (
        <Popover
          open={emailBox}
          content={
            <SendEmail
              emails={selectedRowKeys?.map((f) => f?.email)}
              open={setEmailBox}
              setSelectedRowKeys={setSelectedRowKeys}
            />
          }
          showArrow={false}
          placement="topLeft"
          autoAdjustOverflow={true}
          destroyTooltipOnHide={true}
          overlayStyle={{
            width: "30%",
          }}
        >
          <span onClick={() => setEmailBox(true)}>Send Email</span>
        </Popover>
      ),
      key: "1",
    },
  ];
  const menu = {
    items,
  };

  return (
    <div>
      <TopBar topic={"Events"} />
      <div className={css.userContent}>
        <Row
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <Col>
            <Dropdown menu={menu} disabled={!hasSelected}>
              <Button
                loading={loading}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                Actions <BsChevronDown />
              </Button>
            </Dropdown>
          </Col>

          {/* search filter and add new */}
          <FilterSearch
            handleSearch={handleSearch}
            setFilterModal={setFilterModal}
            setIsModalOpen={setIsModalOpen}
            show={false}
            // showFilter={true}
            filterModal={filterModal}
            content={
              <FilterEvent
                setFilterModal={setFilterModal}
                filterModal={filterModal}
                event={true}
                setFilteredData={setFilteredData}
              />
            }
          />
        </Row>
        {/* table here */}
        <Table
          loading={loadingUser}
          rowSelection={rowSelection}
          dataSource={dataSource}
          columns={columns}
          bordered
          pagination={{
            showTitle: false,
            responsive: true,
            prevIcon: <GrLinkPrevious />,
            nextIcon: <GrLinkNext />,
            current: params.page,
            total: eventData?.data?.length,
            pageSize: params.pageSize,
            onChange: (page) => {
              setParams({
                ...params,
                page,
              });
            },
          }}
        />
      </div>
    </div>
  );
};

export default Event;
