import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { api } from "../Api";
import { BASEURL } from "../Url";

const qs = require("qs");

const initialState = {
  loading: false,
  organizers: [],
  singleOrganizer: null,
  organizerFollower: [],
};
export const getAllOrganizations = createAsyncThunk(
  "getAllOrganizations",
  async ({ params, filter }, thunkApi) => {
    const query = qs.stringify({
      filters: {
        status: filter?.status,
      },
      populate: ["events", "avatar"],
    });
    try {
      const res = await api.get(`${BASEURL}/organizer-profiles/?${query}`);
      return res.data;
    } catch (error) {
      console.log(error, "error");
      return thunkApi.rejectWithValue(error.response.data.error.message);
    }
  }
);
export const getSingleOrganization = createAsyncThunk(
  "getSingleOrganization",
  async (id, thunkApi) => {
    try {
      const query = qs.stringify({
        filters: {
          sort: ["updatedAt:desc"],
        },
        populate: [
          "avatar",
          "events",
          "owner",
          "events.featureImage",
          "reviews",
          "reviews.fromUser",
          "reviews.fromUser.avatar",
        ],
      });
      const res = await api.get(`${BASEURL}/organizer-profiles/${id}?${query}`);
      thunkApi.dispatch(getOrganizerFollower(id));
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.error.message);
    }
  }
);
export const getOrganizerFollower = createAsyncThunk(
  "getOrganizerFollower",
  async (id, thunkApi) => {
    try {
      const query = qs.stringify({
        filters: {
          organizer: id,
          // sort: ["updatedAt:desc"],
        },
        populate: ["followedBy.avatar"],
      });
      const res = await api.get(`${BASEURL}/organizer-followers/?${query}`);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.error.message);
    }
  }
);

const organizerSlice = createSlice({
  name: "organizer",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllOrganizations.pending]: (state) => {
      state.loading = true;
    },
    [getAllOrganizations.fulfilled]: (state, action) => {
      state.loading = false;
      state.organizers = action.payload;
    },
    [getAllOrganizations.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    },
    [getSingleOrganization.pending]: (state) => {
      state.loading = true;
    },
    [getSingleOrganization.fulfilled]: (state, action) => {
      state.loading = false;
      state.singleOrganizer = action.payload;
    },
    [getSingleOrganization.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    },
    [getOrganizerFollower.pending]: (state) => {
      state.loading = true;
    },
    [getOrganizerFollower.fulfilled]: (state, action) => {
      state.loading = false;
      state.organizerFollower = action.payload;
    },
    [getOrganizerFollower.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    },
  },
});

export const {} = organizerSlice.actions;

export default organizerSlice.reducer;
