import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";
import React, { useState } from "react";
import { getBloodNeedsDetail } from "../../features/bloodNeedsSlice";
import noProfile from "../../Images/Users/userProfile.png";
import AdminSettings from "../../pages/adminSettings/AdminSettings";
import css from "../../styles/Details/userDetail.module.scss";
import DonorIssueModal from "../Layout/DonorIssueModal";
import VerifyNeed from "../Layout/VerifyNeed";

const BloodNeedInformation = ({ info, age, from, detailTabs }) => {
  const [verifyModal, setVerifyModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  return (
    <div className={css.userDetailContainer}>
      <div className={css.content}>
        <div className={css.imagePart}>
          <Row
            style={{ flexDirection: "column" }}
            align={"middle"}
            gutter={[0, 16]}
          >
            <Col>
              <Image
                src={
                  info?.data?.attributes?.requestedBy?.data?.attributes?.avatar
                    ?.data?.attributes?.url ||
                  info?.data?.attributes?.requestedBy?.data?.attributes
                    ?.avatarUrl ||
                  noProfile
                }
                alt="profileImage"
                className={css.profileImage}
                width={150}
                height={150}
                preview={false}
              />
            </Col>
            <Typography.Title level={3} style={{ marginBottom: "-1rem" }}>
              {info?.data?.attributes?.requestedBy?.data?.attributes?.username}
              (Seeker)
            </Typography.Title>
            <Typography.Text style={{ marginTop: "0" }}>
              {
                info?.data?.attributes?.requestedBy?.data?.attributes?.role
                  ?.data?.attributes?.name
              }
            </Typography.Text>
            <Col>
              <Row gutter={[8]} align={"middle"}>
                <Col>
                  {
                    info?.data?.attributes?.requestedBy?.data?.attributes
                      ?.followers?.data?.length
                  }
                </Col>
                <Col>Followers</Col>
                <Divider
                  type="vertical"
                  style={{ borderWidth: "2px", height: "25px" }}
                />
                <Col>
                  {
                    info?.data?.attributes?.requestedBy?.data?.attributes
                      ?.badges?.data?.length
                  }
                </Col>
                <Col>Badges</Col>
              </Row>
            </Col>
            <Divider />
          </Row>

          <Row>
            <Space direction="vertical" size="middle">
              <Typography.Title level={5}>Basic Information</Typography.Title>
              <Row gutter={[8, 0]}>
                <Col>
                  <Typography.Text
                    style={{
                      color: "#808080",
                      marginBottom: "-0.5rem",
                      display: "block",
                    }}
                  >
                    Age:
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text>
                    {info?.data?.attributes?.requestedBy?.data?.attributes
                      ?.donorProfile
                      ? `${age} years old`
                      : "-"}
                  </Typography.Text>
                </Col>
              </Row>

              <Row gutter={[8, 0]}>
                <Col>
                  <Typography.Text
                    style={{
                      color: "#808080",
                      marginBottom: "-0.5rem",
                      display: "block",
                    }}
                  >
                    Gender:
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text>
                    {info?.data?.attributes?.requestedBy?.data?.attributes
                      ?.donorProfile
                      ? info?.data?.attributes?.requestedBy?.data?.attributes
                          ?.donorProfile?.data?.attributes?.gender
                      : "-"}
                  </Typography.Text>
                </Col>
              </Row>

              <Row gutter={[8, 0]}>
                <Col>
                  <Typography.Text
                    style={{
                      color: "#808080",
                      marginBottom: "-0.5rem",
                      display: "block",
                    }}
                  >
                    Blood Group:
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text>
                    {info?.data?.attributes?.requestedBy?.data?.attributes
                      ?.donorProfile
                      ? info?.data?.attributes?.requestedBy?.data?.attributes
                          ?.donorProfile?.data?.attributes?.bloodGroup?.data
                          ?.attributes?.name
                      : "-"}
                  </Typography.Text>
                </Col>
              </Row>
              <Divider />

              <Row>
                <Space direction="vertical" size="middle">
                  <Typography.Title level={5}>
                    Contact Information
                  </Typography.Title>
                  <Row gutter={[8, 0]}>
                    <Col>
                      <Typography.Text
                        style={{
                          color: "#808080",
                          marginBottom: "-0.5rem",
                          display: "block",
                        }}
                      >
                        Email Address:
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text>
                        {
                          info?.data?.attributes?.requestedBy?.data?.attributes
                            ?.email
                        }
                      </Typography.Text>
                    </Col>
                  </Row>

                  <Row gutter={[8, 0]}>
                    <Col>
                      <Typography.Text
                        style={{
                          color: "#808080",
                          marginBottom: "-0.5rem",
                          display: "block",
                        }}
                      >
                        Phone:
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text>
                        {
                          info?.data?.attributes?.requestedBy?.data?.attributes
                            ?.phone
                        }
                      </Typography.Text>
                    </Col>
                  </Row>

                  <Row gutter={[8, 0]}>
                    <Col>
                      <Typography.Text
                        style={{
                          color: "#808080",
                          marginBottom: "-0.5rem",
                          display: "block",
                        }}
                      >
                        Address:
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text>
                        {info?.data?.attributes?.requestedBy?.data?.attributes
                          ?.currentAddress
                          ? info?.data?.attributes?.requestedBy?.data
                              ?.attributes?.currentAddress?.city
                          : "-"}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Space>
              </Row>
              <Divider />

              {/* TODO: verify blood need */}
              <Space direction="vertical" size={"large"}>
                <Typography.Title level={5} style={{ margin: "0" }}>
                  Verify Need:
                </Typography.Title>
                {/* <Typography.Text>{info?.IdType}</Typography.Text> */}
              </Space>

              <Row justify={"start"} style={{ gap: "1rem", marginTop: "20px" }}>
                <Button
                  disabled={info?.data?.attributes?.verified === true}
                  type="primary"
                  danger
                  onClick={() => setIsModalOpen(true)}
                >
                  Submit Issue
                </Button>
                <Button
                  disabled={info?.data?.attributes?.verified === true}
                  htmlType="submit"
                  type="primary"
                  onClick={() => setVerifyModal(true)}
                >
                  {info?.data?.attributes?.verified === true
                    ? "Verified"
                    : "Verify"}
                </Button>
              </Row>

              <Modal
                title={"Verify Confirmation"}
                open={verifyModal}
                footer={null}
                centered={true}
                maskClosable={false}
                keyboard={false}
                onCancel={() => setVerifyModal(false)}
              >
                <VerifyNeed
                  setVerifyModal={setVerifyModal}
                  path="blood-needs"
                  id={info?.data?.id}
                  // reloadall={getUsersById}
                  userId={info?.data?.attributes?.requestedBy?.data?.id}
                  body="Congratulations, your user's phone number has been verified."
                  screen="EventDetail"
                  // paramId={userInfo?.id}
                  allUserInfo={info}
                />
              </Modal>

              <Modal
                maskClosable={false}
                open={isModalOpen}
                footer={null}
                className={css.modalSignup}
                centered={true}
                keyboard={true}
                closable={true}
                onCancel={handleCancel}
              >
                <DonorIssueModal
                  setIsModalOpen={setIsModalOpen}
                  Id={info?.data?.id}
                  path="blood-requests"
                  message={info?.data?.attributes?.metaData?.message}
                  getDetail={getBloodNeedsDetail}
                  userId={info?.data?.attributes?.requestedBy?.data?.id}
                  body="Kindly revise your blood need and reapply for consideration."
                  screen="PrivateNeedDetail"
                  paramId={info?.data?.id}
                  customKey="metaData"
                />
              </Modal>
            </Space>
          </Row>
        </div>

        <div className={css.descriptionPart}>
          {from === "admin" ? <AdminSettings /> : detailTabs}
        </div>
      </div>
    </div>
  );
};

export default BloodNeedInformation;
