import { Table } from "antd";
import { Avatar, Space } from "antd/es";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBadgeWinners } from "../../features/badgeSlice";

const BadgeWinner = ({ id }) => {
  const navigate = useNavigate();
  const [params, setParams] = useState({
    page: 1,
    pageSize: 8,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBadgeWinners({ id, params }));
  }, [params]);

  let info = useSelector((state) => state.badge.badgeWinners);
  let loading = useSelector((state) => state.badge.winnerLoading);

  const dataSource = info?.data?.map((f, i) => ({
    id: f?.id,
    name: f?.attributes?.user?.data?.attributes?.username,
    email: f?.attributes?.user?.data?.attributes?.email,
    phone: f?.attributes?.user?.data?.attributes?.phone || "-",
    createdAt: moment.utc(f?.attributes.createdAt).format("YYYY-MM-DD"),
    imgUrl:
      f?.attributes?.user?.data?.attributes?.avatar?.data?.attributes?.url,
  }));

  const columns = [
    {
      title: "Name",
      key: "name",
      render: (row) => {
        return (
          <Space size="middle">
            <Avatar src={row?.imgUrl} size="large" />
            {row?.name}
          </Space>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    // {
    //   title: "Active",
    //   key: "active",
    //   dataIndex: "active",
    // },
    {
      title: "Achieved at",
      dataIndex: "createdAt",
      key: "createdAt",
    },
  ];

  return (
    <div>
      <Table
        loading={loading}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={{
          responsive: true,
          prevIcon: <GrLinkPrevious />,
          nextIcon: <GrLinkNext />,
          total: info?.meta?.pagination?.total,
          onChange: (page) => {
            setParams((prev) => ({
              ...prev,
              page,
            }));
          },
        }}
        onRow={(row) => {
          return {
            onClick: () => {
              navigate(`/dashboard/users/${row?.id}`);
            },
          };
        }}
      />
    </div>
  );
};

export default BadgeWinner;
