import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { api } from "../Api";
import profile from "../Images/Users/userProfile.svg";
import { BASEURL } from "../Url";
const qs = require("qs");

const initialState = {
  history: [],
  loading: false,
  singleHistory: [],
};

export const getHistory = createAsyncThunk(
  "history/getHistory",
  async ({ params, filter }) => {
    const query = qs.stringify({
      pagination: params,
      populate: ["acceptors.acceptor.avatar", "requestedBy"],
    });
    const res = await api.get(
      `${BASEURL}/blood-requests/?filters[isFulFilled][$eq]=true&${query}`
    );
    return res.data;
  }
);

export const getSingleHistory = createAsyncThunk(
  "history/getSingleHistory",
  async (id) => {
    const query = qs.stringify({
      populate: [
        "acceptors.acceptor.avatar",
        "requestedBy.donorProfile",
        "requestedBy.avatar",
        "requestedBy.currentAddress",
        "requestedBloodGroup",
      ],
      filter: {
        status: "Donated",
      },
    });
    const res = await api.get(`${BASEURL}/blood-requests/${id}?${query}`);
    return res.data;
  }
);

const historySlice = createSlice({
  name: "history",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getHistory.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getHistory.fulfilled, (state, action) => {
      state.history = action.payload;
      state.loading = false;
    });
    builder.addCase(getHistory.rejected, (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.error.message,
      });
    });

    // TODO: each history
    builder.addCase(getSingleHistory.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSingleHistory.fulfilled, (state, action) => {
      state.singleHistory = action.payload;
      state.loading = false;
    });
    builder.addCase(getSingleHistory.rejected, (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.error.message,
      });
    });
  },
});

export default historySlice.reducer;
