import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../Api";
import { BASEURL } from "../Url";

const initialState = {
  loading: false,
  error: "",
};

export const sendEmail = createAsyncThunk("sendEmail", async (data) => {
  api.post(`${BASEURL}/email/sendMultiple`, data);
});

const emailSlice = createSlice({
  name: "email",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(sendEmail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendEmail.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(sendEmail.rejected, (state) => {
      state.loading = false;
    });
  },
});


export default emailSlice.reducer