import React, { useState } from "react";
import css from "../../styles/Categories/categories.module.scss";
import TopBar from "./TopBar";
import { BsChevronRight } from "react-icons/bs";
import add from "../../Images/categories/add.svg";
import deleteIcon from "../../Images/categories/delete.svg";
import edit from "../../Images/categories/edit.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import AddCategory from "./AddCategory";

const Categories = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();
  const categoryData = useSelector((state) => state.category[props.prevPath]);
  const categoryList = categoryData?.map((item, index) => {
    return (
      <div key={index} className={css.listDiv}>
        <div className={css.inputDiv}>
          <input type="checkbox" name={item} id={item} />
          <label htmlFor={item} className={css.label}>
            {item}
          </label>
        </div>
        <div className={css.deleteEdit}>
          <div className={css.div}>
            <img src={deleteIcon} alt="deleteIcon" />
            <span>Delete</span>
          </div>
          <div className={css.div}>
            <img src={edit} alt="edit" />
            <span>Edit</span>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div>
      {props.topic === "Partner Categories" && (
        <TopBar
          topic="Partners"
          category={false}
          searchBar={true}
          searchPlaceholder="Search"
          addUser={true}
        />
      )}
      {props.topic === "Blog Categories" && (
        <TopBar
          topic="Blogs"
          category={false}
          searchBar={true}
          searchPlaceholder="Search"
          addDonor={true}
        />
      )}
      {props.topic === "FAQ Categories" && (
        <TopBar
          topic="FAQ"
          category={false}
          searchBar={true}
          searchPlaceholder="Search"
          addUser={true}
        />
      )}
      <div className={css.categoryContainer}>
        <div className={css.path}>
          <span className={css.prevPath} onClick={() => navigate(-1)}>
            {props.prevPath}
          </span>
          <span>
            <BsChevronRight />
          </span>
          <span className={css.currPath}>Category</span>
        </div>
        <div className={css.heading}>
          <span className={css.headingText}>{props.topic}</span>
          <button className={css.addBtn} onClick={() => setIsModalOpen(true)}>
            <img src={add} alt="addIcon" />
            <span>Add</span>
          </button>
        </div>
        <div>{categoryList}</div>
      </div>
      {/* modal */}
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        // footer={null}
        className={css.modalSignup}
        centered={true}
        keyboard={true}
        closable={false}
      >
        <AddCategory />
      </Modal>
    </div>
  );
};

export default Categories;
