import React from "react";
import css from "../../styles/DonorList/donorList.module.scss";

const BloodNeedCard = (props) => {
  return (
    <div className={css.eachCard}>
      <div className={css.imgContainer}>
        <img src={props.icon} className={css.icon} alt="icon" />
      </div>
      <div className={css.cardNumber}>
        <span className={css.value}>{props.value}</span>
        <span className={css.title}>{props.title}</span>
      </div>
    </div>
  );
};

export default BloodNeedCard;
