import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { api } from "../Api";
import { BASEURL } from "../Url";

const qs = require("qs");
const initialState = {
  feedback: [],
  loading: false,
};

export const getFeedback = createAsyncThunk("getFeedback", async ({params, filters}, thunkApi) => {
  const query = qs.stringify({
    paginattion: params,
    filters:filters
  });
  try {
    const res = await api.get(`${BASEURL}/contact-uses?${query}`);
    return res.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data.error.message)
  }
});

const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getFeedback.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getFeedback.fulfilled, (state, action) => {
      state.loading = false;
      state.feedback = action.payload;
    });
    builder.addCase(getFeedback.rejected, (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.error.message,
        confirmButtonColor: "#B00020",
      });
    });
  },
});

export default feedbackSlice.reducer;
