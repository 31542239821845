import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../Api";
import { BASEURL } from "../Url";

const initialState = {
  bloodGroup: [],
  loading: false,
  error: "",
};

// TODO: get BloodGroup
const qs = require("qs");

export const getBloodGroup = createAsyncThunk("getBloodGroup", async () => {
  // const query = qs.stringify({
  //   pagination: params,
  //   sort: ["urgency:desc", "updatedAt:desc"],
  // });
  const res = await api.get(`${BASEURL}/blood-groups`);
  return res.data;
});

// TODO: post BloodGroup
export const postBloodGroup = createAsyncThunk(
  "postBloodGroup",
  async (data, thunkApi) => {
    const res = await api.post(`${BASEURL}/blood-groups`, data);
    thunkApi.dispatch(getBloodGroup());
    return res.data;
  }
);

// TODO: update BloodGroup

export const updateBloodGroup = createAsyncThunk(
  "updateBloodGroup",
  async ({ formData, id }, thunkApi) => {
    const res = await api.put(`${BASEURL}/blood-groups/${id}`, formData);
    thunkApi.dispatch(getBloodGroup());
    return res.data;
  }
);

// TODO: delete BloodGroup

export const deleteBloodGroup = createAsyncThunk(
  "deleteBloodGroup",
  async (id, thunkApi) => {
    const res = await api.delete(`${BASEURL}/blood-groups/${id}`);
    thunkApi.dispatch(getBloodGroup());
    return res.data;
  }
);

const bloodGroupSlice = createSlice({
  name: "bloodGroup",
  initialState,
  reducers: {},
  extraReducers: {
    // TODO: get
    [getBloodGroup.pending]: (state, action) => {
      state.loading = true;
    },
    [getBloodGroup.fulfilled]: (state, action) => {
      state.loading = false;
      state.bloodGroup = action.payload;
    },
    [getBloodGroup.rejected]: (state, action) => {
      state.loading = false;
      alert(action.error.message);
    },

    // TODO: post
    [postBloodGroup.pending]: (state, action) => {
      state.loading = true;
    },
    [postBloodGroup.fulfilled]: (state, action) => {
      state.loading = false;
      alert("fulfilled");
    },
    [postBloodGroup.rejected]: (state, action) => {
      state.loading = false;
      alert(action.error.message);
    },

    // TODO: Update
    [updateBloodGroup.pending]: (state, action) => {
      state.loading = true;
    },
    [updateBloodGroup.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateBloodGroup.rejected]: (state, action) => {
      state.loading = false;
      alert(action.error.message);
    },

    // TODO: Delete
    [deleteBloodGroup.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteBloodGroup.fulfilled]: (state, action) => {
      state.loading = false;
      alert("fulfilled");
    },
    [deleteBloodGroup.rejected]: (state, action) => {
      state.loading = false;
      alert(action.error.message);
    },
  },
});

export default bloodGroupSlice.reducer;
