import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TopBar from "../../components/Layout/TopBar";
import { useEffect } from "react";
import moment from "moment";
import Loading from "../../components/common/Loading";
import UserInformation from "../../components/userDetail/UserInformation";
import { getSingleEvent } from "../../features/eventSlice";
import EventInformation from "./EventInformation";

const EventDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSingleEvent(id));
  }, [dispatch, id]);

  const eventInfo = useSelector((state) => state.event.singleEvent);
  const loading = useSelector((state) => state.event.loading);
  const organizerFollowers = useSelector((state) => state.organizer.organizerFollower);


  return (
    <div>
      <TopBar topic="Event Detail" />
      {loading ? (
        <Loading />
      ) : (
        <EventInformation
          eventInfo={eventInfo}
          id={id}
          orgFollowers={organizerFollowers}
          isloading={loading}
        />
      )}
    </div>
  );
};

export default EventDetail;
