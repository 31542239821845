import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { api } from "../Api";
import { BASEURL } from "../Url";
import { deleteTestimonials } from "./testimonialSlice";
const qs = require("qs");

const initialState = {
  banner: [],
  loading: false,
  error: "",
};

// TODO: get Banners

export const getBanners = createAsyncThunk(
  "getBanners",
  async ({ params, filter }) => {
    const query = qs.stringify({
      pagination: params,
      filters: {
        title: { $eqi: filter?.title },
      },
    });
    const res = await api.get(`${BASEURL}/banners?populate=*&${query}`);
    return res.data;
  }
);

// TODO: post Banners
export const postBanners = createAsyncThunk(
  "postBanners",
  async (data, thunkApi) => {
    const res = await api.post(`${BASEURL}/banners`, data);
    thunkApi.dispatch(
      getBanners({
        params: {
          page: 1,
          pageSize: 8,
        },
      })
    );
    return res.data;
  }
);

// TODO: update Banners

export const updateBanners = createAsyncThunk(
  "updateBanners",
  async ({ formData, id }, thunkApi) => {
    const res = await api.put(`${BASEURL}/banners/${id}`, formData);
    thunkApi.dispatch(
      getBanners({
        params: {
          page: 1,
          pageSize: 8,
        },
      })
    );
    return res.data;
  }
);

// TODO: delete Banners

export const deleteBanners = createAsyncThunk(
  "deleteBanners",
  async (id, thunkApi) => {
    const res = await api.delete(`${BASEURL}/banners/${id}`);
    thunkApi.dispatch(
      getBanners({
        params: {
          page: 1,
          pageSize: 8,
        },
      })
    );
    return res.data;
  }
);

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {},
  extraReducers: {
    // TODO: get
    [getBanners.pending]: (state, action) => {
      state.loading = true;
    },
    [getBanners.fulfilled]: (state, action) => {
      state.loading = false;
      state.banner = action.payload;
    },
    [getBanners.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: post
    [postBanners.pending]: (state, action) => {
      state.loading = true;
    },
    [postBanners.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Banner Added",
        confirmButtonColor: "#4BB543",
        text: "Banner is successfully added",
      });
    },
    [postBanners.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: Update
    [updateBanners.pending]: (state, action) => {
      state.loading = true;
    },
    [updateBanners.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Banner Updated",
        confirmButtonColor: "#4BB543",
        text: "Banner is successfully updated",
      });
    },
    [updateBanners.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: Delete
    [deleteBanners.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteBanners.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Banner Deleted",
        confirmButtonColor: "#4BB543",
        text: "Banner is successfully deleted",
      });
    },
    [deleteBanners.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },
  },
});

export default bannerSlice.reducer;
