import { Col, Form, Row, Button, Input, Checkbox, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BiArrowBack } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getRoles } from "../../features/roleSlice";
import { postUsers, updateUsers } from "../../features/userSlice";
import css from "../../styles/addModal.module.scss";

const EditUser = (props) => {
  const dispatch = useDispatch();
  const [formInstance] = Form.useForm();
  props.form.resetFields = formInstance.resetFields;

  // TODO: to setValues after each cancel or OK click
  const [cancelOrOk, setCancelOrOk] = useState(false);

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  const roleData = useSelector((state) => state.role.roles);
  const userData = useSelector((state) => state.users.users);

  const [imgSrc, setImgSrc] = useState("");

  const onSubmit = (data) => {
    // const formData = new FormData();

    // formData.append("files.image", imgSrc, imgSrc.name); // for image

    // formData.append("data", JSON.stringify(data));
    props.setEditModal(false);
    dispatch(updateUsers({ data, id: props?.id, from:props.from }));
    setImgSrc("");
  };

  const handleCancel = () => {
    setImgSrc("");
    props.setEditModal(false);
  };

  // TODO: to set the old values
  const editData = userData?.filter((item) => item?.id == props.id);

  formInstance.setFieldsValue({
    username: editData[0]?.username,
    email: editData[0]?.email,
    confirmed: editData[0]?.confirmed,
    blocked: editData[0]?.blocked,
    role: editData[0]?.role?.id,
  });

  return (
    <div className={css.Container}>
      <Row className={css.title}>Edit User</Row>
      <Form layout="vertical" form={formInstance} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              label="Full Name"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Name is required",
                },
              ]}
            >
              <Input className={css.input} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email is required",
                },
              ]}
            >
              <Input className={css.input} type="email" />
            </Form.Item>
          </Col>

          {/* <Col span={12}>
            <Form.Item
              label="Confirmed"
              name="confirmed"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Blocked" name="blocked" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col> */}

          <Col span={12}>
            <Form.Item label="Role" name="role">
              <Select>
                {roleData?.roles?.map((role) => {
                  return (
                    <Select.Option value={role?.id} key={role?.id}>
                      {role?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row justify={"end"} style={{ gap: "1rem" }}>
          <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary">
            Save Changes
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default EditUser;
