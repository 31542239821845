import { Image, Table } from "antd";
import React from "react";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useSelector } from "react-redux";

const BadgeTab = ({ info }) => {
  const dataSource = info?.map((f, i) => ({
    id: f?.id,
    title: f?.attributes?.badge?.data?.attributes?.name || f?.badge?.name,
    description:
      f?.attributes?.badge?.data?.attributes?.description ||
      f?.badge?.description,
    level:
      f?.attributes?.badge?.data?.attributes?.level || f?.badge?.level || 0,
    image:
      f?.attributes?.badge?.data?.attributes?.image?.data?.attributes?.url ||
      f?.badge?.image?.url,
    rewardPoints:
      f?.attributes?.badge?.data?.attributes?.bonusRewards ||
      f?.badge?.bonusRewards,
  }));


  const columns = [
    {
      title: "Image",
      key: "image",
      render: (row) => {
        return (
          <Image
            src={row?.image}
            style={{ objectFit: "cover", width: "5rem", height: "5rem" }}
          />
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },

    {
      title: "Reward Points",
      dataIndex: "rewardPoints",
      key: "rewardPoints",
    },

    {
      title: "Level",
      dataIndex: "level",
      key: "level",
    },
  ];
  return (
    <div>
      <Table
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={{
          responsive: true,
          prevIcon: <GrLinkPrevious />,
          nextIcon: <GrLinkNext />,
          total: info?.data?.length,
          pageSize: 5,
        }}
      />
    </div>
  );
};

export default BadgeTab;
