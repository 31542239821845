import { Button, Col, Form, Input, Row, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import { useForm } from "react-hook-form";
import { BiArrowBack } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { addFaq, getFaq } from "../../features/faqSlice";
import css from "../../styles/addModal.module.scss";

const AddFaq = (props) => {
  const dispatch = useDispatch(); //for dispatching the actions in faqSlice
  const [formInstance] = Form.useForm();
  props.form.resetFields = formInstance.resetFields;

  const onSubmit = (data) => {
    dispatch(addFaq(data));
    formInstance.resetFields();
    props.setIsModalOpen(false);
  };

  const handleCancel = () => {
    formInstance.resetFields();
    props.setIsModalOpen(false);
  };
  return (
    <div className={css.Container}>
      <Row className={css.title}>Add FAQ</Row>

      <Form layout="vertical" form={formInstance} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              label="Question"
              name="question"
              rules={[
                {
                  required: true,
                  message: "Question is required",
                },
              ]}
            >
              <Input className={css.input} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Category"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Category is required",
                },
              ]}
            >
              <Select
                options={[
                  {
                    label: "donor",
                    value: "donor",
                  },
                  {
                    label: "partner",
                    value: "partner",
                  },
                  {
                    label: "suggestion",
                    value: "suggestion",
                  },
                  {
                    label: "volunteering",
                    value: "volunteering",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Answer"
              name="answer"
              rules={[
                {
                  required: true,
                  message: "Answer is required",
                },
              ]}
            >
              <TextArea className={css.input} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"end"} style={{ gap: "1rem" }}>
          <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary">
            Add
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default AddFaq;
