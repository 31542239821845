import { Avatar, Badge, Col, Dropdown, Image, Row, Space, Switch } from "antd";
import React, { useEffect, useState } from "react";
import css from "../../styles/TopBar/topbar.module.scss";
import addUser from "../../Images/Topbar/addUser.svg";
import addDonor from "../../Images/Topbar/addDonor.svg";
import { Link, useNavigate } from "react-router-dom";
import { RiNotification4Line, RiUserSettingsLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { BiLogOutCircle } from "react-icons/bi";
import noProfile from "../../Images/Users/userProfile.png";
import {
  getNotifications,
  readNotification,
} from "../../features/notificationSlice";
import moment from "moment";

const TopBar = (props) => {
  const navigate = useNavigate();
  //for settings showing
  const [settingShow, setSettingShow] = useState(false);
  const profileIcon = localStorage.getItem("avatarUrl");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotifications());

    // Set up a timer to fetch notifications every minute
    const intervalId = setInterval(() => {
      dispatch(getNotifications());
    }, 60000); // 60000 milliseconds = 1 minute

    // Clean up the timer when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  const notifications = useSelector((state) => state.notification);
  const userMe = useSelector((state) => state.users.self);

  const settings = [
    {
      label: (
        <span
          className={css.settingTopic}
          style={{ display: "block", marginBottom: "1rem" }}
        >
          settings
        </span>
      ),
    },
    {
      label: (
        <Row
          gutter={16}
          align={"middle"}
          style={{ cursor: "pointer", marginBottom: "0.5rem" }}
        >
          <Col>
            <RiUserSettingsLine className={css.settingIcons} />
          </Col>
          <Col>
            <span
              className={css.settingOption}
              onClick={() => navigate("/dashboard/admin-profile")}
            >
              Admin Settings
            </span>
          </Col>
        </Row>
      ),
      key: "0",
    },

    {
      label: (
        <Row
          gutter={16}
          align={"middle"}
          style={{ cursor: "pointer", marginBottom: "0.5rem" }}
          onClick={() => {
            localStorage.removeItem("token");
            localStorage.removeItem("name");
            localStorage.removeItem("role");
            localStorage.removeItem("avatarUrl");
            navigate("/");
          }}
        >
          <Col>
            <BiLogOutCircle className={css.settingIcons} />
          </Col>
          <Col>
            <span className={css.settingOption}>Log Out</span>
          </Col>
        </Row>
      ),
      key: "2",
    },
  ];

  const newNotification = Array.from(
    { length: notifications?.New?.length },
    (_, i) => ({
      label: (
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
            padding: "0.5rem",
            backgroundColor: notifications?.New?.[i]?.attributes?.isRead
              ? "white"
              : "#FFEEED",
          }}
          onClick={() => {
            dispatch(readNotification(notifications?.New?.[i]?.id));
            navigate(notifications?.New?.[i]?.attributes?.metaData?.route);
          }}
        >
          {/* <Avatar src="" /> */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p>
              <span style={{ fontWeight: "bold" }}>
                {notifications?.New[i]?.attributes?.metaData?.name}
              </span>{" "}
              {notifications?.New[i]?.attributes?.metaData?.description}
            </p>
            <p style={{ marginTop: "-1rem" }}>
              {moment
                .utc(notifications?.New[i]?.attributes?.createdAt)
                .fromNow()}
            </p>
          </div>
        </div>
      ),
      key: notifications?.New?.[i]?.id,
    })
  );
  const oldNotifications = Array.from(
    { length: notifications.Earlier.length },
    (_, i) => ({
      label: (
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
            padding: "0.5rem",
            backgroundColor: notifications?.Earlier?.[i]?.attributes?.isRead
              ? "white"
              : "#FFEEED",
          }}
          onClick={() => {
            dispatch(readNotification(notifications?.Earlier?.[i]?.id));
            navigate(notifications?.Earlier?.[i]?.attributes?.metaData?.route);
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p>
              <span style={{ fontWeight: "bold" }}>
                {notifications?.Earlier[i]?.attributes?.metaData?.name}
              </span>{" "}
              {notifications?.Earlier[i]?.attributes?.metaData?.description}
            </p>
            <p style={{ marginTop: "-1rem" }}>
              {moment
                .utc(notifications?.Earlier[i]?.attributes?.createdAt)
                .fromNow()}
            </p>
          </div>
        </div>
      ),
      key: notifications.Earlier[i].id,
    })
  );

  let allNotifications = [];
  allNotifications = allNotifications.concat(
    {
      label: (
        <Row gutter={10}>
          <Badge
            count={
              notifications?.New?.filter(
                (item) => item?.attributes?.isRead === false
              )?.length
            }
            offset={[15, 10]}
          >
            <h6>New</h6>
          </Badge>
        </Row>
      ),
    },
    newNotification,
    { type: "divider" },
    {
      label: (
        <Row gutter={10}>
          <Badge
            count={
              notifications?.Earlier?.filter(
                (item) => item?.attributes?.isRead === false
              )?.length
            }
            offset={[15, 10]}
          >
            <h6>Earlier</h6>
          </Badge>
        </Row>
      ),
    },
    oldNotifications
  );

  return (
    <div className={css.topBarContainer}>
      <div className={css.topicIcon}>
        <h5>{props.topic}</h5>
      </div>

      <Row className={css.profileSide} align="center">
        {props.addUser && (
          <Col>
            <img
              src={addUser}
              alt="adduserIcon"
              style={{ marginRight: "-1rem", cursor: "pointer" }}
              onClick={() => props.setIsModalOpen(true)}
            />
          </Col>
        )}
        {props.addDonor && (
          <Col>
            <img
              src={addDonor}
              alt="addDonorIcon"
              style={{ marginRight: "-1rem", cursor: "pointer" }}
              onClick={() => props.setIsModalOpen(true)}
            />
          </Col>
        )}

        {/* TODO: notification */}
        <Col>
          <Dropdown
            trigger={["click"]}
            menu={{
              items: allNotifications,
            }}
            placement="bottomCenter"
            overlayStyle={{
              width: "400px",
              height: "80vh",
              overflow: "auto",
              boxShadow:
                "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
              borderRadius: "20px",
            }}
          >
            <Badge
              count={
                notifications?.New?.filter(
                  (item) => item?.attributes?.isRead === false
                )?.length +
                notifications?.Earlier?.filter(
                  (item) => item?.attributes?.isRead === false
                )?.length
              }
            >
              <RiNotification4Line className={css.notificationIcon} />
            </Badge>
          </Dropdown>
        </Col>

        <Col style={{ cursor: "pointer", position: "relative" }}>
          <Dropdown
            trigger={["click"]}
            menu={{
              items: settings,
              style: { padding: "1rem" },
            }}
            placement="bottomLeft"
            style={{ border: "2px solid red" }}
            overlayStyle={{
              width: "350px",
            }}
          >
            <Row gutter={[32, 32]} onClick={() => setSettingShow(!settingShow)}>
              <Col>
                {profileIcon !== "undefined" ? (
                  <Image
                    preview={false}
                    src={profileIcon}
                    alt="profile"
                    width={45}
                    height={45}
                    style={{ borderRadius: "50%", objectFit: "cover" }}
                  />
                ) : (
                  <Image
                    preview={false}
                    src={noProfile}
                    alt="profile"
                    width={45}
                    height={45}
                    style={{ borderRadius: "50%", objectFit: "cover" }}
                  />
                )}
              </Col>
              <Col>
                <Row className={css.name}>
                  {localStorage.getItem("name") || userMe?.username}
                </Row>
                <Row className={css.post}>
                  {localStorage.getItem("role") || userMe?.role?.type}
                </Row>
              </Col>
            </Row>
          </Dropdown>
        </Col>
      </Row>
    </div>
  );
};

export default TopBar;
