// import React, { memo } from "react";
// import css from "../../styles/HomePage/dbCard.module.scss";
// import { Column } from "@ant-design/plots"; // Correct import for Column

// const DbCard = (props) => {
//   const donorData = [
//     {
//       action: "Blogs",
//       count: 12,
//     },
//     {
//       action: "Stories",
//       count: 12,
//     },
//     {
//       action: "Pages",
//       count: 12,
//     },
//   ];

//   const config = {
//     data: props.data || 0,
//     // data:props.data,
//     columnWidthRatio: 0.4,
//     xField: "action",
//     yField: "count",
//     width: 280,
//     height: 165,
//     xAxis: true,
//     // forceFit: true,
//     columnStyle: {
//       fill: "#cc2c49",
//       color: "white",
//       cursor: "pointer",
//     },
//   };
//   return (
//     <div className={css.cardContainer} style={{ background: props.bg }}>
//       <div className={css.graph}>
//         <span className={css.title}>{props.title}</span>
//         <div style={{ color: "white" }}>
//           <Column {...config} />
//         </div>
//       </div>
//       <span className={css.value}>{props.value}</span>
//     </div>
//     // </div>
//   );
// };

// export default memo(DbCard);

import React, { memo } from "react";
import css from "../../styles/HomePage/dbCard.module.scss";
import ReactApexChart from "react-apexcharts";

const DbCard = (props) => {
  // const chartOptions = {
  //   chart: {
  //     type: "bar",
  //     width: 280,
  //     height: 165,
  //   },
  //   xaxis: {
  //     // categories: props.data.map((item) => item.action),
  //   },
  //   options: {
  //     chart: {
  //       height: 350,
  //       type: "bar",
  //       events: {
  //         click: function (chart, w, e) {
  //           // console.log(chart, w, e)
  //         },
  //       },
  //     },
  //     colors: colors,
  //     plotOptions: {
  //       bar: {
  //         columnWidth: "45%",
  //         distributed: true,
  //       },
  //     },
  //   },
  // };

  const chartOptions = {
    series: props?.series || [],
    options: {
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      // colors: colors,
      plotOptions: {
        bar: {
          columnWidth: "70%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: true,
      },
      xaxis: props?.xaxis || [],
    },
  };

  return (
    <div className={css.cardContainer} style={{ background: props.bg }}>
      <div className={css.graph}>
        <span className={css.title}>{props.title}</span>
        <div>
          <ReactApexChart
            options={chartOptions.options}
            series={chartOptions.series}
            type="bar"
            // height={300}
          />
        </div>
      </div>
      <span className={css.value}>{props.value}</span>
    </div>
  );
};

export default memo(DbCard);
