import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { api } from "../Api";
import { BASEURL } from "../Url";

const qs = require("qs"); //for sending parameter

const initialState = {
  loading: false,
  faqs: [],
  errors: "",
};

// TODO: add faq

export const addFaq = createAsyncThunk("addFaq", async (payload, thunkApi) => {
  const res = await api.post(`${BASEURL}/faqs`, { data: payload });
  thunkApi.dispatch(
    getFaq({
      params: {
        page: 1,
        pageSize: 8,
      },
    })
  );
  return res.data;
});

// TODO: edit faq
export const editFaq = createAsyncThunk(
  "editFaq",
  async ({ data, id }, thunkApi) => {
    const res = await api.put(`${BASEURL}/faqs/${id}`, {
      data: data,
    });
    thunkApi.dispatch(
      getFaq({
        params: {
          page: 1,
          pageSize: 8,
        },
      })
    );
    return res.data;
  }
);

// TODO: get Faq
export const getFaq = createAsyncThunk("getFaq", async ({ params, filter }) => {
  // TODO: send parameters to the request using query string library
  const query = qs.stringify({
    pagination: params,
    filters: {
      question: { $eqi: filter?.question },
      answer: { $eqi: filter?.answer },
      type: { $eqi: filter?.type },
    },
    sort: ["updatedAt:desc"],
  });
  const res = await api.get(`${BASEURL}/faqs?${query}`);
  return res.data;
});

// TODO: delete Faq
export const deleteFaq = createAsyncThunk("deleteFaq", async (id, thunkApi) => {
  const res = await api.delete(`${BASEURL}/faqs/${id}`);
  thunkApi.dispatch(
    getFaq({
      params: {
        page: 1,
        pageSize: 8,
      },
    })
  );
  return res.data;
});

// TODO: faq slice
const faqSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {},
  extraReducers: {
    // TODO: for addFaq
    [addFaq.pending]: (state, action) => {
      state.loading = true;
    },
    [addFaq.fulfilled]: (state, action) => {
      state.loading = false;
      // state.faqs.data.push(action.payload.data);
      // state.faqs.meta.pagination.total += 1;

      Swal.fire({
        icon: "success",
        title: "FAQ Added",
        confirmButtonColor: "#4BB543",
        text: "FAQ is successfully added",
      });
    },
    [addFaq.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: for getFaq
    [getFaq.pending]: (state, action) => {
      state.loading = true;
    },
    [getFaq.fulfilled]: (state, action) => {
      state.loading = false;
      state.faqs = action.payload;
    },
    [getFaq.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: for edit faq
    [editFaq.pending]: (state, action) => {
      state.loading = true;
    },
    [editFaq.fulfilled]: (state, action) => {
      state.loading = false;
      // state.faqs.data.forEach((item) => {
      //   if (item.id === action.payload.data.id) {
      //     item.attributes.question = action.payload.data.attributes.question;
      //     item.attributes.answer = action.payload.data.attributes.answer;
      //   }
      // });
      Swal.fire({
        icon: "success",
        title: "FAQ Updated",
        confirmButtonColor: "#4BB543",
        text: "FAQ is successfully updated",
      });
    },
    [editFaq.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: for delete faq
    [deleteFaq.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteFaq.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "delete",
        title: "FAQ Deleted",
        confirmButtonColor: "#4BB543",
        text: "FAQ is successfully deleted",
      });
      // const index = state.faqs.data.findIndex(
      //   (obj) => obj.id === action.payload.data.id
      // );
      // state.faqs.data.splice(index, 1);
    },
    [deleteFaq.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },
  },
});

export default faqSlice.reducer;
