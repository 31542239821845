import { Form, Modal, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BiAddToQueue } from "react-icons/bi";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import SendNotification from "../../components/Add/SendNotification";
import FilterSearch from "../../components/common/FilterSearch";
import Filter from "../../components/Layout/FilterNotification";
import TopBar from "../../components/Layout/TopBar";
import { getNotification } from "../../features/appSettingSlice";
import css from "../../styles/Admin/settings.module.scss";
import { limit } from "../../utils/truncatingString";

const NotificationSettings = () => {
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState();

  const [params, setParams] = useState({
    page: 1,
    pageSize: 8,
  });

  useEffect(() => {
    dispatch(getNotification({ params, filter: filteredData }));
  }, [dispatch, params]);
  const notifications = useSelector(
    (state) => state.appSetting.notificationsetting
  );
  const isloading = useSelector((state) => state.appSetting.loading);
  const fields = ["title", "description", "sendTo", "isRead"];

  const [filterModal, setFilterModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
    setFilterModal(false);
  };

  let dataSource = notifications?.data?.map((f, i) => ({
    key: f?.id,
    title: limit(f?.attributes?.title, 50),
    isRead: f?.attributes?.isRead,
    description: f?.attributes?.description,
    sendTo: f?.attributes?.toUser?.data?.attributes?.username,
    sendFrom: f?.attributes?.fromUser?.data?.attributes?.username,
    date: moment.utc(f?.attributes?.createdAt).format("YYYY-MM-DD"),
  }));
  const [search, setSearch] = useState("");

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  if (search) {
    const filteredData = dataSource?.filter((data) => {
      return fields.some((field) =>
        String(data?.[field])?.toLowerCase().includes(search.toLowerCase())
      );
    });
    dataSource = filteredData;
  }
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "40%",
    },

    {
      title: "Sender",
      dataIndex: "sendFrom",
      key: "sendFrom",
    },
    {
      title: "Receiver",
      dataIndex: "sendTo",
      key: "sendTo",
    },

    {
      title: "Is Read",
      key: "isRead",
      render: (row) => {
        return (
          <>
            {row?.isRead ? (
              <span className={css.activeDonor}>Read</span>
            ) : (
              <span className={css.inactiveDonor}>Unread</span>
            )}
          </>
        );
      },
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
  ];

  return (
    <>
      <TopBar topic="Notifications" />

      <div className={css.container}>
        {/* search filter and add new */}
        <div className={css.searchFilterNew}>
          <FilterSearch
            handleSearch={handleSearch}
            setFilterModal={setFilterModal}
            setIsModalOpen={setIsModalOpen}
            title="Send Notification"
            show={true}
            filterModal={filterModal}
            content={
              <Filter
                setFilterModal={setFilterModal}
                filterModal={filterModal}
                setFilteredData={setFilteredData}
              />
            }
            icon={<BiAddToQueue className={css.filterIcon} />}
          />
        </div>
        <Table
          scroll={{
            x: 1500,
          }}
          loading={isloading}
          dataSource={dataSource}
          columns={columns}
          bordered
          pagination={{
            showTitle: false,
            responsive: true,
            prevIcon: <GrLinkPrevious />,
            nextIcon: <GrLinkNext />,
            current: params.page,
            total: notifications?.meta?.pagination?.total,
            pageSize: params.pageSize,
            onChange: (page) => {
              setParams({
                ...params,
                page,
              });
            },
          }}
        />
      </div>

      <Modal
        maskClosable={false}
        open={isModalOpen}
        footer={null}
        className={css.modalSignup}
        centered={true}
        keyboard={false}
        onCancel={handleCancel}
      >
        <SendNotification setIsModalOpen={setIsModalOpen} form={form} />
      </Modal>
    </>
  );
};

export default NotificationSettings;
