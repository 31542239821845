import {
  Button,
  Col,
  Dropdown,
  Modal,
  Row,
  Switch,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { BsChevronDown, BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "../../components/Layout/TopBar";
import css from "../../styles/Feedback/feedback.module.scss";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import addDonor from "../../Images/Topbar/addDonor.svg";
import Filter from "../../components/Layout/FilterReview";
import {
  deleteFeedback,
  deleteReview,
  getFeedback,
  getReview,
} from "../../features/reviewSlice";
import moment from "moment";
import { limit } from "../../utils/truncatingString";
import editIcon from "../../Images/Users/editIcon.svg";
import { BiAddToQueue, BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import starIcon from "../../Images/Testimonials/Star.svg";
import EditDots from "../../components/common/EditDots";
import DeleteConfirmation from "../../components/Layout/DeleteConfirmation";
import AddFeedback from "../../components/Add/AddFeedback";
import FilterSearch from "../../components/common/FilterSearch";
import { useNavigate } from "react-router-dom";

const Reviews = () => {
  //  TODO: fetch feedback data
  const [filteredData, setFilteredData] = useState();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // TODO: get faq data when render
  const [params, setParams] = useState({
    page: 1,
    pageSize: 8,
  });
  useEffect(() => {
    dispatch(getReview({ params, filter: filteredData }));
  }, [dispatch, params]);

  const [edit, setEdit] = useState({
    key: 0,
  });

  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterModal, setFilterModal] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
    setFilterModal(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setFilterModal(false);
  };
  const [showDesc, setShowDesc] = useState({});

  // filter Modal

  // bring user data from store
  let reviewData = useSelector((state) => state.review.Review);

  let isLoading = useSelector((state) => state.review.loading);

  // handle search
  const [search, setSearch] = useState("");

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const fields = [
    "username",
    "comments",
    "comments",
    "rating",
    "feedbackFor",
    "to",
  ];

  console.log(reviewData, "ersdfs");

  let dataSource = reviewData?.data?.map((f, i) => ({
    id: f?.id,
    fromId: f?.attributes?.fromUser?.data?.id,
    toId: f?.attributes?.toUser?.data?.id,
    username: f?.attributes?.fromUser?.data?.attributes?.username,
    to: f?.attributes?.toUser?.data?.attributes?.username,
    comments: f?.attributes?.comments,
    comments: limit(f?.attributes?.comments, 50),
    feedbackFor: f?.attributes?.feedBackFor,
    date: moment.utc(f?.attributes?.createdAt).format("YYYY-MM-DD"),
    rating: f?.attributes?.ratingCount,
  }));

  if (search) {
    const filteredData = dataSource?.filter((data) => {
      return fields.some((field) =>
        String(data?.[field])?.toLowerCase().includes(search.toLowerCase())
      );
    });
    dataSource = filteredData;
  }

  // data columns
  const columns = [
    {
      title: "From User",
      // dataIndex: "username",
      render: (row) => {
        return (
          <p onClick={() => navigate(`/dashboard/users/${row?.fromId}`)}>
            {row?.username}
          </p>
        );
      },
      key: "username",
    },

    {
      title: "To User",
      // dataIndex: "to",
      render: (row) => {
        return (
          <p onClick={() => navigate(`/dashboard/users/${row?.toId}`)}>
            {row?.to}
          </p>
        );
      },
      key: "to",
    },

    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
    },

    // {
    //   title: "Feedback",
    //   dataIndex: "comments",
    //   key: "comments",
    //   width: "20%",
    // },

    {
      title: "Rating",
      render: (row) => {
        return new Array(parseInt(row?.rating)).fill(" ").map((item, index) => {
          return <img key={index} src={starIcon} alt="star" />;
        });
      },
    },

    {
      title: "Feedback For",
      dataIndex: "feedbackFor",
      key: "feedbackFor",
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    // {
    //   title: "Action",
    //   render: (row) => {
    //     return (
    //       <EditDots
    //         icon={editIcon}
    //         setEdit={setEdit}
    //         row={row}
    //         edit={edit}
    //         setEditModal={setEditModal}
    //         setDeleteModal={setDeleteModal}
    //       />
    //     );
    //   },
    // },
  ];

  //row selection
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSelectChange = (newSelectedRowKeys) => {
    // console.log("newSelectedRowKeys", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  // console.log("selectedRowKeys changed: ", selectedRowKeys);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const [hasSelected, setHasSelected] = useState(false);
  useEffect(() => {
    setHasSelected(selectedRowKeys.length > 0);
  }, [selectedRowKeys?.length]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [params]);

  const start = (key) => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      alert(key);
      setLoading(false);
    }, 1000);
  };

  // dropdown functions
  function handleMenuClick(e) {
    // message.info("Click on menu item.");
    // console.log("click", e);
    start(e.key);
  }

  /* dropdown action */
  const items = [
    {
      label: "Send Email",
      key: "1",
    },
    {
      label: "Delete",
      key: "2",
    },
  ];
  const menu = {
    items,
    onClick: handleMenuClick,
  };
  return (
    <div>
      <TopBar topic="Reviews" />
      <div className={css.feedbackContent}>
        <Row
          style={{
            // justifyContent: "space-between",
            justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          {/* <Col>
            <Dropdown menu={menu} disabled={!hasSelected}>
              <Button
                loading={loading}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                Actions <BsChevronDown />
              </Button>
            </Dropdown>
          </Col> */}

          {/* search filter and add new */}
          <FilterSearch
            handleSearch={handleSearch}
            setFilterModal={setFilterModal}
            setIsModalOpen={setIsModalOpen}
            title="Add Feedback"
            showFilter={true}
            filterModal={filterModal}
            content={
              <Filter
                setFilterModal={setFilterModal}
                filterModal={filterModal}
                setFilteredData={setFilteredData}
              />
            }
            icon={<BiAddToQueue className={css.filterIcon} />}
          />
        </Row>
        {/* table here */}

        <Table
          scroll={{
            x: 1500,
          }}
          loading={isLoading}
          // rowSelection={rowSelection}
          dataSource={dataSource}
          columns={columns}
          bordered
          pagination={{
            showTitle: false,
            responsive: true,
            showSizeChanger: false,
            prevIcon: <GrLinkPrevious />,
            nextIcon: <GrLinkNext />,
            defaultCurrent: 1,
            // current: params.page,
            total: reviewData?.meta?.pagination?.total,
            pageSize: params.pageSize,
            onChange: (page) => {
              setParams({
                ...params,
                page,
              });
            },
          }}
        />

        <Modal
          open={isModalOpen}
          footer={null}
          className={css.modalSignup}
          centered={true}
          keyboard={true}
          onCancel={handleCancel}
          maskClosable={false}
        >
          <AddFeedback setIsModalOpen={setIsModalOpen} />
        </Modal>
        {/* <Modal
          open={filterModal}
          footer={null}
          className={css.modalSignup}
          centered={true}
          keyboard={true}
          onCancel={() => setFilterModal(false)}
        >
          <Filter setFilterModal={setFilterModal} />
        </Modal> */}

        <Modal
          open={editModal}
          footer={null}
          className={css.modalSignup}
          centered={true}
          keyboard={true}
          onCancel={() => setEditModal(false)}
        >
          {/* <EditFee setEditModal={setEditModal} id={edit?.key} /> */}
        </Modal>

        <Modal
          title={"Delete Confirmation"}
          open={deleteModal}
          footer={null}
          centered={true}
          keyboard={true}
          onCancel={() => setDeleteModal(false)}
        >
          <DeleteConfirmation
            setDeleteModal={setDeleteModal}
            id={edit?.key}
            delete={deleteReview}
            get={getReview}
          />
        </Modal>
      </div>
    </div>
  );
};

export default Reviews;
