import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { api } from "../Api";
import { BASEURL } from "../Url";

const initialState = {
  Review: [],
  loading: false,
  error: "",
  reports: [],
  realBloodNeed: 0,
};

// TODO: get Review
const qs = require("qs");

export const getReview = createAsyncThunk(
  "getReview",
  async ({ params, filter }) => {
    const query = qs.stringify({
      pagination: params,
      filters: {
        fromUser: {
          username: {
            $eqi: filter?.username,
          },
        },
        toUser: {
          username: {
            $eqi: filter?.to,
          },
        },
      },
    });
    const res = await api.get(`${BASEURL}/user-feed-backs?populate=*&${query}`);
    return res.data;
  }
);

export const realBloodNeed = createAsyncThunk("realBloodNeed", async () => {
  const query = qs.stringify({
    pagination: {
      limit: 1,
    },
    filters: {
      isRealBloodNeedPost: true,
    },
  });
  const res = await api.get(`${BASEURL}/user-feed-backs?${query}`);
  return res.data;
});

export const getReports = createAsyncThunk("reports", async () => {
  const query = qs.stringify({
    pagination: {
      limit: 1,
    },
    populate: ["*", "reports"],
  });
  const res = await api.get(`${BASEURL}/reports?${query}`);
  return res.data;
});
// TODO: post Review
export const postReview = createAsyncThunk(
  "postReview",
  async (data, thunkApi) => {
    const res = await api.post(`${BASEURL}/user-feed-backs`, data);
    thunkApi.dispatch(
      getReview({
        page: 1,
        pageSize: 8,
      })
    );
    return res.data;
  }
);

// TODO: update Review

export const updateReview = createAsyncThunk(
  "updateReview",
  async ({ formData, id }, thunkApi) => {
    const res = await api.put(`${BASEURL}/user-feed-backs/${id}`, formData);
    thunkApi.dispatch(
      getReview({
        page: 1,
        pageSize: 8,
      })
    );
    return res.data;
  }
);

// TODO: delete Review

export const deleteReview = createAsyncThunk(
  "deleteReview",
  async (id, thunkApi) => {
    const res = await api.delete(`${BASEURL}/user-feed-backs/${id}`);
    thunkApi.dispatch(
      getReview({
        page: 1,
        pageSize: 8,
      })
    );
    return res.data;
  }
);

const ReviewSlice = createSlice({
  name: "Review",
  initialState,
  extraReducers: {
    // TODO: get
    [getReview.pending]: (state, action) => {
      state.loading = true;
    },
    [getReview.fulfilled]: (state, action) => {
      state.loading = false;
      state.Review = action.payload;
    },
    [getReview.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },
    [getReports.pending]: (state, action) => {
      state.loading = true;
    },
    [getReports.fulfilled]: (state, action) => {
      state.loading = false;
      state.reports = action.payload;
    },
    [getReports.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: real bloodNeedPost
    [realBloodNeed.pending]: (state, action) => {},
    [realBloodNeed.fulfilled]: (state, action) => {
      state.realBloodNeed = action.payload.meta.pagination.total;
    },
    [realBloodNeed.rejected]: (state, action) => {},

    // TODO: post
    [postReview.pending]: (state, action) => {
      state.loading = true;
    },
    [postReview.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Feedback Added",
        confirmButtonColor: "#4BB543",
        text: "Feedback is successfully added",
      });
    },
    [postReview.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: Update
    [updateReview.pending]: (state, action) => {
      state.loading = true;
    },
    [updateReview.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Review Updated",
        confirmButtonColor: "#4BB543",
        text: "Review is successfully updated",
      });
    },
    [updateReview.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: Delete
    [deleteReview.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteReview.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Review Deleted",
        confirmButtonColor: "#4BB543",
        text: "Review is successfully deleted",
      });
    },
    [deleteReview.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },
  },
});

export default ReviewSlice.reducer;
