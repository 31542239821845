export const getBadgeInfo = (badges, badgeName)=>{

    const transformedBadges = badges.reduce((result, badge) => {
        const attributes = badge.attributes;

        const uniqueId = attributes.badgeUniqueId || null;
        const id = badge.id;
        const rewardPoint = attributes.bonusRewards || null;
        const reason = attributes.description || null;

        result[uniqueId] = { id, rewardPoint, reason };
        return result;
    }, {});

    // console.log(transformedBadges[badgeName])
    return transformedBadges[badgeName]
}