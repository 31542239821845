import { Button, Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getPartners } from "../../features/partnerSlice";
import css from "../../styles/addModal.module.scss";

const Filter = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onSubmit = (data) => {
    // TODO: sending only data which needs to be filtered
    const filterData = {};
    for (let index = 0; index < Object.keys(data).length; index++) {
      if (data[Object.keys(data)[index]] !== undefined) {
        filterData[Object.keys(data)[index]] = data[Object.keys(data)[index]];
      }
    }
    props.setFilteredData(filterData)

    dispatch(
      getPartners({
        params: {
          page: 1,
          pageSize: 8,
        },
        filter: filterData,
      })
    );

    props.setFilterModal(false);
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    props.setFilterModal(false);
  };

  return (
    <div className={css.Container}>
      <Row className={css.title}>Filter</Row>
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Row gutter={[32, 0]} justify="space-between">
          {/* <Col span={12}>
            <Form.Item label="Organization Name" name={"name"}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Phone" name={"phone"}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Email" name={"email"}>
              <Input type="email" />
            </Form.Item>
          </Col> */}

          <Col span={12}>
            <Form.Item label="Category" name={"type"}>
              <Select
                options={[
                  {
                    label: "Business Partners",
                    value: "business",
                  },
                  {
                    label: "Technical Partners",
                    value: "technical",
                  },
                  {
                    label: "Health Partners",
                    value: "health",
                  },
                  {
                    label: "Social Partners",
                    value: "social",
                  },
                  {
                    label: "Media Partners",
                    value: "media",
                  },
                ]}
              />
            </Form.Item>
          </Col>

          {/* <Col span={12}>
            <Form.Item label="Organization Location" name={"location"}>
              <Input />
            </Form.Item>
          </Col> */}
        </Row>

        <Row justify={"end"} style={{ gap: "1rem" }}>
          <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary">
            Filter
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default Filter;
