import { Divider, Space, Typography } from "antd";
import moment from "moment";
import React from "react";

const BasicBloodNeedInfo = ({ info }) => {
  return (
    <>
      <Space direction="vertical">
        <Space size={"large"}>
          <Typography.Title level={5} style={{ margin: "0" }}>
            Case Description:
          </Typography.Title>
          <Typography.Text>
            {info?.data?.attributes?.caseDescription}
          </Typography.Text>
        </Space>
        <Space size={"large"}>
          <Typography.Title level={5} style={{ margin: "0" }}>
            Blood Type:
          </Typography.Title>
          <Typography.Text>{info?.data?.attributes?.bloodType}</Typography.Text>
        </Space>
        <Space size={"large"}>
          <Typography.Title level={5} style={{ margin: "0" }}>
            Blood Group:
          </Typography.Title>
          <Typography.Text>
            {
              info?.data?.attributes?.requestedBloodGroup?.data?.attributes
                ?.name
            }
          </Typography.Text>
        </Space>
        <Space size={"large"}>
          <Typography.Title level={5} style={{ margin: "0" }}>
            No. of units required:
          </Typography.Title>
          <Typography.Text>
            {info?.data?.attributes?.noOfUnits} units
          </Typography.Text>
        </Space>
        <Space size={"large"}>
          <Typography.Title level={5} style={{ margin: "0" }}>
            Requested date:
          </Typography.Title>
          <Typography.Text>
            {moment.utc(info?.data?.attributes?.createdAt).format("YYYY-MM-DD")}
          </Typography.Text>
        </Space>

        <Space size={"large"}>
          <Typography.Title level={5} style={{ margin: "0" }}>
            Urgency:
          </Typography.Title>
          <Typography.Text>{info?.data?.attributes?.urgency}</Typography.Text>
        </Space>
        <Space size={"large"}>
          <Typography.Title level={5} style={{ margin: "0" }}>
            Fulfilled:
          </Typography.Title>
          <Typography.Text>
            {info?.data?.attributes?.isFulFilled ? "YES" : "NO"}
          </Typography.Text>
        </Space>

        <Space size={"large"}>
          <Typography.Title level={5} style={{ margin: "0" }}>
            For Self:
          </Typography.Title>
          <Typography.Text>
            {info?.data?.attributes?.isForSelf ? "YES" : "NO"}
          </Typography.Text>
        </Space>

        <Space size={"large"}>
          <Typography.Title level={5} style={{ margin: "0" }}>
            Hospital:
          </Typography.Title>
          <Typography.Text>{info?.data?.attributes?.hospital}</Typography.Text>
        </Space>

        <Space size={"large"}>
          <Typography.Title level={5} style={{ margin: "0" }}>
            Need before:
          </Typography.Title>
          <Typography.Text>
            {moment
              .utc(info?.data?.attributes?.needBefore)
              .format("YYYY-MM-DD hh:mm A")}
          </Typography.Text>
        </Space>
      </Space>
      <Divider />

      <Typography.Title level={4}>Patient's information</Typography.Title>
      <Space direction="vertical">
        <Space size={"large"}>
          <Typography.Title level={5} style={{ margin: "0" }}>
            Name:
          </Typography.Title>
          <Typography.Text>
            {info?.data?.attributes?.patientName}
          </Typography.Text>
        </Space>
        <Space size={"large"}>
          <Typography.Title level={5} style={{ margin: "0" }}>
            Age:
          </Typography.Title>
          <Typography.Text>
            {info?.data?.attributes?.patientAge} years old
          </Typography.Text>
        </Space>
        <Space size={"large"}>
          <Typography.Title level={5} style={{ margin: "0" }}>
            Gender:
          </Typography.Title>
          <Typography.Text>
            {info?.data?.attributes?.patientGender}
          </Typography.Text>
        </Space>
        <Space size={"large"}>
          <Typography.Title level={5} style={{ margin: "0" }}>
            Phone:
          </Typography.Title>
          <Typography.Text>
            {info?.data?.attributes?.patientPhone}
          </Typography.Text>
        </Space>
      </Space>
    </>
  );
};

export default BasicBloodNeedInfo;
