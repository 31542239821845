import {
  Button,
  Col,
  Divider,
  Image,
  Modal,
  Row,
  Space,
  Tabs,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import AdminSettings from "../../pages/adminSettings/AdminSettings";
import css from "../../styles/Details/userDetail.module.scss";
import EventInfo from "../../components/Event/EventInfo";
import { GoVerified } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import VerifyDonor from "../../components/Layout/VerifyDonor";
import { getEvents, getSingleEvent } from "../../features/eventSlice";
import DonorIssueModal from "../../components/Layout/DonorIssueModal";

const EventInformation = ({ eventInfo, from, id, orgFollowers, isloading }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [verifyModal, setVerifyModal] = useState(false);
  const navigate = useNavigate();

  return (
    <div className={css.userDetailContainer}>
      <div className={css.content}>
        <div className={css.imagePart}>
          <Row
            style={{ flexDirection: "column" }}
            align={"middle"}
            gutter={[0, 16]}
          >
            <Col>
              <Image
                src={eventInfo?.attributes?.featureImage?.data?.attributes?.url}
                alt="profileImage"
                className={css.profileImage}
                width={150}
                height={150}
                preview={false}
              />
            </Col>
            <Typography.Title
              level={3}
              style={{ marginBottom: "-1rem", textAlign: "center" }}
            >
              {eventInfo?.attributes?.name}{" "}
              {eventInfo?.attributes?.status === "Verified" && (
                <GoVerified style={{ color: "#296DE5", fontSize: "1.02rem" }} />
              )}
            </Typography.Title>
            <Typography.Title
              level={4}
              style={{ marginBottom: "-1rem", textAlign: "center" }}
              onClick={() =>
                navigate(
                  `/dashboard/admin/organizer/${eventInfo?.attributes?.organizer?.data?.id}`
                )
              }
            >
              {eventInfo?.attributes?.organizer?.data?.attributes?.name}{" "}
              (Organizer)
            </Typography.Title>
            {/* <Col>
              <Row gutter={[8]} align={"middle"}>
                <Col>{eventInfo?.attributes?.followers?.length}</Col>
                <Col>Followers</Col>
                <Divider
                  type="vertical"
                  style={{ borderWidth: "2px", height: "25px" }}
                />
                <Col>{eventInfo?.attributes?.badges?.length}</Col>
                <Col>Badges</Col>
              </Row>
            </Col> */}
            <Divider />
          </Row>

          <Row>
            <Space direction="vertical" size="middle">
              <Typography.Title level={5}>Event Information</Typography.Title>
              <Row gutter={[8, 0]}>
                <Col>
                  <Typography.Text
                    style={{
                      color: "#808080",
                      marginBottom: "-0.5rem",
                      display: "block",
                    }}
                  >
                    Event Date Time:{" "}
                    {moment
                      .utc(`${eventInfo?.attributes?.eventDateTime}`)
                      .format("YYYY-MM-DD hh:mm")}
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text>
                    {/* {eventInfo?.attributes?.donorProfile ? `${age} years old` : "-"} */}
                  </Typography.Text>
                </Col>
              </Row>

              <Row gutter={[8, 0]}>
                <Col>
                  <Typography.Text
                    style={{
                      color: "#808080",
                      marginBottom: "-0.5rem",
                      display: "block",
                    }}
                  >
                    Location: {eventInfo?.attributes?.mapLocation?.place_name}
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text></Typography.Text>
                </Col>
              </Row>

              <Row gutter={[8, 0]}>
                <Col>
                  <Typography.Text
                    style={{
                      color: "#808080",
                      marginBottom: "-0.5rem",
                      display: "block",
                    }}
                  >
                    No of participants:{" "}
                    {eventInfo?.attributes?.noOfParticipants}
                  </Typography.Text>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Space direction="vertical" size={"large"}>
                  <Space size={"large"}>
                    {/* <Typography.Text>{info?.IdType}</Typography.Text> */}
                  </Space>

                  <Row justify={"start"} style={{ gap: "1rem" }}>
                    <Button
                      disabled={eventInfo?.attributes?.status === "Verified"}
                      type="primary"
                      danger
                      onClick={() => setIsModalOpen(true)}
                    >
                      Submit Issue
                    </Button>
                    <Button
                      disabled={eventInfo?.attributes?.status === "Verified"}
                      htmlType="submit"
                      type="primary"
                      onClick={() => setVerifyModal(true)}
                    >
                      {eventInfo?.attributes?.status === "Verified"
                        ? "Verified"
                        : "Verify"}
                    </Button>
                  </Row>

                  {/* TODO: modal */}

                  <Modal
                    title={"Verify Confirmation"}
                    open={verifyModal}
                    footer={null}
                    centered={true}
                    maskClosable={false}
                    keyboard={false}
                    onCancel={() => setVerifyModal(false)}
                  >
                    <VerifyDonor
                      setVerifyModal={setVerifyModal}
                      path="events"
                      id={id}
                      getAll={getSingleEvent}
                      userId={
                        eventInfo?.attributes?.organizer?.data?.attributes
                          ?.owner?.data?.id
                      }
                      body="Your event has been verified."
                      screen="EventDetail"
                      paramId={eventInfo?.id}
                      event={true}
                      orgFollowers={orgFollowers}
                      eventName={eventInfo?.attributes?.name}
                      verificationNotes={
                        eventInfo?.attributes?.verificationNotes
                      }
                      updatedAt={eventInfo?.attributes?.updatedAt}
                      eventJoiners={eventInfo?.attributes?.eventJoiners}
                    />
                  </Modal>
                  <Modal
                    maskClosable={false}
                    open={isModalOpen}
                    footer={null}
                    className={css.modalSignup}
                    centered={true}
                    keyboard={true}
                    closable={true}
                    onCancel={() => setIsModalOpen(false)}
                  >
                    <DonorIssueModal
                      setIsModalOpen={setIsModalOpen}
                      Id={id}
                      path="events"
                      getDetail={getSingleEvent}
                      message={
                        eventInfo?.attributes?.verificationNotes?.message
                      }
                      userId={
                        eventInfo?.attributes?.organizer?.data?.attributes
                          ?.owner?.data?.id
                      }
                      body="Kindly revise your event and reapply for consideration."
                      screen="EventDetail"
                      paramId={eventInfo?.id}
                    />
                  </Modal>
                </Space>
              </Row>
            </Space>
          </Row>
        </div>

        <div className={css.descriptionPart}>
          {from === "admin" ? (
            <AdminSettings />
          ) : (
            <EventInfo
              description={eventInfo?.attributes?.description}
              id={id}
              orgFollowers={orgFollowers}
              isLoading={isloading}
              eventJoiner={eventInfo?.attributes?.eventJoiners}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EventInformation;
