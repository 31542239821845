import { Button, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { api } from "../../Api";
import { changePw } from "../../features/loginSlice";
import css from "../../styles/addModal.module.scss";
import { BASEURL } from "../../Url";

const ChangePwForm = (props) => {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [passwordShown, setPasswordShown] = useState({
    old: false,
    new: false,
    confirm: false,
  });
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    // dispatch(
    //   changePw({
    //     data: {
    //       password: data.NewPassword,
    //       currentPassword: data.oldPassword,
    //       passwordConfirmation: data.ConfirmPassword,
    //     },
    //   })
    // );
    try {
      setLoadingBtn(true);
      await api.post(`${BASEURL}/auth/change-password`, {
        password: data.NewPassword,
        currentPassword: data.oldPassword,
        passwordConfirmation: data.ConfirmPassword,
      });
      setLoadingBtn(false);
      localStorage.removeItem("token");
      localStorage.removeItem("avatarUrl");
      localStorage.removeItem("role");
      localStorage.removeItem("name");
      navigate("/");
    } catch (error) {
      setLoadingBtn(false);
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: error.response.data.error.message,
        confirmButtonColor: "#B00020",
      });
    }
  };
  const handleCancel = () => {
    props.setIsModalVisible(false);
    form.resetFields();
  };

  return (
    <Form form={form} onFinish={onSubmit} layout="vertical">
      <Row>
        <Col span={24}>
          <Form.Item
            label="Enter Old Password"
            name="oldPassword"
            rules={[
              {
                required: true,
                message: "Old password is required",
              },
              {
                min: 6,
                message: "Minimun 6 character is required",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Enter New Password"
            name="NewPassword"
            rules={[
              {
                required: true,
                message: "New password is required",
              },
              {
                min: 6,
                message: "Minimun 6 character is required",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Confirm New Password"
            name="ConfirmPassword"
            rules={[
              {
                required: true,
                message: "Confirm password is required",
              },

              // {
              //   validateTrigger: "onchange",
              //   validator: (rule, value, callback) => {
              //     if (value !== form.getFieldValue("NewPassword")) {
              //       callback("Password does not match");
              //     }
              //   },
              // },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"end"} style={{ gap: "1rem" }}>
        <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
          Cancel
        </Button>
        <Button htmlType="submit" type="primary" loading={loadingBtn}>
          Change
        </Button>
      </Row>
    </Form>
  );
};

export default ChangePwForm;
