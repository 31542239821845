import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { api } from "../Api";
import { BASEURL } from "../Url";
const qs = require("qs");

const initialState = {
  badges: [],
  loading: false,
  error: "",
  singleBadge: [],
  donorReferCount: 0,
  userReferCount: 0,
  badgeWinners: [],
  winnerLoading: false,
};

// TODO: get badges

export const getbadges = createAsyncThunk(
  "getbadges",
  async ({ params, filter }) => {
    const query = qs.stringify({
      pagination: params,
      filters: {
        name: { $eqi: filter?.name },
      },
      sort: ["updatedAt:desc"],
    });
    const res = await api.get(`${BASEURL}/badges?populate=*&${query}`);
    return res.data;
  }
);

// TODO: get badges winners

export const getBadgeWinners = createAsyncThunk(
  "getBadgeWinners",
  async ({ id, params }) => {
    const query = qs.stringify({
      pagination: params,
      filters: {
        badge: id,
      },
      sort: ["updatedAt:desc"],
      populate: ["user.avatar"],
    });
    const res = await api.get(`${BASEURL}/user-badges?${query}`);
    return res.data;
  }
);

// TODO: get badges

export const getbadgesById = createAsyncThunk("getbadgesById", async (id) => {
  const query = qs.stringify({
    populate: ["image", "users.avatar"],
  });
  const res = await api.get(`${BASEURL}/badges/${id}?${query}`);

  return res.data;
});

// TODO: post badges
export const postbadges = createAsyncThunk(
  "postbadges",
  async (data, thunkApi) => {
    try {
      const res = await api.post(`${BASEURL}/badges`, data);
      thunkApi.dispatch(
        getbadges({
          params: {
            page: 1,
            pageSize: 8,
          },
        })
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response);
    }
  }
);

// TODO: update badges

export const updatebadges = createAsyncThunk(
  "updatebadges",
  async ({ formData, id }, thunkApi) => {
    const res = await api.put(`${BASEURL}/badges/${id}`, formData);
    thunkApi.dispatch(
      getbadges({
        params: {
          page: 1,
          pageSize: 8,
        },
      })
    );
    return res.data;
  }
);

// TODO: delete badges

export const deletebadges = createAsyncThunk(
  "deletebadges",
  async (id, thunkApi) => {
    const res = await api.delete(`${BASEURL}/badges/${id}`);
    thunkApi.dispatch(
      getbadges({
        params: {
          page: 1,
          pageSize: 8,
        },
      })
    );
    return res.data;
  }
);

// TODO: delete badges

export const getReferDonorCount = createAsyncThunk(
  "getReferDonorCount",

  async (id, thunkApi) => {
    const query = qs.stringify({
      filters: {
        user: {
          referBy: id,
        },
        status: "Verified",
      },
    });

    try {
      const res = await api.get(`${BASEURL}/donor-profiles?${query}`);
      return res.data?.data?.length;
    } catch (error) {
      console.log(error, "errorerer");
    }
  }
);

// TODO: delete badges

export const getReferUserCount = createAsyncThunk(
  "getReferUserCount",

  async (id, thunkApi) => {
    const query = qs.stringify({
      filters: {
        referBy: id,
        status: "Verified",
      },
    });

    try {
      const res = await api.get(`${BASEURL}/users?${query}`);
      return res.data?.data?.length;
    } catch (error) {
      console.log(error, "errorerer");
    }
  }
);

const badgeSlice = createSlice({
  name: "badges",
  initialState,
  reducers: {},
  extraReducers: {
    // TODO: get
    [getbadges.pending]: (state, action) => {
      state.loading = true;
    },
    [getbadges.fulfilled]: (state, action) => {
      state.loading = false;
      state.badges = action.payload;
    },
    [getbadges.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: get badge winners
    [getBadgeWinners.pending]: (state, action) => {
      state.winnerLoading = true;
    },
    [getBadgeWinners.fulfilled]: (state, action) => {
      state.winnerLoading = false;
      state.badgeWinners = action.payload;
    },
    [getBadgeWinners.rejected]: (state, action) => {
      state.winnerLoading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    [getbadgesById.pending]: (state, action) => {
      state.loading = true;
    },
    [getbadgesById.fulfilled]: (state, action) => {
      state.loading = false;
      state.singleBadge = action.payload;
    },
    [getbadgesById.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: donation refer count

    [getReferDonorCount.fulfilled]: (state, action) => {
      state.loading = false;
      state.donorReferCount = action.payload;
    },

    // TODO: friends refer count

    [getReferUserCount.fulfilled]: (state, action) => {
      state.loading = false;
      state.userReferCount = action.payload;
    },

    // TODO: post
    [postbadges.pending]: (state, action) => {
      state.loading = true;
    },
    [postbadges.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Badge Added",
        confirmButtonColor: "#4BB543",
        text: "Badge is successfully added",
      });
    },
    [postbadges.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: Update
    [updatebadges.pending]: (state, action) => {
      state.loading = true;
    },
    [updatebadges.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Badge Updated",
        confirmButtonColor: "#4BB543",
        text: "Badge is successfully updated",
      });
    },
    [updatebadges.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: Delete
    [deletebadges.pending]: (state, action) => {
      state.loading = true;
    },
    [deletebadges.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Badge Deleted",
        confirmButtonColor: "#4BB543",
        text: "Badge is successfully deleted",
      });
    },
    [deletebadges.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },
  },
});

export default badgeSlice.reducer;
