import { Avatar, Space, Table } from "antd";
import React from "react";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import css from "../../styles/DonorList/donorList.module.scss";
import { useNavigate } from "react-router-dom";

const qs = require("qs");

const FollowingTab = ({ info }) => {
  const navigate = useNavigate();

  const dataSource = info?.map((f, i) => ({
    id: f?.id,
    userId: f?.attributes?.followedTo?.data?.id,
    followerName:
      f?.followedTo?.data?.attributes?.username ||
      f?.attributes?.followedTo?.data?.attributes?.username,
    active:
      f?.followedTo?.data?.attributes?.deactivate ||
      f?.attributes?.followedTo?.data?.attributes?.deactivate,
    blocked:
      f?.followedTo?.data?.attributes?.blocked ||
      f?.attributes?.followedTo?.data?.attributes?.blocked,
    imgUrl:
      f?.followedTo?.data?.attributes?.avatar?.url ||
      f?.followedTo?.data?.attributes?.avatarUrl ||
      f?.attributes?.followedTo?.data?.attributes?.avatar?.data?.attributes
        ?.url ||
      f?.attributes?.followedTo?.data?.attributes?.avatarUrl,
  }));

  const columns = [
    {
      title: "Profile Picture",
      key: "imgUrl",
      render: (row) => {
        return <Avatar src={row?.imgUrl} size="large" />;
      },
    },
    {
      title: "Followed To",
      // dataIndex: "followerName",
      key: "followerName",
      render: (row) => {
        return (
          <p onClick={() => navigate(`/dashboard/users/${row?.userId}`)}>
            {row?.followerName}
          </p>
        );
      },
    },
    {
      title: "Active",
      key: "active",
      render: (row) => {
        return (
          <Space>
            {!row?.active ? (
              <span className={css.activeDonor}>Active</span>
            ) : (
              <span className={css.inactiveDonor}>Inactive</span>
            )}
            {row?.blocked && (
              <span className={css.inactiveDonor}>Suspended</span>
            )}
          </Space>
        );
      },
    },
  ];
  return (
    <div>
      <Table
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={{
          responsive: true,
          prevIcon: <GrLinkPrevious />,
          nextIcon: <GrLinkNext />,
          defaultCurrent: 1,
          total: info?.followers?.length,
          pageSize: 8,
        }}
      />
    </div>
  );
};

export default FollowingTab;
