import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Partners: ["Business", "IT", "Sponser"],
  Blogs: ["Story", "Blog", "News"],
  FAQ: ["App questions", "Donor questions", "Patient questions"],
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
});

export default categorySlice.reducer;
