import { Col, Layout, Row, Menu as AntMenu } from "antd";
import smallLogo from "../../Images/Navigation/icon.svg";
import { useEffect, useState } from "react";
import { SuperAdminOptions } from "../common/navbarOptions/SuperAdminNavbarOption";
import { AdminOptions } from "../common/navbarOptions/AdminNavbarOptions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLogo } from "../../features/appSettingSlice";
import SuperAdminRoutes from "../../routes/SuperAdminRoutes";
import AdminRoutes from "../../routes/AdminRoutes";
import ModeratorRoutes from "../../routes/ModeratorRoutes";
import { ModeratorOptions } from "../common/navbarOptions/ModeratorNavbarOptions";

const { Content, Sider } = Layout;

const Navbar = () => {
  const [topbarTitle, setTopbarTitle] = useState("");
  const dispatch = useDispatch();
  const [options, setOptions] = useState();
  const role = localStorage.getItem("role");

  useEffect(() => {
    dispatch(getLogo());

    if (role === "Admin") {
      setOptions(AdminOptions);
    } else if (role === "Super Admin") {
      setOptions(SuperAdminOptions);
    } else if (role === "Moderator") {
      setOptions(ModeratorOptions);
    }
  }, [dispatch, role]);

  const settings = useSelector((state) => state.appSetting.settings);
  const logo = settings?.data?.attributes?.appLogo?.data?.attributes?.url;

  const keys = {
    home: "Dashboard",
    users: "Users",
    reviews: "Reviews",
    feedback: "Feedbacks",
    history: "History",
    partners: "Partners",
    banner: "Banner",
    testimonial: "Testimonials",
    badge: "Badges",
    adminrole: "Roles",
    adminteam: "Teams",
    dashboardadminsitesettings: "Site Settings",
    faq: "FAQ",
    bloodneeds: "Blood Needs",
    donorlist: "Donor List",
  };

  const navigate = useNavigate();

  const onClick = (e) => {
    if (e.key === "logout") {
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      localStorage.removeItem("role");
      localStorage.removeItem("avatarUrl");
      localStorage.removeItem("logo");
      navigate("/");
    } else {
      setTopbarTitle(keys[e.key.replace(/[-\/]/g, "")]);
      navigate(`${e.key}`);
    }
  };

  const [collapsed, setCollapsed] = useState(false);

  return (
    <div>
      <Layout>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          collapsedWidth={70}
          width={250}
          breakpoint="md"
          zeroWidthTriggerStyle={{ display: "none" }} // Add this line to hide the expand icon
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
          }}
          theme="dark"
        >
          <div
            style={{
              height: 32,
              width: "150px",
              margin: 16,
              display: "flex",
              justifyContent: "start",
            }}
          >
            {!collapsed ? (
              <Row gutter={[10, 10]}>
                <Col>
                  <img
                    src={logo}
                    alt="menuIcon"
                    style={{
                      height: "40px",
                      objectFit: "contain",
                      width: "fit-content",
                    }}
                  />
                </Col>
              </Row>
            ) : (
              <Row gutter={[10, 10]}>
                <Col>
                  <img
                    src={smallLogo}
                    alt="menuIcon"
                    style={{
                      height: "40px",
                      objectFit: "contain",
                      width: "fit-content",
                    }}
                  />
                </Col>
              </Row>
            )}
          </div>

          <AntMenu
            onClick={onClick}
            theme="dark"
            mode="inline"
            items={options}
          />
        </Sider>

        <Content
          style={{
            marginLeft: collapsed ? "70px" : "250px",
            transition: "0.3s cubic-bezier(0.22, 0.61, 0.36, 1)",
          }}
        >
          {role === "Admin" ? (
            <AdminRoutes />
          ) : role === "Super Admin" ? (
            <SuperAdminRoutes />
          ) : role === "moderator" ? (
            <ModeratorRoutes />
          ) : (
            ""
          )}
        </Content>
      </Layout>
    </div>
  );
};

export default Navbar;
