import { Col, Input, Popover, Row } from "antd";
import React from "react";
import { AiOutlinePlus, AiOutlineUserAdd } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { ImFilter } from "react-icons/im";
import { VscSettings } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import css from "../../styles/filter.module.scss";

const FilterSearch = (props) => {
  const navigate = useNavigate();
  return (
    <Row className={css.searchFilterNew}>
      <Col className={css.searchContainer}>
        <BsSearch className={css.searchIcon} />
        <Input
          bordered={false}
          type="text"
          placeholder={"Search"}
          className={css.searchInput}
          onChange={props.handleSearch}
        />
      </Col>

      {props.show && (
        <Col>
          <div
            className={css.add}
            onClick={() => {
              if (props.from === "blog") {
                navigate("/dashboard/add-blog/");
              } else if (props.from === "page") {
                navigate("/dashboard/add-page/");
              } else {
                props.setIsModalOpen(true);
              }
            }}
          >
            <AiOutlinePlus />
            <span className={css.addText}>{props.title}</span>
          </div>
        </Col>
      )}
      {!props.showFilter && (
        <Popover
          content={props.content}
          trigger="click"
          placement="topRight"
          autoAdjustOverflow={true}
          destroyTooltipOnHide={true}
          open={props.filterModal}
          showArrow={false}
          // onOpenChange={(newOpen) => props.setFilterModal(newOpen)}
          overlayStyle={{ width: "30%" }}
        >
          <Col className={css.filter}>
            <VscSettings
              className={css.filterIcon}
              onClick={() => props.setFilterModal(!props.filterModal)}
            />
          </Col>
        </Popover>
      )}
    </Row>
  );
};

export default FilterSearch;
