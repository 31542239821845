import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BloodNeedInformation from "../../components/bloodNeedDetail/BloodNeedInformation";
import Loading from "../../components/common/Loading";
import HistoryDetailTabs from "../../components/HistoryDetail/HistoryDetailTabs";
import Topbar from "../../components/Layout/TopBar";
import { getBloodNeedsDetail } from "../../features/bloodNeedsSlice";
import { getSingleHistory } from "../../features/historySlice";

const HistoryDetail = () => {
  const { historyId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleHistory(historyId));
  }, [dispatch, historyId]);

  const historyInfo = useSelector((state) => state.history.singleHistory);
  const loading = useSelector((state) => state.history.loading);
  const today = moment.utc();
  const dob = moment
    .utc(
      historyInfo?.data?.attributes?.requestedBy?.data?.attributes?.donorProfile
        ?.data?.attributes?.dob?.dob
    )
    .format("YYYY-MM-DD");
  const age = today.diff(dob, "years");

  return (
    <div>
      <Topbar
        topic={
          window.location.pathname.includes("history")
            ? "History Detail"
            : "Blood Need Detail"
        }
      />
      {loading ? (
        <Loading />
      ) : (
        <BloodNeedInformation
          info={historyInfo}
          age={age}
          detailTabs={<HistoryDetailTabs info={historyInfo} />}
        />
      )}
    </div>
  );
};

export default HistoryDetail;
