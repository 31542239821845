import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { api } from "../Api";
import { BASEURL } from "../Url";

const qs = require("qs");

const initialState = {
  New: [],

  Earlier: [],
};

export const getNotifications = createAsyncThunk(
  "getNotifications",
  async (_, thunkApi) => {
    const query = qs.stringify({
      pagination: {
        pageSize: 100,
      },
      // populate: '*',
      filters: {
        tags: { $eq: "Admin" },
      },
      sort: ["createdAt:desc"],
    });

    try {
      const res = await api.get(`${BASEURL}/notifications?${query}`);
      return res.data?.data;
    } catch (error) {
      console.log(error);
      return thunkApi.rejectWithValue(error.response.data.error.message);
    }
  }
);

export const postNotification = createAsyncThunk(
  "postNotification",
  async (data, thunkApi) => {
    try {
      const res = await api.post(`${BASEURL}/notifications`, {
        data,
      });

      return res.data.data;
    } catch (error) {
      console.log(error, "error");
      return thunkApi.rejectWithValue(error.response.data.error.message);
    }
  }
);

export const readNotification = createAsyncThunk(
  "readNotification",
  async (id, thunkApi) => {
    try {
      const res = await api.get(`${BASEURL}/notifications/${id}/markedAsRead`);
      thunkApi.dispatch(getNotifications());
      return res.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.error.message);
    }
  }
);

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      const oldNotifications = action.payload?.filter(
        (n) =>
          moment.utc(n?.attributes?.createdAt).diff(moment.utc(), "days") < 0
      );

      const newNotifications = action.payload?.filter(
        (n) =>
          moment.utc(n?.attributes?.createdAt).diff(moment.utc(), "days") >= 0
      );

      state.New = newNotifications;
      state.Earlier = oldNotifications;
    });
  },
});

export default notificationSlice.reducer;
