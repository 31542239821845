import { Avatar, Col, Image, Row, Space, Table, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReports } from "../../features/reviewSlice";
import { getBloodNeeds } from "../../features/bloodNeedsSlice";
import { limit } from "../../utils/truncatingString";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const ReportsTab = ({ attachments }) => {
  const dataSource = attachments.map((r) => ({
    id: r?.id,
    userId: r?.attributes?.fromUser?.data?.id,
    imgUrl:
      r?.attributes?.fromUser?.data?.attributes?.avatar?.data?.attributes?.url,
    name: r?.attributes?.fromUser?.data?.attributes?.username,
    message: r?.attributes?.message,
    reportedAt: moment
      .utc(r?.attributes?.createdAt)
      .format("YYYY-MM-DD HH:mm:ss"),
  }));

  const navigate = useNavigate();

  const columns = [
    {
      title: "Reporter",
      key: "name",
      render: (row) => {
        return (
          <Space onClick={() => navigate(`/dashboard/users/${row?.userId}`)}>
            <Avatar src={row?.imgUrl} size="large" />
            <span>{row?.name}</span>
          </Space>
        );
      },
    },
    {
      title: "Problem",
      key: "message",
      render: (row) => {
        return row?.message ? (
          <Tooltip title={row?.message}>{limit(row?.message, 30)}</Tooltip>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Reported At",
      dataIndex: "reportedAt",
      key: "reportedAt",
    },
  ];
  return (
    <div>
      <Table
        bordered
        columns={columns}
        dataSource={dataSource}
        pagination={{
          responsive: true,
          prevIcon: <GrLinkPrevious />,
          nextIcon: <GrLinkNext />,
          total: attachments?.length,
          pageSize: 8,
        }}
      />
    </div>
  );
};

export default ReportsTab;
