import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/userSlice";
import historyReducer from "./features/historySlice";
import bloodNeedsReducer from "./features/bloodNeedsSlice";
import donorListReducer from "./features/donorListSlice";
import partnerReducer from "./features/partnerSlice";
import testimonialReducer from "./features/testimonialSlice";
import blogReducer from "./features/blogSlice";
import bannerReducer from "./features/bannerSlice";
import badgeReducer from "./features/badgeSlice";
import faqReducer from "./features/faqSlice";
import categoryReducer from "./features/categorySlice";
import recentBlogsReducer from "./features/recentBlogsSlice";
import dashboardReducer from "./features/dashboardSlice";
import feedbackReducer from "./features/feedbackSlice";
import reviewsReducer from "./features/reviewSlice";
import roleReducer from "./features/roleSlice";
import bloodGroupReducer from "./features/bloodGroupSlice";
import deleteFileReducer from "./features/deleteFileSlice";
import themeReducer from "./features/themeSlice";
import notificationReducer from "./features/notificationSlice";
import loginReducer from "./features/loginSlice";
import appSettingReducer from "./features/appSettingSlice";
import emailSlice from "./features/email";
import brAcceptor from "./features/brAcceptor";
import verification from "./features/IDVerificationSlice";
import event from "./features/eventSlice";
import organizer from "./features/organizerSlice";
import rewardSlice from "./features/rewardSlice";

const store = configureStore({
  reducer: {
    users: userReducer,
    history: historyReducer,
    bloodNeed: bloodNeedsReducer,
    donorList: donorListReducer,
    partners: partnerReducer,
    testimonial: testimonialReducer,
    blog: blogReducer,
    banner: bannerReducer,
    badge: badgeReducer,
    faq: faqReducer,
    category: categoryReducer,
    recentBlog: recentBlogsReducer,
    dashboard: dashboardReducer,
    feedback: feedbackReducer,
    review: reviewsReducer,
    role: roleReducer,
    bloodGroup: bloodGroupReducer,
    files: deleteFileReducer,
    theme: themeReducer,
    notification: notificationReducer,
    login: loginReducer,
    appSetting: appSettingReducer,
    brAcceptor: brAcceptor,
    sendEmail: emailSlice,
    verification: verification,
    event:event,
    organizer:organizer,
    reward:rewardSlice
  },
});

export default store;
