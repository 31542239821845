import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Form,
  Image,
  Modal,
  Popconfirm,
  Row,
  Switch,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { BiAddToQueue, BiEdit } from "react-icons/bi";
import { GrLink, GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "../../components/Layout/TopBar";
import css from "../../styles/Testimonials/testimonial.module.scss";
import { limit } from "../../utils/truncatingString";
import AddTestimonial from "../../components/Add/AddTestimonial";
import editIcon from "../../Images/Users/editIcon.svg";
import starIcon from "../../Images/Testimonials/Star.svg";
import { ImFilter } from "react-icons/im";
import { BsChevronDown, BsChevronUp, BsSearch } from "react-icons/bs";
import searchIcon from "../../Images/Topbar/Search.svg";
import addDonor from "../../Images/Topbar/addDonor.svg";
import Filter from "../../components/Layout/FilterTestimonial";
import {
  deleteTestimonials,
  getTestimonials,
  pinUnpinTestimonial,
} from "../../features/testimonialSlice";
import moment from "moment";
import DeleteConfirmation from "../../components/Layout/DeleteConfirmation";
import EditTestimonials from "../../components/Edit/EditTestimonials";
import EditDots from "../../components/common/EditDots";
import FilterSearch from "../../components/common/FilterSearch";

const Testimonials = () => {
  // TODO: fetch testimonial data
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState();

  // TODO: get faq data when render
  const [params, setParams] = useState({
    page: 1,
    pageSize: 8,
  });
  useEffect(() => {
    dispatch(getTestimonials({ params, filter: filteredData }));
  }, [dispatch, params]);

  const [form] = Form.useForm();

  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [showData, setShowData] = useState(false);

  const [edit, setEdit] = useState({
    key: 0,
    toggle: false,
  });

  const [toggle, setToggle] = useState(0); //TODO: to expand description on Click

  let testimonialData = useSelector((state) => state.testimonial.testimonials);
  let isLoading = useSelector((state) => state.testimonial.loading);
  // handle search
  const [search, setSearch] = useState("");

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const fields = ["name", "fullDesc", "company", "role"];

  let dataSource = testimonialData?.data?.map((f, i) => ({
    id: f?.id,
    name: f?.attributes?.name,
    description: limit(f?.attributes?.message, 50),
    fullDesc: f?.attributes?.message,
    imgUrl: f?.attributes?.image?.data?.attributes?.url,
    date: moment.utc(f?.attributes?.createdAt).format("YYYY-MM-DD"),
    company: f?.attributes?.organization,
    status: f?.attributes?.pin,
    role: f?.attributes?.designation,
  }));

  if (search) {
    const filteredData = dataSource?.filter((data) => {
      return fields.some((field) =>
        String(data?.[field])?.toLowerCase().includes(search.toLowerCase())
      );
    });
    dataSource = filteredData;
  }

  const testimonialColumn = [
    {
      title: "Name",
      key: "name",
      render: (row) => {
        return (
          <Row
            style={{
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Col>
              <Image
                src={row?.imgUrl}
                alt="image"
                width={30}
                height={30}
                style={{ objectFit: "cover", borderRadius: "50%" }}
              />
            </Col>
            <span>{row?.name}</span>
          </Row>
        );
      },
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },

    {
      title: "Description",
      key: "description",
      render: (row) => {
        return <Tooltip title={row?.fullDesc}>{row?.description}</Tooltip>;
      },
      width: "25%",
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Pin",
      key: "status",
      render: (row, record) => {
        return (
          <Popconfirm
            overlayClassName={css.pop}
            title="Are you sure?"
            okText="Yes"
            cancelText="No"
            onConfirm={() =>
              dispatch(
                pinUnpinTestimonial({
                  data: { data: { pin: !row?.status } },
                  id: row?.id,
                })
              )
            }
          >
            <Switch checked={row?.status} />
          </Popconfirm>
        );
      },
    },
    {
      title: "Action",
      render: (row) => {
        return (
          <EditDots
            icon={editIcon}
            setEdit={setEdit}
            row={row}
            edit={edit}
            setEditModal={setEditModal}
            setDeleteModal={setDeleteModal}
          />
        );
      },
    },
  ];

  //row selection
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSelectChange = (newSelectedRowKeys) => {
    // console.log("newSelectedRowKeys", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  // console.log("selectedRowKeys changed: ", selectedRowKeys);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [hasSelected, setHasSelected] = useState(false);
  useEffect(() => {
    setHasSelected(selectedRowKeys.length > 0);
  }, [selectedRowKeys?.length]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [params]);

  const start = (key) => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      alert(key);
      setLoading(false);
    }, 1000);
  };

  // dropdown functions
  function handleMenuClick(e) {
    // message.info("Click on menu item.");
    // console.log("click", e);
    start(e.key);
  }

  /* dropdown action */
  const items = [
    {
      label: "Send Email",
      key: "1",
    },
    {
      label: "Delete",
      key: "2",
    },
  ];
  const menu = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div>
      <TopBar topic="Testimonial" />

      <div className={css.testimonialContainer}>
        <Row
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <Col>
            <Dropdown menu={menu} disabled={!hasSelected}>
              <Button
                loading={loading}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                Actions <BsChevronDown />
              </Button>
            </Dropdown>
          </Col>

          {/* search filter and add new */}
          <FilterSearch
            handleSearch={handleSearch}
            setFilterModal={setFilterModal}
            setIsModalOpen={setIsModalOpen}
            filterModal={filterModal}
            show={true}
            content={
              <Filter
                setFilterModal={setFilterModal}
                filterModal={filterModal}
                setFilteredData={setFilteredData}
              />
            }
            icon={<BiAddToQueue className={css.filterIcon} />}
            title="Add Testimonial"
          />
        </Row>

        {/* table here */}
        <Table
          scroll={{
            x: 1500,
          }}
          loading={isLoading}
          rowSelection={rowSelection}
          dataSource={dataSource}
          columns={testimonialColumn}
          bordered
          pagination={{
            showTitle: false,
            responsive: true,
            prevIcon: <GrLinkPrevious />,
            nextIcon: <GrLinkNext />,
            // current: params.page,
            total: testimonialData?.meta?.pagination?.total,
            pageSize: params.pageSize,
            onChange: (page) => {
              setParams({
                ...params,
                page,
              });
            },
          }}
        />
      </div>

      {/* modal */}
      <Modal
        open={isModalOpen}
        footer={null}
        className={css.modalSignup}
        centered={true}
        keyboard={false}
        onCancel={handleCancel}
        maskClosable={false}
      >
        <AddTestimonial setIsModalOpen={setIsModalOpen} form={form} />
      </Modal>
      {/* <Modal
        open={filterModal}
        footer={null}
        className={css.modalSignup}
        centered={true}
        keyboard={false}
        maskClosable={false}
        onCancel={handleCancel}
      >
        <Filter setFilterModal={setFilterModal} />
      </Modal> */}

      <Modal
        open={editModal}
        footer={null}
        className={css.modalSignup}
        centered={true}
        maskClosable={false}
        keyboard={false}
        onCancel={() => {
          setEditModal(false);
          setShowData(!showData);
        }}
      >
        <EditTestimonials
          setEditModal={setEditModal}
          id={edit?.key}
          form={form}
          setShowData={setShowData}
          showData={showData}
        />
      </Modal>

      <Modal
        title={"Delete Confirmation"}
        open={deleteModal}
        maskClosable={false}
        footer={null}
        centered={true}
        keyboard={true}
        onCancel={() => setDeleteModal(false)}
      >
        <DeleteConfirmation
          setDeleteModal={setDeleteModal}
          id={edit?.key}
          delete={deleteTestimonials}
          get={getTestimonials}
          fromDelete={true}
        />
      </Modal>
    </div>
  );
};

export default Testimonials;
