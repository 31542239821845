import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Typography,
  Upload,
} from "antd";
import React, { useState } from "react";
import css from "../../styles/addModal.module.scss";
import addCamera from "../../Images/addCamera.svg";
import { useDispatch, useSelector } from "react-redux";
import { postbadges } from "../../features/badgeSlice";
import TextArea from "antd/es/input/TextArea";
import ShowFile from "../showFile/ShowFile";

const AddBadge = (props) => {
  const dispatch = useDispatch();
  const [formInstance] = Form.useForm();
  const [imgSrc, setImgSrc] = useState("");

  const [imageError, setImageError] = useState(null);

  props.form.resetFields = formInstance.resetFields;

  const onSubmit = (data) => {
    const badgeData = {
      ...data,
      badgeUniqueId: data.name,
      dependency: "No of donations",
      metaData: {
        related:
          data.related === "donation count" ||
          data.related === "donated blood type"
            ? "donation"
            : data.related,
        criteria: data.criteria,
      },
    };

    delete badgeData.related;
    delete badgeData.criteria;

    let valid = true;
    if (imgSrc === "") {
      valid = false;
      setImageError("Please Provide image !!");
    }
    if (valid) {
      const formData = new FormData();

      formData.append("files.image", imgSrc, imgSrc.name);
      formData.append("data", JSON.stringify(badgeData));

      dispatch(postbadges(formData));
      setImgSrc("");
    }
    props.setIsModalOpen(false);
  };
  const loading = useSelector((state) => state.badge.loading);

  const handleCancel = () => {
    formInstance.resetFields();
    setImgSrc("");
    props.setIsModalOpen(false);
  };

  const [showDonationCount, setShowDonationCount] = useState(false);
  const [showDonationBloodType, setShowDonationBloodType] = useState(false);
  const [showStoryCount, setShowStoryCount] = useState(false);
  const [showReferer, setShowReferer] = useState(false);
  const [showDonation, setShowDonation] = useState(false);
  const handleCategoryChange = (value) => {
    setShowDonationCount(value === "donation count");
    setShowDonationBloodType(value === "donated blood type");
    setShowStoryCount(value === "story");
    setShowReferer(value === "Refer Friends");
    setShowDonation(value === "Refer Donors");
  };

  return (
    <div className={css.Container}>
      <Row className={css.title}>Add Badge</Row>
      <Form layout="vertical" form={formInstance} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              label="Badge Title"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Title is required",
                },
              ]}
            >
              <Input className={css.input} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Dependency"
              name="related"
              rules={[
                {
                  required: true,
                  message: "Category is required",
                },
              ]}
            >
              <Select
                options={[
                  {
                    label: "Donation count",
                    value: "donation count",
                  },
                  {
                    label: "Donated blood type",
                    value: "donated blood type",
                  },
                  {
                    label: "Story",
                    value: "story",
                  },
                  {
                    label: "Refer Friend",
                    value: "Refer Friends",
                  },
                  {
                    label: "Donation Refer",
                    value: "Refer Donors",
                  },
                ]}
                onChange={handleCategoryChange}
              />
            </Form.Item>
          </Col>
          {showDonationCount && (
            <Col span={8}>
              <Form.Item
                label="Donation Count"
                name="criteria"
                rules={[
                  {
                    required: true,
                    message: 'Donation count is required."',
                  },
                ]}
              >
                <Input type="number" className={css.input} />
              </Form.Item>
            </Col>
          )}

          {showReferer && (
            <Col span={8}>
              <Form.Item
                label="Referer Count"
                name="criteria"
                rules={[
                  {
                    required: true,
                    message: 'Referer count is required."',
                  },
                ]}
              >
                <Input type="number" className={css.input} />
              </Form.Item>
            </Col>
          )}
          {showDonation && (
            <Col span={8}>
              <Form.Item
                label="Donor Refer Count"
                name="criteria"
                rules={[
                  {
                    required: true,
                    message: 'Donor refer count is required."',
                  },
                ]}
              >
                <Input type="number" className={css.input} />
              </Form.Item>
            </Col>
          )}
          {showStoryCount && (
            <Col span={8}>
              <Form.Item
                label="Story Count"
                name="criteria"
                rules={[
                  {
                    required: true,
                    message: 'Story count is required."',
                  },
                ]}
              >
                <Input type="number" className={css.input} />
              </Form.Item>
            </Col>
          )}
          {showDonationBloodType && (
            <Col span={8}>
              <Form.Item
                label="Blood Type"
                name="criteria"
                rules={[
                  {
                    required: true,
                    message: 'Blood Type is required."',
                  },
                ]}
              >
                <Select
                  options={[
                    {
                      label: "Plasma",
                      value: "Pasma",
                    },
                    {
                      label: "Power Blood",
                      value: "Power Blood",
                    },
                    {
                      label: "Whole Blood",
                      value: "Whole Blood",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          )}
          <Col span={8}>
            <Form.Item
              label="Reward Point"
              name="bonusRewards"
              rules={[
                {
                  required: true,
                  message: "Reward point is required",
                },
              ]}
            >
              <Input type="number" className={css.input} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Level"
              name="level"
              rules={[
                {
                  required: true,
                  message: "Level is required",
                },
              ]}
            >
              <Input type="number" className={css.input} />
            </Form.Item>
          </Col>
          {/* <Col span={8}>
            <Form.Item
              label="Criteria"
              name="criteria"
              rules={[
                {
                  required: true,
                  message: "Criteria is required",
                },
              ]}
            >
              <Input className={css.input} />
            </Form.Item>

          </Col> */}
          <Divider />
          <Col span={24}>
            <Form.Item
              label="Badge Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Description is required",
                },
              ]}
            >
              <TextArea className={css.input} />
            </Form.Item>
          </Col>
          <Divider />
          <Row justify={"center"}>
            <Row style={{ marginRight: "8px" }}>
              {imgSrc !== "" && <ShowFile imgSrc={imgSrc} />}
            </Row>
            <Col>
              <Upload
                showUploadList={false}
                listType="picture-card"
                accept=".jpg, .png"
                beforeUpload={(file) => {
                  setImgSrc({ file }.file);
                  return false;
                }}
                onRemove={() => setImgSrc("")}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ display: "flex", gap: "0.5rem" }}>
                    <div className={css.uploadImgDiv}>
                      <img src={addCamera} alt="addCamera" />
                    </div>
                    <span className={css.uploadBtn}>Attachment Upload</span>
                  </div>
                  <span className={css.uploadText}>Upload Picture</span>
                  <span className={css.uploadText}>
                    Only in jpg and png format.
                  </span>
                </div>
              </Upload>
              <Typography.Text style={{ color: "red" }}>
                {imageError}
              </Typography.Text>
            </Col>
          </Row>
          <Divider />
        </Row>
        <Row justify={"end"} style={{ gap: "1rem" }}>
          <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={loading}>
            Add
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default AddBadge;
