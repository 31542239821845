import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../Api";
import { BASEURL } from "../Url";

const qs = require("qs");

const initialState = {
  loading: false,
  donationCount: 0,
};

export const getDonationInfo = createAsyncThunk(
  "getDonationInfo",
  async (userId) => {
    const query = qs.stringify({
      pagination: {
        limit: 1,
      },
      filters: {
        acceptor: {
          id: userId,
        },
        status: "Donated",
      },
    });
    const res = await api.get(`${BASEURL}/b-request-acceptors?${query}`);
    return res.data;
  }
);
const brAcceptorSlice = createSlice({
  name: "brAcceptor",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDonationInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.donationCount = action.payload;
    });
  },
});

export default brAcceptorSlice.reducer;
