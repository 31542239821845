import { Col, Row } from "antd";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getRoles } from "../../features/roleSlice";
import css from "../../styles/addModal.module.scss";

const Filter = (props) => {
  const dispatch = useDispatch();

  const { register, handleSubmit, clearErrors, reset } = useForm();

  const onSubmit = (data) => {
    // TODO: sending only data which needs to be filtered
    const filterData = {};
    for (let index = 0; index < Object.keys(data).length; index++) {
      if (data[Object.keys(data)[index]] !== "") {
        filterData[Object.keys(data)[index]] = data[Object.keys(data)[index]];
      }
    }

    // TODO: Fill atleast one field

    if (Object.keys(filterData).length > 0) {
      props.setFilterModal(false);

      dispatch(getRoles(filterData));
      reset();
    } else {
      alert("Fill atleast one field");
    }
  };

  const handleCancel = () => {
    props.setFilterModal(false);
    clearErrors();
  };

  return (
    <form className={css.Container} onSubmit={handleSubmit(onSubmit)}>
      <Row className={css.title}>Filter</Row>
      <Row gutter={[10, 10]}>
        <Col span={24} className={css.subTitle}>
          Name
        </Col>
        <Col span={24}>
          <input type={"text"} {...register("name")} />
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={24} className={css.subTitle}>
          Description
        </Col>
        <Col span={24}>
          <input type={"text"} {...register("description")} />
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={24} className={css.subTitle}>
          Permissions
        </Col>
        <Col span={12}>
          <select {...register("permissions")}>
            <option value="" selected>
              ---Select Permission---
            </option>
            <option value="permission1">permission1</option>
            <option value="permission2">permission2</option>
            <option value="permission3">permission3</option>
          </select>
        </Col>
      </Row>
      <div className={css.Btns}>
        <button className={css.cancelBtn} type="reset" onClick={handleCancel}>
          Cancel
        </button>
        <button className={css.okBtn} type="submit">
          OK
        </button>
      </div>
    </form>
  );
};

export default Filter;
