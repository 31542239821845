import React, { useEffect } from "react";
import css from "../../styles/Admin/settings.module.scss";
import { Button, Col, Divider, Form, Image, Input, Row, Upload } from "antd";
import { useState } from "react";
import addCamera from "../../Images/addCamera.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLogo, updateSiteSetting } from "../../features/appSettingSlice";
import { deleteFile } from "../../features/deleteFileSlice";
import ShowFile from "../../components/showFile/ShowFile";
import TopBar from "../../components/Layout/TopBar";

const Website = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLogo());
  }, [dispatch]);

  const navigate = useNavigate();

  const [imgSrc, setImgSrc] = useState("");

  const handleCancel = () => {
    navigate(-1);
    setImgSrc("");
  };

  const handleSubmit = (data) => {
    const contactSetting = {};
    const metaSetting = {};

    const formData = new FormData();

    const filterData = {};
    for (let index = 0; index < Object.keys(data).length; index++) {
      if (data[Object.keys(data)[index]] !== undefined) {
        filterData[Object.keys(data)[index]] = data[Object.keys(data)[index]];
        if (Object.keys(data)[index] === "email") {
          contactSetting[Object.keys(data)[index]] =
            data[Object.keys(data)[index]];
        }
        if (Object.keys(data)[index] === "phoneNumber") {
          contactSetting[Object.keys(data)[index]] =
            data[Object.keys(data)[index]];
        }
        if (Object.keys(data)[index] === "address") {
          contactSetting[Object.keys(data)[index]] =
            data[Object.keys(data)[index]];
        }

        if (Object.keys(data)[index] === "title") {
          metaSetting[Object.keys(data)[index]] =
            data[Object.keys(data)[index]];
        }
        if (Object.keys(data)[index] === "description") {
          metaSetting[Object.keys(data)[index]] =
            data[Object.keys(data)[index]];
        }
        if (Object.keys(data)[index] === "keywords") {
          metaSetting[Object.keys(data)[index]] =
            data[Object.keys(data)[index]];
        }
      }
    }
    if (imgSrc !== "") {
      formData.append("files.appLogo", imgSrc, imgSrc.name);
    }

    const newData = {
      contactSetting,
      metaSetting,
    };

    formData.append("data", JSON.stringify(newData));

    dispatch(updateSiteSetting(formData));
  };

  const settings = useSelector((state) => state.appSetting.settings);
  const loading = useSelector((state) => state.appSetting.loading);
  let fileList = [
    {
      uid: String(settings?.data?.attributes?.appLogo?.data?.id),
      url: settings?.data?.attributes?.appLogo?.data?.attributes?.url,
    },
  ];

  const [form] = Form.useForm();

  form.setFieldsValue({
    email: settings?.data?.attributes?.contactSetting?.email,
    address: settings?.data?.attributes?.contactSetting?.address,
    phoneNumber: settings?.data?.attributes?.contactSetting?.phoneNumber,
    title: settings?.data?.attributes?.metaSetting?.title,
    description: settings?.data?.attributes?.metaSetting?.description,
    keywords: settings?.data?.attributes?.metaSetting?.keywords,
  });

  return (
    <>
      <TopBar topic="Site Settings" />
      <div className={css.container}>
        <Form
          className={css.websiteSettings}
          onFinish={handleSubmit}
          form={form}
          layout="vertical"
        >
          <Row>
            <Col span={8}>
              <h4>Change Logo</h4>
              <span>You can upload Logo here or change it. </span>
            </Col>
            <Col span={16}>
              <Row justify={"start"}>
                <Row style={{ marginRight: "8px" }}>
                  {(fileList[0]?.uid !== "undefined" || imgSrc?.uid) && (
                    <>
                      {fileList?.map((file) => {
                        return (
                          <ShowFile
                            file={file}
                            id={fileList[0]?.uid}
                            from={"appSettings"}
                            dis={getLogo}
                            imgSrc={imgSrc}
                          />
                        );
                      })}
                    </>
                  )}
                </Row>
                <Col>
                  <Upload
                    showUploadList={false}
                    listType="picture-card"
                    accept=".jpg, .png"
                    beforeUpload={(file) => {
                      if (fileList[0]?.uid !== "undefined") {
                        dispatch(
                          deleteFile({
                            dis: getLogo,
                            from: "appSettings",
                          })
                        );
                      }

                      setImgSrc({ file }.file);
                      return false;
                    }}
                    onRemove={() => setImgSrc("")}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ display: "flex", gap: "0.5rem" }}>
                        <div className={css.uploadImgDiv}>
                          <img src={addCamera} alt="addCamera" />
                        </div>
                        <span className={css.uploadBtn}>Logo Upload</span>
                      </div>
                      <span className={css.uploadText}>
                        Only in jpg and png format.
                      </span>
                    </div>
                  </Upload>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* TODO: Change contact details */}
          <Divider />
          <Row>
            <Col span={8}>
              <h4>Contact Settings</h4>
              <span>This information will appear on contact page </span>
            </Col>

            <Col span={16}>
              <Row gutter={[16, 16]}>
                <Col lg={8} md={12} sm={24}>
                  <Form.Item label="Email" name={"email"}>
                    <Input type="email" />
                  </Form.Item>
                </Col>
                <Col lg={8} md={12} sm={24}>
                  <Form.Item label="Phone" name={"phoneNumber"}>
                    <Input type="text" />
                  </Form.Item>
                </Col>

                <Col lg={8} md={12} sm={24}>
                  <Form.Item label="Address" name={"address"}>
                    <Input type="text" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* TODO: meta settings */}
          <Divider />
          <Row>
            <Col span={8}>
              <h4>Meta Settings</h4>
              <span>You can optimize SEO.</span>
            </Col>

            <Col span={16}>
              <Row gutter={[16, 16]}>
                <Col lg={8} md={12} sm={24}>
                  <Form.Item label="Title" name={"title"}>
                    <Input type="text" />
                  </Form.Item>
                </Col>
                <Col lg={8} md={12} sm={24}>
                  <Form.Item label="Keywords" name={"keywords"}>
                    <Input.TextArea rows={5} />
                  </Form.Item>
                </Col>

                <Col lg={8} md={12} sm={24}>
                  <Form.Item label="Description" name={"description"}>
                    <Input.TextArea rows={5} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />

          <Row justify={"end"} style={{ gap: "1rem" }}>
            <Button
              type="primary"
              danger
              htmlType="reset"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button htmlType="submit" type="primary" loading={loading}>
              Save Changes
            </Button>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default Website;
