import { Image, Table, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import starIcon from "../../Images/Testimonials/Star.svg";
import css from "../../styles/Blogs/blog.module.scss";
import { limit } from "../../utils/truncatingString";

const OrganizerReviews = ({ reviews }) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  let dataSource = reviews?.data?.map((f, i) => ({
    id: f?.id,
    userId: f?.attributes?.fromUser?.data?.id,
    name: f?.attributes?.fromUser?.data?.attributes?.username,
    avatarUrl:
      f?.attributes?.fromUser?.data?.attributes?.avatar?.data?.attributes?.url,
    comment: f?.attributes?.comments || "--",
    ratingCount: f?.attributes?.ratingCount,
    date: moment.utc(f?.attributes?.eventDateTime).format("YYYY-MM-DD"),
  }));
  const columns = [
    {
      title: "Reviewer Name",
      key: "name",
      render: (row) => {
        return (
          <div
            className={css.profileImage}
            onClick={() => navigate(`/dashboard/users/${row?.userId}`)}
          >
            <Image
              src={row?.avatarUrl}
              alt="image"
              width={30}
              height={30}
              style={{ objectFit: "cover", borderRadius: "50%" }}
            />
            <span>{row?.name}</span>
          </div>
        );
      },
    },

    {
      title: "Comments",
      dataIndex: "comment",
      key: "comment",
      render: (row) => {
        return <Tooltip title={row}>{limit(row, 50)}</Tooltip>;
      },
      width: "40%",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Rating",
      dataIndex: "ratingCount",
      key: "ratingCount",
      render: (row) => {
        return new Array(parseInt(row)).fill(" ").map((item, index) => {
          return <img key={index} src={starIcon} alt="star" />;
        });
      },
    },
  ];

  return (
    <div>
      <Table
        bordered
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        pagination={{
          responsive: true,
          prevIcon: <GrLinkPrevious />,
          nextIcon: <GrLinkNext />,
          defaultCurrent: 1,
          total: reviews?.data?.length,
          pageSize: 10,
        }}
      />
    </div>
  );
};

export default OrganizerReviews;
