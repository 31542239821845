import { Button, Form, Input, Row } from "antd";
import React, { useEffect } from "react";
import css from "../../styles/addModal.module.scss";
import TextArea from "antd/es/input/TextArea";
import { useDispatch } from "react-redux";
import {
  sendDonorIssue,
  sendErrorMsg,
} from "../../features/IDVerificationSlice";

const DonorIssueModal = ({
  setIsModalOpen,
  message,
  Id,
  getDetail,
  path,
  userId,
  body,
  screen,
  paramId,
  customKey,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const onSubmit = (data) => {
    // TODO: sending only data which needs to be filtered

    dispatch(
      sendDonorIssue({
        message: data.message,
        Id,
        getDetail,
        path,
        userId,
        body,
        screen,
        paramId,
        customKey
      })
    );
    form.resetFields();
    setIsModalOpen(false);
  };

  form.setFieldsValue({
    message: message,
  });

  return (
    <div className={css.Container}>
      <Row className={css.title}>Error Message</Row>

      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item
          name={"message"}
          rules={[
            {
              required: true,
              message: "Message is required",
            },
          ]}
        >
          <TextArea placeholder="Write error message..." />
        </Form.Item>

        <Row justify={"end"} style={{ gap: "1rem" }}>
          <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default DonorIssueModal;
