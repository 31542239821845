import { Tabs } from "antd";
import React from "react";
import css from "../../styles/Details/userDetail.module.scss";
import JoinerTable from "./JoinerTable";

const EventInfo = ({ eventJoiner, description, isloading }) => {
  const tabItems = [
    {
      label: "Description",
      key: "1",
      children: <p>{description}</p>,
    },
    {
      label: `Event Joiners`,
      key: "2",
      children: <JoinerTable eventJoiner={eventJoiner} isLoading={isloading} />,
    },
  ];
  return (
    <div className={css.tabs}>
      <Tabs defaultActiveKey="1" items={tabItems} />
    </div>
  );
};

export default EventInfo;
