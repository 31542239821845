// Function to check the token expiry
import { jwtDecode } from "jwt-decode";
export default function checkTokenExpiry(token) {
  try {
    const decoded = jwtDecode(token);
    const expirationTime = decoded.exp;

    const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds

    if (currentTime < expirationTime) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error decoding the token:", error.message);
  }
}
