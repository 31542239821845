import { Table } from "antd";
import moment from "moment";
import React from "react";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import css from "../../styles/BloodNeeds/bloodNeeds.module.scss";

const BloodRequestTab = ({ info }) => {
  const navigate = useNavigate();
  const dataSource = info?.map((f, i) => ({
    id: f?.id,
    name: f?.patientName,
    caseDescription: f?.caseDescription,
    noOfUnits: f?.noOfUnits,
    hospital: f?.hospital,
    patientAge: f?.patientAge,
    patientPhone: f?.patientPhone,
    urgency: f?.urgency,
    bloodType: f?.bloodType,
    self: f?.isForSelf,
    fulfilled: f?.isFulFilled,
    needBefore: moment.utc(f?.needBefore).format("YYYY-MM-DD"),
    gender: f?.patientGender,
  }));

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Blood Type",
      dataIndex: "bloodType",
      key: "bloodType",
      filters: [
        {
          text: "Plasma",
          value: "Pasma",
        },
        {
          text: "Power Blood",
          value: "Power Blood",
        },
        {
          text: "Whole Blood",
          value: "Whole Blood",
        },
      ],
      onFilter: (value, row) => row?.bloodType === value,
    },

    {
      title: "Patient Gender",
      dataIndex: "gender",
      key: "gender",
      filters: [
        {
          text: "Male",
          value: "Male",
        },
        {
          text: "Female",
          value: "Female",
        },
        {
          text: "Other",
          value: "Other",
        },
      ],
      onFilter: (value, row) => row?.gender === value,
    },
    {
      title: "Patient Age",
      dataIndex: "patientAge",
      key: "patientAge",
    },
    {
      title: "Patient Phone",
      dataIndex: "patientPhone",
      key: "patientPhone",
    },
    {
      title: "Urgency",
      key: "urgency",
      render: (row) => {
        if (row?.urgency === "Urgent") {
          return <span className={css.urgency}>{row?.urgency}</span>;
        }
        if (row?.urgency === "Medium") {
          return <span className={css.moderate}>{row?.urgency}</span>;
        }
      },
    },
    {
      title: "Fulfilled",
      key: "fulfilled",
      render: (row) => {
        return row?.fulfilled ? "Fulfilled" : "Not Fulfilled";
      },
    },
  ];

  return (
    <div>
      <Table
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={{
          responsive: true,
          prevIcon: <GrLinkPrevious />,
          nextIcon: <GrLinkNext />,
          total: info?.bloodRequests?.length,
          pageSize: 8,
        }}
        onRow={(row) => {
          return {
            onClick: () => {
              navigate(`/dashboard/blood-needs/${row?.id}`);
            },
          };
        }}
      />
    </div>
  );
};

export default BloodRequestTab;
