import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Row,
  Select,
  Typography,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import css from "../../styles/addModal.module.scss";
import addCamera from "../../Images/addCamera.svg";
import { useDispatch, useSelector } from "react-redux";
import { getbadges, updatebadges } from "../../features/badgeSlice";
import TextArea from "antd/es/input/TextArea";
import ShowFile from "../showFile/ShowFile";

const EditBadge = (props) => {
  const dispatch = useDispatch();
  const [formInstance] = Form.useForm();
  const [imgSrc, setImgSrc] = useState("");
  const [imageError, setimageError] = useState(null);

  let badgeData = useSelector((state) => state.badge.badges);

  const editData = badgeData?.data?.filter((item) => item?.id == props.id);

  const onFinish = (data) => {
    const formData = new FormData();
    if (imgSrc !== "") {
      formData.append("files.image", imgSrc, imgSrc.name);
    }
    let valid = true;
    if (fileList[0].url === undefined && imgSrc === "") {
      valid = false;
      setimageError("Please Provide a valid image");
    }
    if (valid || imgSrc) {
      formData.append("data", JSON.stringify(data));
      dispatch(updatebadges({ formData, id: props?.id }));
      setImgSrc("");
      props.setEditModal(false);
      setimageError("");
    }
  };

  const handleCancel = () => {
    props.setShowData(!props.showData);
    formInstance.resetFields();
    setImgSrc("");
    props.setEditModal(false);
  };

  const loading = useSelector((state) => state.badge.loading);

  useEffect(() => {
    formInstance.setFieldsValue({
      name: editData[0]?.attributes?.name,
      description: editData[0]?.attributes?.description,
      level: editData[0]?.attributes?.level,
      bonusRewards: editData[0]?.attributes?.bonusRewards,
    });
  }, [props.id, props.showData]);

  const fileList = [
    {
      uid: String(editData[0]?.attributes?.image?.data?.id),
      url: editData[0]?.attributes?.image?.data?.attributes?.url,
    },
  ];

  return (
    <div className={css.Container}>
      <Row className={css.title}>Edit Badge</Row>
      <Form layout="vertical" form={formInstance} onFinish={onFinish}>
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              label="Badge Title"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Title is namerequired",
                },
              ]}
            >
              <Input className={css.input} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Level"
              name="level"
              rules={[
                {
                  required: true,
                  message: "Level is required",
                },
              ]}
            >
              <Input className={css.input} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              onFinish
              label="Reward Point"
              name="bonusRewards"
              rules={[
                {
                  required: true,
                  message: "Reward Point is required",
                },
              ]}
            >
              <Input className={css.input} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Badge Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Description is required",
                },
              ]}
            >
              <TextArea className={css.input} />
            </Form.Item>
          </Col>
          <Divider />
          <Row justify={"center"}>
            <Row style={{ marginRight: "8px" }}>
              {(fileList[0]?.uid !== "undefined" || imgSrc !== "") && (
                <>
                  {fileList?.map((file) => {
                    return (
                      <ShowFile
                        file={file}
                        id={props.id}
                        dis={getbadges}
                        from="getbadgEditBadgees"
                        imgSrc={imgSrc}
                      />
                    );
                  })}
                </>
              )}
            </Row>
            <Col>
              <Upload
                showUploadList={false}
                multiple={false}
                listType="picture-card"
                accept=".jpg, .png"
                beforeUpload={(file) => {
                  setImgSrc({ file }.file);
                  setimageError("")
                  return false;
                }}
                onRemove={() => setImgSrc("")}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ display: "flex", gap: "0.5rem" }}>
                    <div className={css.uploadImgDiv}>
                      <img src={addCamera} alt="addCamera" />
                    </div>
                    <span className={css.uploadBtn}>Attachment Upload</span>
                  </div>
                  <span className={css.uploadText}>Upload Picture</span>
                  <span className={css.uploadText}>
                    Only in jpg and png format.
                  </span>
                </div>
              </Upload>
              <Typography.Text style={{ color: "red" }}>
                {imageError}
              </Typography.Text>
            </Col>
          </Row>
          <Divider />
        </Row>
        <Row justify={"end"} style={{ gap: "1rem" }}>
          <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={loading}>
            Save Changes
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default EditBadge;
