import { Button, Col, Form, Input, Row } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import logo from "../../Images/login/logo.png";
import { BASEURL } from "../../Url";
import css from "../../styles/loginform.module.scss";
import checkTokenExpiry from "../../utils/JWTExpiryCheck";

function LoginForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const savedToken = localStorage.getItem("token");
    if (savedToken && checkTokenExpiry(savedToken)) {
      navigate("/dashboard/home");
    }
  }, []);

  const login = async (data) => {
    try {
      setLoading(true);
      const res = await axios.post(`${BASEURL}/auth/local/`, data);
      localStorage.setItem("token", res.data.jwt);
      const token = localStorage.getItem("token");

      if (token) {
        window.location.reload();
        navigate("/dashboard/home");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: error.response.data.error.message,
        confirmButtonColor: "#B00020",
      });
    } finally {
      setLoading(false);
    }
  };

  const [form] = Form.useForm();

  return (
    <div className={css.container}>
      <Row className={css.main}>
        <Row justify={"start"} style={{ width: "100%" }}>
          <Col>
            <img src={logo} alt="logo" className={css.logo} />
          </Col>
        </Row>
        <Form
          onFinish={login}
          layout="vertical"
          className={css.formDiv}
          form={form}
        >
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                label="Email"
                name={"identifier"}
                rules={[
                  {
                    required: true,
                    message: "Email is required",
                  },
                ]}
              >
                <Input placeholder="Enter your email" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Password is required",
                  },
                ]}
              >
                <Input.Password placeholder="Enter your password" />
              </Form.Item>
            </Col>
          </Row>

          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            style={{ backgroundColor: "#e53232", width: "fit-content" }}
          >
            Login
          </Button>
          {/* <Divider /> */}
        </Form>
      </Row>
    </div>
  );
}

export default LoginForm;
