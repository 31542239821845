import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import Swal from "sweetalert2";
import { api } from "../Api";
import { getBadgeInfo } from "../components/common/Badges";
import {
  SendPushNotification,
  getFCM,
} from "../components/SendPushNotification";
import { BASEURL } from "../Url";
import { postNotification } from "./notificationSlice";
import { sendReward } from "./rewardSlice";
import { getUsersById, updateAvailability } from "./userSlice";
const qs = require("qs");

const initialState = { loading: false };

// TODO:send error

export const sendErrorMsg = createAsyncThunk(
  "sendErrorMsg",
  async ({ message, id, userId }, thunkApi) => {
    try {
      const res = await api.put(`${BASEURL}/id-profiles/${id}`, {
        data: {
          verificationNotes: {
            message,
          },
        },
      });

      thunkApi.dispatch(getUsersById(userId));

      getFCM(
        userId,
        "Sorry!!!",
        `Sorry, your ID has been rejected. ${message}`,
        "VerifyId",
        id
      );

      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.error.message);
    }
  }
);

// TODO:verify document of user

export const verifyDocument = createAsyncThunk(
  "verifyDocument",
  async (
    {
      id,
      userId,
      badges,
      body,
      phoneVerified,
      referBy,
      userReferCount,
      referBadges,
    },
    thunkApi
  ) => {
    try {
      const res = await api.put(`${BASEURL}/id-profiles/${id}`, {
        data: {
          verificationNotes: null,
          status: "Verified",
        },
      });

      thunkApi.dispatch(getUsersById(userId));

      getFCM(userId, "Congratulations!!!", body, "VerifyId", id);

      thunkApi.dispatch(
        postNotification({
          title: "Congratulations!!!",
          description: `${body}.`,
          toUser: userId,
          isRead: false,
          metaData: {
            description: `${body}`,
            type: "ID verification",
          },
        })
      );

      // TODO: get badge information of Verified User

      if (phoneVerified) {
        const badgeObject = getBadgeInfo(badges, "Verified User");
        thunkApi.dispatch(
          sendReward({
            badgeId: badgeObject?.id,
            reason: badgeObject?.reason,
            rewardPoint: badgeObject?.rewardPoint,
            userId,
            body: "Congratulations!!! You got Verified User badge.",
            screen: "Badges",
          })
        );

        if (referBy) {
          referBadges?.forEach((item) => {
            if (item?.attributes?.metaData?.criteria - 1 == userReferCount) {
              thunkApi.dispatch(
                sendReward({
                  badgeId: item?.id,
                  reason: item?.attributes?.reason,
                  rewardPoint: item?.attributes?.rewardPoint,
                  userId: referBy,
                  screen: "Badges",
                  body: `You got ${item?.attributes?.name} badge`,
                })
              );
            }
          });

          const badgeObject = getBadgeInfo(badges, "Refer Friends");
          thunkApi.dispatch(
            sendReward({
              badgeId: badgeObject?.id,
              reason: badgeObject?.reason,
              rewardPoint: badgeObject?.rewardPoint,
              userId: referBy,
              body: "Congratulations!!! You got Refer Friends badge.",
              screen: "Badges",
            })
          );
        }
      }

      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.error.message);
    }
  }
);

// TODO:verify document of all

export const verifyDonorProfile = createAsyncThunk(
  "verifyDonorProfile",
  async (
    {
      Id,
      path,
      getAll,
      badges,
      userId,
      body,
      screen,
      paramId,
      orgFollowers,
      donorProfile,
      referBadges,
      donorReferCount,
      refId,
      eventName,
      verificationNotes,
      updatedAt,
      eventJoiners,
      userSettings,
    },
    thunkApi
  ) => {
    const idArray = orgFollowers?.data.map(
      (item) => item?.attributes?.followedBy?.data?.id
    );
    const eventJoinerIds = eventJoiners?.data.map(
      (item) => item?.attributes?.user?.data?.id
    );

    try {
      const res = await api.put(`${BASEURL}/${path}/${Id}`, {
        data: {
          verificationNotes: {
            message: null,
            verifiedAt: new Date(),
          },
          status: "Verified",
        },
      });

      thunkApi.dispatch(getAll(Id));

      if (
        moment
          .utc(verificationNotes?.verifiedAt)
          .isBefore(moment.utc(updatedAt))
      ) {
        SendPushNotification(
          `/topics/event_${paramId}`,
          "Important Notice",
          `We would like to bring to your attention that there have been recent updates to the ${eventName}. Your timely update is appreciated. Thank you for your understanding.`,
          screen,
          paramId
        );
      } else {
        SendPushNotification(
          `/topics/organizer_follow_${userId}`,
          "Don't miss out",
          "Don't miss out – the event is starting soon! Join us now.",
          screen,
          paramId
        );
      }

      getFCM(userId, "Congratulations!!!", body, screen, paramId);

      // TODO: get badge information of Verified User
      if (donorProfile) {
        thunkApi.dispatch(
          updateAvailability({
            userSettings: { ...userSettings, availableForDonation: true },
            id: { userId },
          })
        );
        const badgeObject = getBadgeInfo(badges, "Verified Donor");
        thunkApi.dispatch(
          sendReward({
            badgeId: badgeObject?.id,
            reason: badgeObject?.reason,
            rewardPoint: badgeObject?.rewardPoint,
            userId,
            screen: "Badges",
            body: `You got Verified Donor badge`,
          })
        );

        referBadges?.forEach((item) => {
          if (item?.attributes?.metaData?.criteria - 1 == donorReferCount) {
            thunkApi.dispatch(
              sendReward({
                badgeId: item?.id,
                reason: item?.attributes?.reason,
                rewardPoint: item?.attributes?.rewardPoint,
                userId: refId,
                screen: "Badges",
                body: `You got ${item?.attributes?.name} badge`,
              })
            );
          }
        });
      }

      if (
        moment
          .utc(verificationNotes?.verifiedAt)
          .isBefore(moment.utc(updatedAt))
      ) {
        if (eventJoinerIds?.length > 0) {
          for (let index = 0; index < eventJoinerIds?.length; index++) {
            thunkApi.dispatch(
              postNotification({
                toUser: eventJoinerIds?.[index],
                title: "Important Notice",
                description: `Kindly note recent updates to ${eventName}. Your prompt attention is valued. Thank you for your understanding.`,
                isRead: false,
                metaData: {
                  description: `Kindly note recent updates to ${eventName}. Your prompt attention is valued. Thank you for your understanding.`,
                  type: "event",
                  eventid: paramId,
                },
              })
            );
          }
        }
      } else {
        if (idArray?.length > 0) {
          for (let index = 0; index < idArray?.length; index++) {
            thunkApi.dispatch(
              postNotification({
                toUser: idArray?.[index],
                title: "Don't miss out",
                description: `Don't miss out – ${eventName} is starting soon! Join us now.`,
                isRead: false,
                metaData: {
                  description: `Don't miss out – ${eventName} is starting soon! Join us now.`,
                  type: "event",
                  eventid: paramId,
                },
              })
            );
          }
        }
      }

      return { data: res.data, path: path };
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.error.message);
    }
  }
);

// TODO:send error donor

export const sendDonorIssue = createAsyncThunk(
  "sendDonorIssue",
  async (
    { message, Id, getDetail, path, screen, userId, body, paramId, customKey },
    thunkApi
  ) => {
    try {
      const res = await api.put(`${BASEURL}/${path}/${Id}`, {
        data: {
          ...(customKey === "metaData"
            ? {
                metaData: {
                  message,
                },
              }
            : {
                verificationNotes: {
                  message,
                },
              }),
        },
      });

      thunkApi.dispatch(getDetail(Id));
      getFCM(userId, "Sorry!!!", body, screen, paramId);

      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.error.message);
    }
  }
);

const IDSlice = createSlice({
  name: "IDVerify",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(sendErrorMsg.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(sendErrorMsg.fulfilled, (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "ID verification",
        confirmButtonColor: "#4BB543",
        text: "Error message is sent.",
      });
    });
    builder.addCase(sendErrorMsg.rejected, (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    });

    builder.addCase(verifyDocument.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(verifyDocument.fulfilled, (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "ID verification",
        confirmButtonColor: "#4BB543",
        text: "ID is verified.",
      });
    });
    builder.addCase(verifyDocument.rejected, (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    });

    builder.addCase(sendDonorIssue.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(sendDonorIssue.fulfilled, (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        // title: "Issue created Successfully",
        confirmButtonColor: "#4BB543",
        title: "Error message is sent.",
      });
    });
    builder.addCase(sendDonorIssue.rejected, (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    });

    builder.addCase(verifyDonorProfile.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(verifyDonorProfile.fulfilled, (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title:
          (action.payload.path === "events" && "Event verification") ||
          (action.payload.path === "donor-profiles" && "Donor verification") ||
          (action.payload.path === "organizer-profiles" &&
            "Organization verification"),

        confirmButtonColor: "#4BB543",
        text:
          (action.payload.path === "events" && "Event is verified") ||
          (action.payload.path === "donor-profiles" && "Donor is verified") ||
          (action.payload.path === "organizer-profiles" &&
            "Organization is verified"),
      });
    });
    builder.addCase(verifyDonorProfile.rejected, (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    });
  },
});

export default IDSlice.reducer;
