import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { api } from "../Api";
import { BASEURL } from "../Url";
const qs = require("qs");

const initialState = {
  testimonials: [],
  loading: false,
  error: "",
};

// TODO: get Testimonials

export const getTestimonials = createAsyncThunk(
  "getTestimonials",
  async ({ params, filter }) => {
    const query = qs.stringify({
      pagination: params,
      filters: {
        name: { $eqi: filter?.name },
        organization: { $eqi: filter?.organization },
        message: { $eqi: filter?.message },
        designation: { $eqi: filter?.designation },
        pin: filter?.pin,
      },
      sort: ["updatedAt:desc"],
    });
    const res = await api.get(`${BASEURL}/testimonials?populate=*&${query}`);
    return res.data;
  }
);

// TODO: post testimonials
export const postTestimonials = createAsyncThunk(
  "postTestimonials",
  async (data, thunkApi) => {
    const res = await api.post(`${BASEURL}/testimonials`, data);
    thunkApi.dispatch(
      getTestimonials({
        params: {
          page: 1,
          pageSize: 8,
        },
      })
    );
    return res.data;
  }
);

// TODO: update Testimonials

export const updateTestimonials = createAsyncThunk(
  "updateTestimonials",
  async ({ formData, id }, thunkApi) => {
    const res = await api.put(`${BASEURL}/testimonials/${id}`, formData);
    thunkApi.dispatch(
      getTestimonials({
        params: {
          page: 1,
          pageSize: 8,
        },
      })
    );
    return res.data;
  }
);

export const pinUnpinTestimonial = createAsyncThunk(
  "pinUnpin",
  async ({ data, id }, thunkApi) => {
    const res = await api.put(`${BASEURL}/testimonials/${id}`, data);
    thunkApi.dispatch(getTestimonials({ params: { page: 1, pageSize: 8 } }));
    return res.data;
  }
);
// TODO: delete Testimonials

export const deleteTestimonials = createAsyncThunk(
  "deleteTestimonials",
  async (id, thunkApi) => {
    const res = await api.delete(`${BASEURL}/testimonials/${id}`);
    thunkApi.dispatch(
      getTestimonials({
        params: {
          page: 1,
          pageSize: 8,
        },
      })
    );
    return res.data;
  }
);

const testimonialSlice = createSlice({
  name: "testimonial",
  initialState,
  reducers: {},
  extraReducers: {
    // TODO: get
    [getTestimonials.pending]: (state, action) => {
      state.loading = true;
    },
    [getTestimonials.fulfilled]: (state, action) => {
      state.loading = false;
      state.testimonials = action.payload;
    },
    [getTestimonials.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.error.message,
        confirmButtonColor: "#B00020",
      });
    },

    // TODO: post
    [postTestimonials.pending]: (state, action) => {
      state.loading = true;
    },
    [postTestimonials.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Testimonial Added",
        confirmButtonColor: "#4BB543",
        text: "Testimonial is successfully added",
      });
    },
    [postTestimonials.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.error.message,
        confirmButtonColor: "#B00020",
      });
    },

    // TODO: Update
    [updateTestimonials.pending]: (state, action) => {
      state.loading = true;
    },
    [updateTestimonials.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Testimonial Updated",
        confirmButtonColor: "#4BB543",
        text: "Testimonial is successfully updated",
      });
    },
    [updateTestimonials.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.error.message,
        confirmButtonColor: "#B00020",
      });
    },

    // TODO: Delete
    [deleteTestimonials.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteTestimonials.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Testimonial Deleted",
        confirmButtonColor: "#4BB543",
        text: "Testimonial is successfully deleted",
      });
    },
    [deleteTestimonials.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.error.message,
        confirmButtonColor: "#B00020",
      });
    },
    [pinUnpinTestimonial.pending]: (state, action) => {
      state.loading = true;
    },
    [pinUnpinTestimonial.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [pinUnpinTestimonial.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.error.message,
        confirmButtonColor: "#B00020",
      });
    },
  },
});

export default testimonialSlice.reducer;
