// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCmzWEo9z65Bu2C0YaVDJnwoqpiHhoPSHI",
//   authDomain: "hamro-blood-bank-389409.firebaseapp.com",
//   projectId: "hamro-blood-bank-389409",
//   storageBucket: "hamro-blood-bank-389409.appspot.com",
//   messagingSenderId: "493663181795",
//   appId: "1:493663181795:web:321361b007e079605c1ed6",
//   measurementId: "G-79XPRNSN70",
// };

const firebaseConfig = {
  apiKey: "AIzaSyCkVuwjhAK2ea7-gY3NMnhpFgVXOw_lHhg",
  authDomain: "hamrobloodbank-f3a95.firebaseapp.com",
  projectId: "hamrobloodbank-f3a95",
  storageBucket: "hamrobloodbank-f3a95.appspot.com",
  messagingSenderId: "685128551650",
  appId: "1:493663181795:web:321361b007e079605c1ed6",
  measurementId: "G-GBWZNRBJHV",
  databaseURL: "https://hamrobloodbank.firebaseio.com/",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
