import { Row, Typography } from 'antd'
import React from 'react'

const NoData = ({message}) => {
  return (
    <Row justify={'center'}>
        <Typography.Text>
              {message}
        </Typography.Text>
    </Row>
  )
}

export default NoData