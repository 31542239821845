import React, { useEffect, useState } from "react";
import TopBar from "../../components/Layout/TopBar";
import { Avatar, Button, Col, Dropdown, Image, Row, Table } from "antd";
import { BsChevronDown } from "react-icons/bs";
import FilterSearch from "../../components/common/FilterSearch";
import FilterEvent from "../../components/Layout/FilterEvent";
import css from "../../styles/Users/users.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrganizations } from "../../features/organizerSlice";
import moment from "moment";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useNavigate } from "react-router-dom";

const Organizer = () => {
  const [params, setParams] = useState({
    page: 1,
    pageSize: 8,
  });
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState();

  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [filterModal, setFilterModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getAllOrganizations({ params, filteredData }));
  }, [dispatch]);
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const fields = ["name", "phoneNumber", "location"];
  const loading = useSelector((state) => state.organizer.loading);
  const organizations = useSelector((state) => state.organizer.organizers);
  let dataSource = organizations?.data?.map((f, i) => ({
    key: f?.attributes,
    id: f?.id,
    avatarUrl: f?.attributes?.avatar?.data?.attributes?.url,
    name: f?.attributes?.name,
    organizer: f?.attributes?.organizer?.data?.attributes?.name,
    location: f?.attributes?.mapLocation?.place_name || "--",
    phoneNumber: f?.attributes?.phoneNumber,
    status: f?.attributes?.status,
    event: f?.attributes?.events?.data?.length,
    createdAt: moment.utc(f?.attributes?.createdAt).format("YYYY-MM-DD"),
    verifiedAt: moment
      .utc(f?.attributes?.verificationNotes?.verifiedAt)
      .format("YYYY-MM-DD"),
  }));

  if (search) {
    const filteredData = dataSource?.filter((data) => {
      return fields.some((field) =>
        String(data?.[field])?.toLowerCase().includes(search.toLowerCase())
      );
    });
    dataSource = filteredData;
  }
  const columns = [
    {
      title: "Organizer Name",
      key: "name",
      render: (row) => {
        return (
          <div
            className={css.profileImage}
            onClick={() => navigate(`${row.id}`)}
          >
            <Image
              src={row?.avatarUrl}
              preview={true}
              width={50}
              height={50}
              style={{ borderRadius: "50%" }}
            />
            <span>{row?.name}</span>
          </div>
        );
      },
    },
    {
      title: "Phone Number",
      key: "phoneNumber",
      dataIndex: "phoneNumber",
    },
    { title: "Location", key: "location", dataIndex: "location" },
    {
      title: "Events",
      key: "event",
      dataIndex: "event",
    },

    {
      title: "Created At",
      key: "createdAt",
      dataIndex: "createdAt",
    },

    {
      title: "Verified At",
      key: "verifiedAt",
      dataIndex: "verifiedAt",
    },

    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (row) => {
        return (
          <>
            {row === "Verified" ? (
              <span className={css.activeDonor}>Verified</span>
            ) : (
              <span className={css.inactiveDonor}>Pending</span>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div>
      <TopBar topic={"Organizers"} />
      <div className={css.userContent}>
        <Row
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <Col></Col>
          <FilterSearch
            handleSearch={handleSearch}
            setFilterModal={setFilterModal}
            setIsModalOpen={setIsModalOpen}
            show={false}
            // showFilter={true}
            filterModal={filterModal}
            content={
              <FilterEvent
                setFilterModal={setFilterModal}
                filterModal={filterModal}
                setFilteredData={setFilteredData}
                organizer={true}
              />
            }
          />
        </Row>
        {/* table here */}
        <Table
          loading={loading}
          // rowSelection={rowSelection}
          dataSource={dataSource}
          columns={columns}
          bordered
          pagination={{
            showTitle: false,
            responsive: true,
            prevIcon: <GrLinkPrevious />,
            nextIcon: <GrLinkNext />,
            current: params.page,
            total: organizations?.meta?.pagination?.total,
            pageSize: params.pageSize,
            onChange: (page) => {
              setParams({
                ...params,
                page,
              });
            },
          }}
        />
      </div>
    </div>
  );
};

export default Organizer;
