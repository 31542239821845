import { Badge, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { api } from "../../Api";
import css from "../../styles/Details/userDetail.module.scss";
import { BASEURL } from "../../Url";
import BadgeTab from "../detailTabs/BadgeTab";
import BlogTab from "../detailTabs/BlogTab";
import BloodRequestTab from "../detailTabs/BloodRequestTab";
import DonationTab from "../detailTabs/DonationTab";
import FollowerTab from "../detailTabs/FollowerTab";
import FollowingTab from "../detailTabs/FollowingTab";
import IdProfileTab from "../detailTabs/IdProfileTab";
import OrganizerEventTabList from "./OrganizerEventTabList";
import OrganizerReviews from "./OrganizerReviews";
const qs = require("qs");

const OrganizerTab = ({ userId, organizerInfo, follower }) => {
  const tabItems = [
    {
      label: "Description",
      key: "1",
      children: <p>{organizerInfo?.data?.attributes?.description}</p>,
    },
    {
      label: "Reviews",
      key: "2",
      children: (
        <OrganizerReviews reviews={organizerInfo?.data?.attributes?.reviews} />
      ),
    },
    {
      label: "Events",
      key: "3",
      children: (
        <OrganizerEventTabList
          userId={userId}
          organizerInfo={organizerInfo?.data?.attributes?.events}
        />
      ),
    },
    {
      label: `Followers(${follower?.data?.length})`,
      key: "4",
      children: <FollowerTab info={follower?.data} />,
    },
  ];
  return (
    <div className={css.tabs}>
      <Tabs defaultActiveKey="1" items={tabItems} />
    </div>
  );
};
export default OrganizerTab;
