import { Button, Col, Form, Input, Row, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { editFaq } from "../../features/faqSlice";
import css from "../../styles/addModal.module.scss";

const EditFaq = (props) => {
  const dispatch = useDispatch(); //for dispatching the actions in faqSlice
  const [formInstance] = Form.useForm();
  props.form.resetFields = formInstance.resetFields;

  // TODO: get data from store to show for editing
  const faqData = useSelector((state) => state.faq.faqs);

  const editData = faqData?.data?.filter((item) => item?.id === props.id);

  const onSubmit = (data) => {
    dispatch(editFaq({ data, id: props?.id }));
    props.setEditModal(false);
    formInstance.resetFields();
  };

  const handleCancel = () => {
    props.setEditModal(false);
  };

  formInstance.setFieldsValue({
    question: editData[0]?.attributes?.question,
    type: editData[0]?.attributes?.type,
    answer: editData[0]?.attributes?.answer,
  });

  return (
    <div className={css.Container}>
      <Row className={css.title}>Edit FAQ</Row>

      <Form layout="vertical" form={formInstance} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              label="Question"
              name="question"
              rules={[
                {
                  required: true,
                  message: "Question is required",
                },
              ]}
            >
              <Input className={css.input} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Category"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Category is required",
                },
              ]}
            >
              <Select
                options={[
                  {
                    label: "donor",
                    value: "donor",
                  },
                  {
                    label: "partner",
                    value: "partner",
                  },
                  {
                    label: "suggestion",
                    value: "suggestion",
                  },
                  {
                    label: "volunteering",
                    value: "volunteering",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Answer"
              name="answer"
              rules={[
                {
                  required: true,
                  message: "Answer is required",
                },
              ]}
            >
              <TextArea className={css.input} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"end"} style={{ gap: "1rem" }}>
          <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary">
            Save Changes
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default EditFaq;
