import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "../../components/Layout/TopBar";
import { useEffect } from "react";
import { getMe } from "../../features/userSlice";
import Loading from "../../components/common/Loading";
import UserInformation from "../../components/userDetail/UserInformation";
import moment from "moment";

const AdminProfile = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);
  const userInfo = useSelector((state) => state.users.self);
  const loading = useSelector((state) => state.users.loading);
  const today = moment.utc();
  const dob = moment.utc(userInfo?.donorProfile?.dob).format("YYYY-MM-DD");
  const age = today.diff(dob, "years");

  return (
    <div>
      <TopBar topic="Admin Detail" />
      {loading ? (
        <Loading />
      ) : (
        <UserInformation userInfo={userInfo} age={age} from={"admin"} />
      )}
    </div>
  );
};

export default AdminProfile;
