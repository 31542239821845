import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Typography,
  Upload,
} from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postBanners } from "../../features/bannerSlice";
import addCamera from "../../Images/addCamera.svg";
import css from "../../styles/addModal.module.scss";
import ShowFile from "../showFile/ShowFile";

const AddBanner = (props) => {
  const dispatch = useDispatch();
  const [formInstance] = Form.useForm();
  props.form.resetFields = formInstance.resetFields;
  const [imageError, setImageError] = useState(null);
  const [valid, setValid] = useState(true);
  const [imgSrc, setImgSrc] = useState("");

  const onSubmit = (data) => {
    let valid = true;
    if (imgSrc === "") {
      setValid(false);
      setImageError("Please Provide image !!");
    }
    const formData = new FormData();
    formData.append("files.attachment", imgSrc, imgSrc.name);
    formData.append("data", JSON.stringify(data));
    props.setIsModalOpen(false);
    formInstance.resetFields();
    dispatch(postBanners(formData));
    setImgSrc("");
  };
  const handleCancel = () => {
    setImgSrc("");
    props.setIsModalOpen(false);
  };
  const loading = useSelector((state) => state.banner.loading);

  return (
    <div className={css.Container}>
      <Row className={css.title}>Banner</Row>
      <Form layout="vertical" form={formInstance} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              label="Banner Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Title is required",
                },
              ]}
            >
              <Input className={css.input} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Order"
              name="order"
              rules={[
                {
                  required: true,
                  message: "Order is required",
                },
              ]}
            >
              <Input className={css.input} type="number" />
            </Form.Item>
          </Col>

          <Divider />
          <Row justify={"center"}>
            <Row style={{ marginRight: "8px" }}>
              {imgSrc !== "" && <ShowFile imgSrc={imgSrc} />}
            </Row>
            <Col>
              <Upload
                showUploadList={false}
                listType="picture-card"
                accept=".jpeg, .png"
                beforeUpload={(file) => {
                  setImgSrc({ file }.file);
                  return false;
                }}
                onRemove={() => setImgSrc("")}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ display: "flex", gap: "0.5rem" }}>
                    <div className={css.uploadImgDiv}>
                      <img src={addCamera} alt="addCamera" />
                    </div>
                    <span className={css.uploadBtn}>Attachment Upload</span>
                  </div>
                  <span className={css.uploadText}>Upload Picture</span>
                  <span className={css.uploadText}>
                    Only in jpg and png format.
                  </span>
                </div>
              </Upload>
              <Typography.Text style={{ color: "red" }}>
                {!valid && imageError}
              </Typography.Text>
            </Col>
          </Row>
          <Divider />
        </Row>
        <Row justify={"end"} style={{ gap: "1rem" }}>
          <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={loading}>
            Add
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default AddBanner;
