import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { api } from "../Api";
import { BASEURL } from "../Url";
import {
  SendPushNotification,
  getFCM,
} from "../components/SendPushNotification";

const initialState = {
  bloodNeed: [],
  loading: false,
  error: "",
  bloodNeedDetail: "",

  cardValues: {
    completedNeeds: 0,
    totalNeeds: 0,
    pendingNeeds: 0,
    verifiedNeeds: 0,
  },
};

// TODO: get BloodNeeds
const qs = require("qs");

export const getBloodNeeds = createAsyncThunk(
  "getBloodNeeds",
  async ({ params, filter }, thunkApi) => {
    const query = qs.stringify({
      pagination: params,
      sort: ["urgency:desc", "updatedAt:desc"],
      filters: {
        patientName: { $eqi: filter?.patientName },
        noOfUnits: filter?.noOfUnits,
        patientGender: filter?.patientGender,
        patientPhone: filter?.patientPhone,
        requestedBloodGroup: filter?.requestedBloodGroup,
        urgency: { $eqi: filter?.urgency },
        hospital: { $eqi: filter?.hospital },
        isFulFilled: { $eqi: filter?.isFulFilled },
        verified: { $eqi: filter?.verified },
      },
      populate: [
        "requestedBy.avatar",
        "acceptors",
        "requestedBloodGroup",
        // "reports.fromUser",
      ],
    });
    try {
      const res = await api.get(`${BASEURL}/blood-requests?${query}`);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.error.message);
    }
  }
);

// TODO: complete blood nedd count

export const completeCount = createAsyncThunk("completeCount", async () => {
  const query = qs.stringify({
    // pagination: {
    //   limit: 1,
    // },
    filters: {
      isFulFilled: true,
    },
  });
  const res = await api.get(`${BASEURL}/blood-requests?${query}`);
  return res.data;
});

export const getBloodNeedsDetail = createAsyncThunk(
  "getBloodNeedsDetail",
  async (id) => {
    const query = qs.stringify({
      populate: [
        "acceptors.acceptor.avatar",
        "requestedBy.avatar",
        "requestedBloodGroup",
        "attachments",
        "requestedBy.role",
        "requestedBy.donorProfile.bloodGroup",
        "requestedBy.currentAddress",
        "requestedBy.followers",
        "requestedBy.badges",
        "reports.fromUser.avatar",
        "reports.attachment",
      ],
    });
    const res = await api.get(`${BASEURL}/blood-requests/${id}?${query}`);
    return res.data;
  }
);

// TODO: post BloodNeeds
export const postBloodNeeds = createAsyncThunk(
  "postBloodNeeds",
  async (data, thunkApi) => {
    const res = await api.post(`${BASEURL}/blood-requests`, data);
    thunkApi.dispatch(
      getBloodNeeds({
        params: {
          page: 1,
          pageSize: 6,
        },
      })
    );
    return res.data;
  }
);

// TODO: update BloodNeeds

export const updateBloodNeeds = createAsyncThunk(
  "updateBloodNeeds",
  async ({ formData, id }, thunkApi) => {
    const res = await api.put(`${BASEURL}/blood-requests/${id}`, formData);
    thunkApi.dispatch(getBloodNeedsDetail(id));
    return res.data;
  }
);

// TODO: delete BloodNeeds

export const deleteBloodNeeds = createAsyncThunk(
  "deleteBloodNeeds",
  async (id, thunkApi) => {
    const res = await api.delete(`${BASEURL}/blood-requests/${id}`);
    thunkApi.dispatch(
      getBloodNeeds({
        params: {
          page: 1,
          pageSize: 6,
        },
      })
    );
    return res.data;
  }
);

// TODO: verify BloodNeeds

export const verifyBloodNeed = createAsyncThunk(
  "verifyBloodNeed",
  async (props, thunkApi) => {
    const res = await api.put(`${BASEURL}/blood-requests/${props.id}`, {
      data: {
        metaData: {
          message: null,
          verifiedAt: new Date(),
        },
        verified: true,
      },
    });

    SendPushNotification(
      "/topics/user_push_notification",
      "Blood Need Alert !!!",
      `A new blood need has been posted. Please click to learn more and see if you can help. Thank you.`,
      "NeedDetail",
      props.id
    );

    getFCM(
      props.userId,
      "All the best!!!",
      "Your blood need has been verified.",
      "PrivateNeedDetail",
      props.id
    );

    thunkApi.dispatch(getBloodNeedsDetail(props.id));

    return res.data;
  }
);

const bloodNeedSlice = createSlice({
  name: "bloodNeeed",
  initialState,
  reducers: {},
  extraReducers: {
    // TODO: get
    [getBloodNeeds.pending]: (state, action) => {
      state.loading = true;
    },
    [getBloodNeeds.fulfilled]: (state, action) => {
      state.loading = false;
      state.bloodNeed = action.payload;
    },
    [getBloodNeeds.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.payload,
      });
    },

    // TODO: get detail
    [getBloodNeedsDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [getBloodNeedsDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.bloodNeedDetail = action.payload;
    },
    [getBloodNeedsDetail.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: get complete count
    [completeCount.pending]: (state, action) => {},
    [completeCount.fulfilled]: (state, action) => {
      state.cardValues.completeCount = action.payload.meta.pagination.total;
    },
    [completeCount.rejected]: (state, action) => {},

    // TODO: post
    [postBloodNeeds.pending]: (state, action) => {
      state.loading = true;
    },
    [postBloodNeeds.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Blood Need Added",
        confirmButtonColor: "#4BB543",
        text: "Blood Need is successfully added",
      });
    },
    [postBloodNeeds.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: Update
    [updateBloodNeeds.pending]: (state, action) => {
      state.loading = true;
    },
    [updateBloodNeeds.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Blood Need Updated",
        confirmButtonColor: "#4BB543",
        text: "Blood Need is successfully updated",
      });
    },
    [updateBloodNeeds.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: Delete
    [deleteBloodNeeds.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteBloodNeeds.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Blood Need Deleted",
        confirmButtonColor: "#4BB543",
        text: "Blood Need is successfully deleted",
      });
    },
    [deleteBloodNeeds.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: verify need
    [deleteBloodNeeds.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteBloodNeeds.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Blood Need Deleted",
        confirmButtonColor: "#4BB543",
        text: "Blood Need is successfully deleted",
      });
    },
    [deleteBloodNeeds.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },
  },
});

export default bloodNeedSlice.reducer;
