import { Button, Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { getBloodNeeds } from "../../features/bloodNeedsSlice";
import css from "../../styles/addModal.module.scss";

const Filter = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onSubmit = (data) => {
    // TODO: sending only data which needs to be filtered
    const filterData = {};
    for (let index = 0; index < Object.keys(data).length; index++) {
      if (data[Object.keys(data)[index]] !== undefined) {
        filterData[Object.keys(data)[index]] = data[Object.keys(data)[index]];
      }
    }
    props.setFilteredData(filterData);

    dispatch(
      getBloodNeeds({
        params: {
          page: 1,
          pageSize: 8,
        },
        filter: filterData,
      })
    );

    props.setFilterModal(false);
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    props.setFilterModal(false);
  };

  return (
    <div className={css.Container}>
      <Row className={css.title}>Filter</Row>
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Row gutter={[32, 0]} justify="space-between">
          <Col span={12}>
            <Form.Item label="Full Name" name={"patientName"}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Location" name={"hospital"}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Units Needed" name={"noOfUnits"}>
              <Input type="number" />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item label="Units Fulfilled" name={"fulfilled"}>
              <Input type="number" />
            </Form.Item>
          </Col> */}

          <Col span={12}>
            <Form.Item label="Gender" name={"patientGender"}>
              <Select
                options={[
                  {
                    label: "Male",
                    value: "Male",
                  },
                  {
                    label: "Female",
                    value: "Female",
                  },
                  {
                    label: "Others",
                    value: "Others",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Phone" name={"patientPhone"}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Blood Group" name={"requestedBloodGroup"}>
              <Select
                options={[
                  {
                    label: "A+",
                    value: 1,
                  },
                  {
                    label: "A-",
                    value: 5,
                  },
                  {
                    label: "B+",
                    value: 2,
                  },
                  {
                    label: "B-",
                    value: 6,
                  },
                  {
                    label: "O+",
                    value: 3,
                  },
                  {
                    label: "O-",
                    value: 7,
                  },
                  {
                    label: "AB+",
                    value: 4,
                  },
                  {
                    label: "AB-",
                    value: 8,
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Urgency" name={"urgency"}>
              <Select
                options={[
                  {
                    label: "Urgent",
                    value: "Urgent",
                  },
                  {
                    label: "Medium",
                    value: "Medium",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="FulFilled?" name={"isFulFilled"}>
              <Select
                options={[
                  {
                    label: "Fulfilled",
                    value: true,
                  },
                  {
                    label: "Not Fulfilled",
                    value: false,
                  },
                ]}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Verified?" name={"verified"}>
              <Select
                options={[
                  {
                    label: "Verified",
                    value: true,
                  },
                  {
                    label: "Not Verified",
                    value: false,
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"end"} style={{ gap: "1rem" }}>
          <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary">
            Filter
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default Filter;
