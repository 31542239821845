import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { api } from "../Api";
import { BASEURL } from "../Url";
const qs = require("qs");

const initialState = {
  users: [],
  loading: false,
  error: "",
  singleUser: "",
  self: "",
  cardValues: {
    totalUsers: 0,
    suspendedUsers: 0,
    verifiedUsers: 0,
    usersThisMonth: 0,
  },
};

// TODO: get
export const getUsers = createAsyncThunk(
  "getUsers",
  async ({ params, filter }) => {
    const query = qs.stringify({
      pagination: params,
      filters: {
        blocked: filter?.blocked,
        username: {
          $eqi: filter?.username,
        },
        email: filter?.email,
        phone: filter?.phone,
        phoneVerified: filter?.phoneVerified,
        confirmed: filter?.emailVerified,
        currentAddress: {
          city: { $eqi: filter?.location },
        },
        role: {
          $or: [
            {
              name: "User",
            },
            {
              name: "Public",
            },
          ],
        },
        gender: filter?.gender,
        bloodGroup: {
          name: filter?.bloodType,
        },
      },
      sort: ["updatedAt:desc"],
      populate: [
        "currentAddress",
        "donorProfile.bloodGroup",
        "avatar",
        "role",
        "bloodGroup",
        "idProfile",
      ],
    });
    const res = await api.get(`${BASEURL}/users?${query}`);
    return res.data;
  }
);

// TODO: get Team members

export const getTeamMember = createAsyncThunk(
  "getTeamMember",
  async ({ params, filter }) => {
    const query = qs.stringify({
      pagination: params,
      filters: {
        // username: filter?.username,
        // email: filter?.email,
        // phone: filter?.phone,
        ...(filter?.name
          ? {
              role: {
                name: filter?.name,
              },
            }
          : {
              role: {
                $or: [
                  {
                    name: "Super Admin",
                  },
                  {
                    name: "Admin",
                  },
                ],
              },
            }),
      },
      sort: ["updatedAt:desc"],
      populate: ["role", "avatar"],
    });

    const res = await api.get(`${BASEURL}/users?${query}`);
    return res.data;
  }
);

// TODO: get me
export const getMe = createAsyncThunk("getMe", async (navigate) => {
  try {
    const query = qs.stringify({
      populate: [
        "avatar",
        "currentAddress",
        "donorProfile.bloodGroup",
        "role",
        "followers",
        "badges",
      ],
    });
    const res = await api.get(`${BASEURL}/users/me?${query}`);
    return res.data;
  } catch (error) {
    if (error.response.data.error.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      localStorage.removeItem("role");
      localStorage.removeItem("avatarUrl");
      localStorage.removeItem("logo");
      navigate("/");
    }
  }
});

export const getUsersById = createAsyncThunk("getUsersById", async (id) => {
  const query = qs.stringify({
    populate: [
      "avatar",
      "currentAddress",
      "donorProfile.bloodGroup",
      "role",
      "followers",
      "badges.badge.image",
      "bloodRequests",
      "followers.followedBy.avatar",
      "idProfile.IdBackImage",
      "idProfile.IdFrontImage",
      "referBy",
    ],
  });
  const res = await api.get(`${BASEURL}/users/${id}?${query}`);
  return res.data;
});

//TODO: change blocking of users
export const blockUnblockUser = createAsyncThunk(
  "blockUnblockUser",
  async ({ data, row }, thunkApi) => {
    const res = await api.put(`${BASEURL}/users/${row?.row?.id}`, data);
    thunkApi.dispatch(
      getUsers({
        page: 1,
        pageSize: 8,
      })
    );

    thunkApi.dispatch(getSuspendedUserCounts());
    return res.data;
  }
);

// TODO: post users
export const postUsers = createAsyncThunk(
  "postUsers",
  async (data, thunkApi) => {
    try {
      const res = await api.post(`${BASEURL}/users`, data);
      thunkApi.dispatch(
        getTeamMember({
          page: 1,
          pageSize: 8,
        })
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.error.message);
    }
  }
);

// TODO: update Users
export const updateUserPhone = createAsyncThunk(
  "updateUsers",
  async ({ data, id, from }, thunkApi) => {
    const res = await api.put(`${BASEURL}/users/${id}`, data);
    if (from === "team") {
      thunkApi.dispatch(
        getTeamMember({
          filter: {
            $and: [
              {
                name: {
                  $ne: "User",
                },
              },
              {
                name: {
                  $ne: "Public",
                },
              },
            ],
          },
        })
      );
    } else {
      thunkApi.dispatch(data.getAll(id));
    }
    return res.data;
  }
);

// TODO: update Users
export const updateAvailability = createAsyncThunk(
  "updateAvailability",
  async ({ userSettings, id }, thunkApi) => {
    const res = await api.put(`${BASEURL}/users/${id?.userId}`, {
      userSetting: { userSettings },
    });
    return res.data;
  }
);

export const updateUsers = createAsyncThunk(
  "updateUsers",
  async ({ data, id, from }, thunkApi) => {
    const res = await api.put(`${BASEURL}/users/${id}`, data);
    if (from === "team") {
      thunkApi.dispatch(
        getTeamMember({
          filter: {
            $and: [
              {
                name: {
                  $ne: "User",
                },
              },
              {
                name: {
                  $ne: "Public",
                },
              },
            ],
          },
        })
      );
    } else {
      thunkApi.dispatch(
        getUsers({
          page: 1,
          pageSize: 8,
        })
      );
    }
    return res.data;
  }
);

// TODO: delete

export const deactivateUser = createAsyncThunk(
  "deactivateUser",
  async ({ id, from }, thunkApi) => {
    const query = qs.stringify({
      ids: id,
    });
    const res = await api.delete(
      `${BASEURL}/users-permissions/users/deactivateMany/?${query}`
    );
    if (from === "team") {
      thunkApi.dispatch(
        getTeamMember({
          page: 1,
          pageSize: 8,
        })
      );
    } else {
      thunkApi.dispatch(
        getUsers({
          page: 1,
          pageSize: 8,
        })
      );
    }
    return res.data;
  }
);

export const deleteUser = createAsyncThunk(
  "deleteUser",
  async (id, thunkApi) => {
    const res = await api.delete(`${BASEURL}/users/${id}`);
    thunkApi.dispatch(
      getUsers({
        page: 1,
        pageSize: 8,
      })
    );
    return res.data;
  }
);

// TODO: delete Testimonials

export const activateUser = createAsyncThunk(
  "activateUser",
  async ({ id, from }, thunkApi) => {
    const query = qs.stringify({
      ids: id,
    });
    const res = await api.put(
      `${BASEURL}/users-permissions/users/activateMany/?${query}`
    );
    if (from === "team") {
      thunkApi.dispatch(
        getTeamMember({
          page: 1,
          pageSize: 8,
        })
      );
    } else {
      thunkApi.dispatch(
        getUsers({
          page: 1,
          pageSize: 8,
        })
      );
    }
    return res.data;
  }
);

// TODO: get user counts

export const getAllUserCounts = createAsyncThunk("getUserCounts", async () => {
  const query = qs.stringify({
    filters: {
      role: {
        name: "User",
      },
    },
  });
  const res = await api.get(`${BASEURL}/users?${query}`);

  return res.data;
});

// TODO: get user counts

export const getSuspendedUserCounts = createAsyncThunk(
  "getSuspendedUserCounts",
  async () => {
    const query = qs.stringify({
      filters: {
        blocked: true,
      },
    });
    const res = await api.get(`${BASEURL}/users?${query}`);

    return res.data;
  }
);

// TODO: get user counts

export const getThisMonthUsers = createAsyncThunk(
  "getThisMonthUsers",
  async () => {
    // Get the current month and year
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so we add 1
    const currentYear = currentDate.getFullYear();

    // Calculate the start and end of the current month
    const startOfMonth = new Date(currentYear, currentMonth - 1, 1); // Month is zero-based
    const endOfMonth = new Date(currentYear, currentMonth, 0); // Day 0 of next month is the last day of the current month

    // Add filters for createdAt within the current month
    const filters = {
      createdAt: {
        $gte: startOfMonth.toISOString(),
        $lt: endOfMonth.toISOString(),
      },
    };

    const query = qs.stringify({
      filters: filters,
    });

    const res = await api.get(`${BASEURL}/users?${query}`);

    return res.data;
  }
);

// TODO: get user counts

export const getVerifiedUsers = createAsyncThunk(
  "getVerifiedUsers",
  async () => {
    const query = qs.stringify({
      filters: {
        $and: [
          {
            phoneVerified: true,
          },
          {
            confirmed: true,
          },
          {
            idProfile: {
              status: "Verified",
            },
          },
        ],
      },
    });

    const res = await api.get(`${BASEURL}/users?${query}`);

    return res.data;
  }
);

// TODO:
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    // TODO: get
    [getUsers.pending]: (state, action) => {
      state.loading = true;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.users = action.payload;
    },
    [getUsers.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    },

    // TODO: get Team
    [getTeamMember.pending]: (state, action) => {
      state.loading = true;
    },
    [getTeamMember.fulfilled]: (state, action) => {
      state.loading = false;
      state.users = action.payload;
    },
    [getTeamMember.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    },

    // TODO: get me
    [getMe.pending]: (state, action) => {
      state.loading = true;
    },
    [getMe.fulfilled]: (state, action) => {
      state.loading = false;
      state.self = action.payload;
    },
    [getMe.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    },

    //TODO: blockUnblockUser

    [blockUnblockUser.pending]: (state, action) => {
      state.loading = true;
    },
    [blockUnblockUser.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [blockUnblockUser.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    },

    [getUsersById.pending]: (state, action) => {
      state.loading = true;
    },
    [getUsersById.fulfilled]: (state, action) => {
      state.loading = false;
      state.singleUser = action.payload;
    },
    [getUsersById.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    },

    // TODO: post
    [postUsers.pending]: (state, action) => {
      state.loading = true;
    },
    [postUsers.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Team member Added",
        confirmButtonColor: "#4BB543",
        text: "Team member is successfully added",
      });
    },
    [postUsers.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    },

    // TODO: Update
    [updateUsers.pending]: (state, action) => {
      state.loading = true;
    },
    [updateUsers.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Updated",
        confirmButtonColor: "#4BB543",
        text: "User is successfully updated",
      });
    },
    [updateUsers.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    },

    // TODO: Deactivate
    [deactivateUser.pending]: (state, action) => {
      state.loading = true;
    },
    [deactivateUser.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "User Deactivated",
        confirmButtonColor: "#4BB543",
        text: "User is successfully deactivated",
      });
    },
    [deactivateUser.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    },

    // TODO: Delete
    [deleteUser.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "User Deleted",
        confirmButtonColor: "#4BB543",
        text: "User is successfully deleted",
      });
    },
    [deleteUser.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    },

    // TODO: activate
    [activateUser.pending]: (state, action) => {
      state.loading = true;
    },
    [activateUser.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "User Activated",
        confirmButtonColor: "#4BB543",
        text: "User is successfully Activated",
      });
    },
    [activateUser.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    },

    // TODO: all authenticated users

    [getAllUserCounts.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllUserCounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.cardValues.totalUsers = action.payload.length;
    },
    [getAllUserCounts.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    },

    // TODO: all suspended users

    [getSuspendedUserCounts.pending]: (state, action) => {
      state.loading = true;
    },
    [getSuspendedUserCounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.cardValues.suspendedUsers = action.payload.length;
    },
    [getSuspendedUserCounts.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    },

    // TODO: this month users

    [getThisMonthUsers.pending]: (state, action) => {
      state.loading = true;
    },
    [getThisMonthUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.cardValues.usersThisMonth = action.payload.length;
    },
    [getThisMonthUsers.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    },

    // TODO: all suspended users

    [getVerifiedUsers.pending]: (state, action) => {
      state.loading = true;
    },
    [getVerifiedUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.cardValues.verifiedUsers = action.payload.length;
    },
    [getVerifiedUsers.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    },
  },
});

export default userSlice.reducer;
