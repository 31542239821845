import { Col, Form, Input, Row, Button } from "antd";
import JoditEditor from "jodit-react";
import React, { useMemo, useState } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../../components/Layout/TopBar";
import css from "../../styles/page/page.module.scss";
import slugify from "slugify";
import { useDispatch } from "react-redux";
import { postPage } from "../../features/blogSlice";

const AddPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const pageData = { ...data, type: "page" };
    dispatch(postPage({ data: pageData }));
    navigate(-1)
  };

  

  const handleCancel = () => {
    navigate(-1);
  };

  const editor = useRef(null);
  const [content, setContent] = useState(null);

  const config = useMemo(
    () => ({
      uploader: { insertImageAsBase64URI: true },
      showXPathInStatusbar: false,
      showCharsCounter: false,
      showWordsCounter: false,
      toolbarAdaptive: false,
      tabs: true,
    }),
    []
  );

  const [form] = Form.useForm();

  const [values, setValues] = useState({
    title: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  form.setFieldsValue({
    slug: slugify(values.title, { lower: true }),
  });

  return (
    <div>
      <TopBar topic="Create Page" />
      <div className={css.userContent}>
        <Form onFinish={onSubmit} form={form} layout="vertical">
          <Col>
            <Form.Item
              label="Page title"
              name={"title"}
              rules={[
                {
                  required: true,
                  message: "Title is required",
                },
              ]}
            >
              <Input onChange={handleChange} name="title" />
            </Form.Item>
          </Col>

          {/* TODO: URL */}

          <Col>
            <Form.Item label="URL" name={"slug"}>
              <Input readOnly />
            </Form.Item>
          </Col>

          {/* TODO: text editor */}
          <Col span={24}>
            <Form.Item label="Page Description" name="detail">
              <JoditEditor
                className={css.editor}
                ref={editor}
                value={content}
                config={config}
                onChange={(newContent) => setContent(newContent)}
              />
            </Form.Item>
          </Col>

          <Row justify={"end"} style={{ gap: "1rem" }}>
            <Button
              type="primary"
              danger
              htmlType="reset"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button htmlType="submit" type="primary">
              Create
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default AddPage;
