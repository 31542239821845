import { Avatar, Space, Table } from "antd";
import React from "react";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import css from "../../styles/DonorList/donorList.module.scss";

const FollowerTab = ({ info, follower }) => {
  const navigate = useNavigate();

  const dataSource =
    info &&
    info?.map((f, i) => ({
      userId: f?.followedBy?.id || f?.attributes?.followedBy?.data?.id,
      id: f?.id,
      followerName:
        f?.followedBy?.username ||
        f?.attributes?.followedBy?.data?.attributes?.username,
      active:
        f?.followedBy?.deactivate ||
        f?.attributes?.followedBy?.data?.attributes?.deactivate,
      blocked:
        f?.followedBy?.blocked ||
        f?.attributes?.followedBy?.data?.attributes?.blocked,
      imgUrl:
        f?.followedBy?.avatar?.url ||
        f?.followedBy?.avatarUrl ||
        f?.attributes?.followedBy?.data?.attributes?.avatar?.data?.attributes
          ?.url ||
        f?.attributes?.followedBy?.data?.attributes?.avatarUrl,
    }));

  const columns = [
    {
      title: "Profile Picture",
      key: "imgUrl",
      render: (row) => {
        return <Avatar src={row?.imgUrl} size="large" />;
      },
    },

    {
      title: "Follower's Name",
      // dataIndex: "followerName",
      key: "followerName",
      render: (row) => {
        return (
          <p onClick={() => navigate(`/dashboard/users/${row?.userId}`)}>
            {row?.followerName}
          </p>
        );
      },
    },
    {
      title: "Active",
      key: "active",
      width: 200,
      render: (row) => {
        return (
          <Space>
            {!row?.active ? (
              <span className={css.activeDonor}>Active</span>
            ) : (
              <span className={css.inactiveDonor}>Inactive</span>
            )}
            {row?.blocked && (
              <span className={css.inactiveDonor}>Suspended</span>
            )}
          </Space>
        );
      },
    },
  ];
  return (
    <div>
      <Table
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={{
          responsive: true,
          prevIcon: <GrLinkPrevious />,
          nextIcon: <GrLinkNext />,
          defaultCurrent: 1,
          total: info?.followers?.length,
          pageSize: 8,
        }}
      />
    </div>
  );
};

export default FollowerTab;
