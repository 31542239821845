import { Col, Divider, Image, Row, Space, Tabs, Typography } from "antd";
import { List } from "antd/es";
import React from "react";
import AdminSettings from "../../pages/adminSettings/AdminSettings";
import css from "../../styles/Details/userDetail.module.scss";
import BadgeDetailTabs from "./BadgeDetailTabs";

const BadgeInformation = ({ info, from, badgeId }) => {



  const data = [
    "Racing car sprays burning fuel into crowd.",
    "Japanese princess to wed commoner.",
    "Australian walks 100km after outback crash.",
    "Man charged over missing wedding girl.",
    "Los Angeles battles huge wildfires.",
  ];

  return (
    <div className={css.userDetailContainer}>
      <div className={css.content}>
        <div className={css.imagePart}>
          <Row
            style={{ flexDirection: "column" }}
            align={"middle"}
            gutter={[0, 16]}
          >
            <Col>
              <Image
                src={info?.data?.attributes?.image?.data?.attributes?.url}
                alt="profileImage"
                className={css.profileImage}
                width={150}
                height={150}
                preview={false}
              />
            </Col>
            <Typography.Title level={3} style={{ marginBottom: "-1rem" }}>
              {info?.data?.attributes?.name}
            </Typography.Title>

            <Col>
              <Row
                gutter={[8]}
                align={"middle"}
                style={{ marginTop: "0.5rem" }}
              >
                <Col>{info?.data?.attributes?.users?.data?.length || 0}</Col>
                <Col>Badge owners</Col>
              </Row>
            </Col>
            <Divider />
          </Row>

          <Row>
            <Space direction="vertical" size="middle">
              <Typography.Title level={5}>Basic Information</Typography.Title>

              <Row gutter={[8, 0]}>
                <Col>
                  <Typography.Text
                    style={{
                      color: "#808080",
                      marginBottom: "-0.5rem",
                      display: "block",
                    }}
                  >
                    Reward Point:
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text>
                    {info?.data?.attributes?.bonusRewards}
                  </Typography.Text>
                </Col>
              </Row>

              <Row gutter={[8, 0]}>
                <Col>
                  <Typography.Text
                    style={{
                      color: "#808080",
                      // marginBottom: "-0.5rem",
                      display: "block",
                    }}
                  >
                    Description:
                  </Typography.Text>
                </Col>
                
                <Col>
                  <Typography.Text>
                    {info?.data?.attributes?.description}
                  </Typography.Text>
                </Col>
              </Row>
              <Divider />

              <Row>
                <Space direction="vertical" size="middle">
                  <Typography.Title level={5}>Rules</Typography.Title>
                  <List
                    dataSource={data}
                    renderItem={(item, i) => (
                      <List.Item>
                        {i + 1}. {item}
                      </List.Item>
                    )}
                  />
                </Space>
              </Row>
            </Space>
          </Row>
        </div>

        <div className={css.descriptionPart}>
          {from === "admin" ? (
            <AdminSettings />
          ) : (
            <BadgeDetailTabs info={info} badgeId={badgeId} />
          )}
        </div>
      </div>
    </div>
  );
};

export default BadgeInformation;
