import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Upload,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPartners, updatePartners } from "../../features/partnerSlice";
import addCamera from "../../Images/addCamera.svg";
import css from "../../styles/addModal.module.scss";
import ShowFile from "../showFile/ShowFile";

const EditPartner = (props) => {
  const dispatch = useDispatch(); //for dispatching the actions in faqSlice
  const [formInstance] = Form.useForm();
  props.form.resetFields = formInstance.resetFields;

  // TODO: get data from store to show for editing
  let partnerData = useSelector((state) => state.partners.partners);
  const loading = useSelector((state) => state.partners.loading);

  const editData = partnerData?.data?.filter((item) => item?.id == props.id);
  // TODO: to store the file
  const [imgSrc, setImgSrc] = useState("");

  const onSubmit = (data) => {
    const formData = new FormData();
    if (imgSrc !== "") {
      formData.append("files.logo", imgSrc, imgSrc.name);
    }

    formData.append("data", JSON.stringify(data));

    dispatch(updatePartners({ formData, id: props?.id }));
    setImgSrc("");
    props.setEditModal(false);
  };

  const handleCancel = () => {
    formInstance.resetFields();
    setImgSrc("");
    props.setEditModal(false);
  };

  useEffect(() => {
    formInstance.setFieldsValue({
      name: editData[0]?.attributes?.name,
      message: editData[0]?.attributes?.message,
      email: editData[0]?.attributes?.email,
      phone: editData[0]?.attributes?.phone,
      type: editData[0]?.attributes?.type,
      verified: editData[0]?.attributes?.verified,
      pin: editData[0]?.attributes?.pin,
      website: editData[0]?.attributes?.website,
    });
  }, [props.id, props.showData]);

  let fileList = [
    {
      uid: String(editData[0]?.attributes?.logo?.data?.id),
      url: editData[0]?.attributes?.logo?.data?.attributes?.url,
    },
  ];

  return (
    <div className={css.Container}>
      <Row className={css.title}>Edit Partner</Row>
      <Form layout="vertical" form={formInstance} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Name is required",
                },
              ]}
            >
              <Input className={css.input} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email is required",
                },
              ]}
            >
              <Input type="email" className={css.input} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Organization URL"
              name="website"
              rules={[
                {
                  required: true,
                  message: "URL is required",
                },
              ]}
            >
              <Input className={css.input} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Category"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Category is required",
                },
              ]}
            >
              <Select
                options={[
                  {
                    label: "Business",
                    value: "business",
                  },
                  {
                    label: "Technical",
                    value: "technical",
                  },
                  {
                    label: "Health",
                    value: "health",
                  },
                  {
                    label: "Media",
                    value: "media",
                  },
                  {
                    label: "Social",
                    value: "social",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Phone"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Phone is required",
                },
              ]}
            >
              <Input className={css.input} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={2}>
            <Form.Item label="Verified" name="verified" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={2}>
            <Form.Item label="Pin" name="pin" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Message" name="message">
              <TextArea className={css.input} />
            </Form.Item>
          </Col>

          <Divider />
          <Row justify={"center"}>
            <Row style={{ marginRight: "8px" }}>
              {(fileList[0]?.uid !== "undefined" || imgSrc !== "") && (
                <>
                  {fileList?.map((file) => {
                    return (
                      <ShowFile
                        file={file}
                        id={props.id}
                        dis={getPartners}
                        from="getPartners"
                        imgSrc={imgSrc}
                      />
                    );
                  })}
                </>
              )}
            </Row>
            <Col>
              <Upload
                showUploadList={false}
                multiple={false}
                listType="picture-card"
                accept=".jpg, .png"
                beforeUpload={(file) => {
                  setImgSrc({ file }.file);
                  return false;
                }}
                onRemove={() => setImgSrc("")}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ display: "flex", gap: "0.5rem" }}>
                    <div className={css.uploadImgDiv}>
                      <img src={addCamera} alt="addCamera" />
                    </div>
                    <span className={css.uploadBtn}>Attachment Upload</span>
                  </div>
                  <span className={css.uploadText}>Upload Picture</span>
                  <span className={css.uploadText}>
                    Only in jpg and png format.
                  </span>
                </div>
              </Upload>
            </Col>
          </Row>
          <Divider />
        </Row>
        <Row justify={"end"} style={{ gap: "1rem" }}>
          <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={loading}>
            Save Changes
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default EditPartner;
