import { Button, Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { getNotification } from "../../features/appSettingSlice";
import { getUsers } from "../../features/userSlice";
import css from "../../styles/addModal.module.scss";

const Filter = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onSubmit = (data) => {
    // TODO: sending only data which needs to be filtered
    const filterData = {};
    for (let index = 0; index < Object.keys(data).length; index++) {
      if (data[Object.keys(data)[index]] !== undefined) {
        filterData[Object.keys(data)[index]] = data[Object.keys(data)[index]];
      }
    }
    props.setFilteredData(filterData)
    // console.log(filterData);
    dispatch(
      getNotification({
        params: {
          page: 1,
          pageSize: 8,
        },
        filter: filterData,
      })
    );

    props.setFilterModal(false);
    form.resetFields();
  };
  const handleCancel = () => {
    form.resetFields();
    props.setFilterModal(false);
  };

  return (
    <div className={css.Container}>
      <Row className={css.title}>Filter</Row>
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Row gutter={[32, 0]} justify="space-between">
          {/* <Col span={12}>
            <Form.Item name={"title"} label="Title">
              <Input />
            </Form.Item>
          </Col> */}

          <Col span={12}>
            <Form.Item label="Status" name={"isRead"}>
              <Select>
                <Select.Option value={true}>Read</Select.Option>
                <Select.Option value={false}>Unread</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"end"} style={{ gap: "1rem" }}>
          <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary">
            Filter
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default Filter;
