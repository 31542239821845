import { Form, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "../../components/Layout/TopBar";
import css from "../../styles/Badge/badge.module.scss";
import AddBadge from "../../components/Add/AddBadge";
import { deletebadges, getbadges } from "../../features/badgeSlice";
import Filter from "../../components/Layout/FilterBadge";
import { AiOutlineUserAdd } from "react-icons/ai";
import FilterSearch from "../../components/common/FilterSearch";
import moment from "moment";
import { Image, Table } from "antd/es";
import { limit } from "../../utils/truncatingString";
import { useNavigate } from "react-router-dom/dist";
import editIcon from "../../Images/Users/editIcon.svg";
import EditDots from "../../components/common/EditDots";
import EditBadge from "../../components/Edit/EditBadge";
import DeleteConfirmation from "../../components/Layout/DeleteConfirmation";

const Badge = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [form] = Form.useForm();

  const [params, setParams] = useState({
    page: 1,
    pageSize: 6,
  });

  const handleCancel = () => {
    form.resetFields();
    setEditModal(false);
    setIsModalOpen(false);
    setFilterModal(false);
  };

  // filter Modal

  const [edit, setEdit] = useState({
    key: 0,
  });

  const [showData, setShowData] = useState(false);

  useEffect(() => {
    dispatch(getbadges({ params }));
  }, [dispatch, params]);

  let badgeData = useSelector((state) => state.badge.badges);
  let load = useSelector((state) => state.badge.loading);

  // handle search
  const [search, setSearch] = useState("");

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const fields = ["name", "description", "userCount", "rewardPoint"];

  let dataSource = badgeData?.data?.map((f) => ({
    id: f?.id,
    badgeUrl: f?.attributes?.image?.data?.attributes?.url,
    name: f?.attributes?.name,
    description: limit(f?.attributes?.description, 50),
    createdAt: moment.utc(f?.attributes?.createdAt).format("YYYY-MM-DD"),
    level: f?.attributes?.level,
    rewardPoint: f?.attributes?.bonusRewards || 0,
  }));

  if (search) {
    const filteredData = dataSource?.filter((data) => {
      return fields.some((field) =>
        String(data?.[field])?.toLowerCase().includes(search.toLowerCase())
      );
    });
    dataSource = filteredData;
  }

  const columns = [
    {
      title: "Image",
      key: "badgeUrl",
      render: (row) => {
        return (
          <Image
            src={row?.badgeUrl}
            width={65}
            height={65}
            style={{ objectFit: "cover" }}
          />
        );
      },
    },
    {
      title: "Name",
      key: "name",
      // dataIndex: "name",
      render: (row) => {
        return (
          <span
            onClick={() => {
              navigate(`/dashboard/badge/${row?.id}`);
            }}
          >
            {row?.name}
          </span>
        );
      },
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
    },
    {
      title: "Level",
      key: "level",
      dataIndex: "level",
    },
    {
      title: "Reward Point",
      key: "rewardPoint",
      dataIndex: "rewardPoint",
    },
    {
      title: "Created Date",
      key: "createdAt",
      dataIndex: "createdAt",
    },

    {
      title: "Action",
      width: 100,
      render: (row) => {
        return (
          <EditDots
            icon={editIcon}
            setEdit={setEdit}
            row={row}
            edit={edit}
            setEditModal={setEditModal}
            setDeleteModal={setDeleteModal}
          />
        );
      },
    },
  ];

  return (
    <div>
      <TopBar topic="Badge" />
      <div className={css.testimonialContainer}>
        {/* search filter and add new */}
        <div className={css.searchFilterNew}>
          <FilterSearch
            handleSearch={handleSearch}
            setFilterModal={setFilterModal}
            setIsModalOpen={setIsModalOpen}
            title="Add Badge"
            show={true}
            filterModal={filterModal}
            showFilter={true}
            content={
              <Filter
                setFilterModal={setFilterModal}
                filterModal={filterModal}
              />
            }
            icon={<AiOutlineUserAdd className={css.filterIcon} />}
          />
        </div>
        {/* {!load ? <Row gutter={[40, 40]}>{card}</Row> : <Loading />} */}
        <Table
          scroll={{
            x: 1500,
          }}
          bordered
          dataSource={dataSource}
          columns={columns}
          loading={load}
          pagination={{
            responsive: true,
            prevIcon: <GrLinkPrevious />,
            nextIcon: <GrLinkNext />,
            current: params.page,
            total: badgeData?.meta?.pagination?.total,
            pageSize: params.pageSize,
            onChange: (page) => {
              setParams({
                ...params,
                page,
              });
            },
          }}
        />
      </div>

      <Modal
        style={{
          top: 20,
        }}
        maskClosable={false}
        open={isModalOpen}
        footer={null}
        className={css.modalSignup}
        // centered={true}
        keyboard={false}
        onCancel={handleCancel}
      >
        <AddBadge setIsModalOpen={setIsModalOpen} form={form} />
      </Modal>

      <Modal
        style={{
          top: 20,
        }}
        open={editModal}
        footer={null}
        className={css.modalSignup}
        // centered={true}
        maskClosable={false}
        keyboard={true}
        onCancel={() => {
          setEditModal(false);
          setShowData(!showData);
        }}
      >
        <EditBadge
          setEditModal={setEditModal}
          id={edit?.key}
          form={form}
          setShowData={setShowData}
          showData={showData}
        />
      </Modal>

      <Modal
        title={"Delete Confirmation"}
        open={deleteModal}
        footer={null}
        centered={true}
        maskClosable={false}
        keyboard={true}
        onCancel={() => setDeleteModal(false)}
      >
        <DeleteConfirmation
          setDeleteModal={setDeleteModal}
          id={edit?.key}
          fromDelete={true}
          delete={deletebadges}
          get={getbadges}
        />
      </Modal>
    </div>
  );
};

export default Badge;
