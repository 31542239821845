import {
  Button,
  Col,
  Divider,
  Image,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";
import React, { useState } from "react";
import { GoVerified } from "react-icons/go";
import { getSingleOrganization } from "../../features/organizerSlice";
import AdminSettings from "../../pages/adminSettings/AdminSettings";
import css from "../../styles/Details/userDetail.module.scss";
import DonorIssueModal from "../Layout/DonorIssueModal";
import VerifyDonor from "../Layout/VerifyDonor";
import OrganizerTab from "./OrganizerTab";

const OrganizerInformation = ({
  organizerInfo,
  id,
  from,
  organizerFollower,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [verifyModal, setVerifyModal] = useState(false);

  return (
    <div className={css.userDetailContainer}>
      <div className={css.content}>
        <div className={css.imagePart}>
          <Row
            style={{ flexDirection: "column" }}
            align={"middle"}
            gutter={[0, 16]}
          >
            <Col>
              <Image
                src={
                  organizerInfo?.data?.attributes?.avatar?.data?.attributes?.url
                }
                alt="profileImage"
                className={css.profileImage}
                width={150}
                height={150}
                // preview={false}
              />
            </Col>
            <Typography.Title level={3} style={{ marginBottom: "-1rem" }}>
              {organizerInfo?.data?.attributes?.name}
              {organizerInfo?.data?.attributes?.status === "Verified" && (
                <GoVerified style={{ color: "#296DE5", fontSize: "1.02rem" }} />
              )}
            </Typography.Title>
            <Typography.Text style={{ marginTop: "0" }}>
              {organizerInfo?.role?.name}
            </Typography.Text>
            <Col>
              <Row gutter={[8]} align={"middle"}>
                <Col>{organizerFollower?.data?.length}</Col>
                <Col>Followers</Col>
                <Divider
                  type="vertical"
                  style={{ borderWidth: "2px", height: "25px" }}
                />
                <Col>
                  {organizerInfo?.data?.attributes?.events?.data?.length}
                </Col>
                <Col>Events</Col>
              </Row>
            </Col>
            <Divider />
          </Row>

          <Row>
            <Space direction="vertical" size="middle">
              <Typography.Title level={5}>Basic Information</Typography.Title>
              <Row gutter={[8, 0]}>
                <Col>
                  <Typography.Text
                    style={{
                      color: "#808080",
                      marginBottom: "-0.5rem",
                      display: "block",
                    }}
                  >
                    Owner:{" "}
                    {
                      organizerInfo?.data?.attributes?.owner?.data?.attributes
                        ?.username
                    }
                  </Typography.Text>
                </Col>
              </Row>

              <Row gutter={[8, 0]}>
                <Col>
                  <Typography.Text
                    style={{
                      color: "#808080",
                      marginBottom: "-0.5rem",
                      display: "block",
                    }}
                  >
                    Profession:{" "}
                    {
                      organizerInfo?.data?.attributes?.owner?.data?.attributes
                        ?.occupation
                    }
                  </Typography.Text>
                </Col>
              </Row>

              <Divider />

              <Row>
                <Space direction="vertical" size="middle">
                  <Typography.Title level={5}>
                    Contact Information
                  </Typography.Title>
                  <Row>
                    <Col>
                      <Typography.Text
                        style={{
                          color: "#808080",
                          marginBottom: "-0.5rem",
                          display: "block",
                        }}
                      >
                        Email:{" "}
                        {
                          organizerInfo?.data?.attributes?.owner?.data
                            ?.attributes?.email
                        }
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row gutter={[8, 0]}>
                    <Col>
                      <Typography.Text
                        style={{
                          color: "#808080",
                          marginBottom: "-0.5rem",
                          display: "block",
                        }}
                      >
                        Phone: {organizerInfo?.data?.attributes?.phoneNumber}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row gutter={[8, 0]}>
                    <Col>
                      <Typography.Text
                        style={{
                          color: "#808080",
                          marginBottom: "-0.5rem",
                          display: "block",
                        }}
                      >
                        Location:
                        {
                          organizerInfo?.data?.attributes?.mapLocation
                            ?.place_name
                        }
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Divider />
                  <Row>
                    <Space direction="vertical" size={"large"}>
                      <Space size={"large"}>
                        {/* <Typography.Text>{info?.IdType}</Typography.Text> */}
                      </Space>

                      {/* <Space direction="horizontal" size={"large"}>
                    <Space direction="vertical">
                      <Typography.Title level={5} style={{ margin: "0" }}>
                        Back Photo
                      </Typography.Title>

                      <AttachmentTab
                        attachments={[
                          {
                            attributes: {
                              attachment: {
                                data: {
                                //   attributes: { url: info?.IdBackImage?.url },
                                },
                              },
                            },
                          },
                        ]}
                      />
                    </Space>

                    <Space direction="vertical">
                      <Typography.Title level={5} style={{ margin: "0" }}>
                        Front Photo
                      </Typography.Title>

                      <AttachmentTab
                        attachments={[
                          {
                            attributes: {
                              attachment: {
                                data: {
                                //   attributes: { url: info?.IdFrontImage?.url },
                                },
                              },
                            },
                          },
                        ]}
                      />
                    </Space>
                  </Space> */}

                      <Row justify={"start"} style={{ gap: "1rem" }}>
                        <Button
                          disabled={
                            organizerInfo?.data?.attributes?.status ===
                            "Verified"
                          }
                          type="primary"
                          danger
                          onClick={() => setIsModalOpen(true)}
                        >
                          Submit Issue
                        </Button>
                        <Button
                          disabled={
                            organizerInfo?.data?.attributes?.status ===
                            "Verified"
                          }
                          htmlType="submit"
                          type="primary"
                          onClick={() => setVerifyModal(true)}
                        >
                          {organizerInfo?.data?.attributes?.status ===
                          "Verified"
                            ? "Verified"
                            : "Verify"}
                        </Button>
                      </Row>

                      {/* TODO: modal */}

                      <Modal
                        title={"Verify Confirmation"}
                        open={verifyModal}
                        footer={null}
                        centered={true}
                        maskClosable={false}
                        keyboard={false}
                        onCancel={() => setVerifyModal(false)}
                      >
                        <VerifyDonor
                          setVerifyModal={setVerifyModal}
                          path="organizer-profiles"
                          id={id}
                          getAll={getSingleOrganization}
                          userId={
                            organizerInfo?.data?.attributes?.owner?.data?.id
                          }
                          body="Congratulations, Your organizer profile has been verified"
                          screen="OrganizerProfile"
                          paramId={organizerInfo?.data?.id}
                        />
                      </Modal>
                      <Modal
                        maskClosable={false}
                        open={isModalOpen}
                        footer={null}
                        className={css.modalSignup}
                        centered={true}
                        keyboard={true}
                        closable={true}
                        onCancel={() => setIsModalOpen(false)}
                      >
                        <DonorIssueModal
                          setIsModalOpen={setIsModalOpen}
                          Id={id}
                          path="organizer-profiles"
                          getDetail={getSingleOrganization}
                          message={
                            organizerInfo?.data?.attributes?.verificationNotes
                              ?.message
                          }
                          userId={
                            organizerInfo?.data?.attributes?.owner?.data?.id
                          }
                          body="Kindly revise your organizer profile and reapply for consideration."
                          screen="OrganizerProfile"
                          paramId={organizerInfo?.data?.id}
                        />
                      </Modal>
                    </Space>
                  </Row>
                </Space>
              </Row>
            </Space>
          </Row>
        </div>

        <div className={css.descriptionPart}>
          {from === "admin" ? (
            <AdminSettings />
          ) : (
            <OrganizerTab
              userId={organizerInfo?.data?.id}
              organizerInfo={organizerInfo}
              follower={organizerFollower}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OrganizerInformation;
