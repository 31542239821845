import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../Api";
import { BASEURL } from "../Url";
import Swal from "sweetalert2";
import moment from "moment";
import { getOrganizerFollower } from "./organizerSlice";

const qs = require("qs");
const initialState = {
  events: [],
  loading: false,
  error: "",
  singleEvent: "",
  eventJoin: "",
};

export const getEvents = createAsyncThunk(
  "getEvents",
  async ({ params, filter }, thunkApi) => {
    try {
      const fromDate = moment.utc(filter?.fromDate).toISOString();
      const toDate = moment.utc(filter?.toDate).toISOString();

      const query = qs.stringify({
        // pagination: params,
        filters: {
          // name: filter?.name,
          // oraganizer: filter?.oraganizer,
          // location: filter?.location,
          // eventDateTime: filter?.eventData,
          status: filter?.status,

          ...(filter?.fromDate && filter?.toDate
            ? {
                eventDateTime: {
                  $between: [fromDate, toDate],
                },
              }
            : {}),

          noOfParticipants: {
            $between: filter?.noOfParticipants, //[12,50]
          },

          // status: filter?.status,
        },
        populate: ["organizer", "featureImage"],
        sort: ["updatedAt:desc"],
      });
      const res = await api.get(`${BASEURL}/events?${query}`);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.error.message);
    }
  }
);

export const getSingleEvent = createAsyncThunk(
  "getSingleEvents",
  async (id, thunkApi) => {
    try {
      const query = qs.stringify({
        populate: [
          "featureImage",
          "organizer.owner",
          "eventJoiners.user.currentAddress",
          "eventJoiners.user.avatar",
        ],
        sort: ["updatedAt:desc"],
      });
      const res = await api.get(`${BASEURL}/events/${id}?${query}`);
      thunkApi.dispatch(
        getOrganizerFollower(res.data.data?.attributes?.organizer?.data?.id)
      );
      return res.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.error.message);
    }
  }
);

// TODO:get event joiner
export const eventJoiners = createAsyncThunk(
  "eventJoiners",
  async (id, thunkApi) => {
    try {
      const query = qs.stringify({
        filters: {
          event: {
            id,
          },
        },

        populate: ["user", "event"],
      });
      const res = await api.get(`${BASEURL}/event-joiners/?${query}`);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.error.message);
    }
  }
);

const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {},
  extraReducers: {
    [getEvents.pending]: (state) => {
      state.loading = true;
    },
    [getEvents.fulfilled]: (state, action) => {
      state.loading = false;
      state.events = action.payload;
    },
    [getEvents.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    },
    [getSingleEvent.pending]: (state) => {
      state.loading = true;
    },
    [getSingleEvent.fulfilled]: (state, action) => {
      state.loading = false;
      state.singleEvent = action.payload;
    },
    [getSingleEvent.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    },
    [eventJoiners.pending]: (state) => {
      state.loading = true;
    },
    [eventJoiners.fulfilled]: (state, action) => {
      state.loading = false;
      state.eventJoin = action.payload;
    },
    [eventJoiners.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.payload,
        confirmButtonColor: "#B00020",
      });
    },
  },
});

export default eventSlice.reducer;
