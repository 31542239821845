import { AiOutlineSetting, AiOutlineUser } from "react-icons/ai";
import { VscFeedback } from "react-icons/vsc";
import {
  MdOutlineReviews,
  MdOutlineDashboardCustomize,
  MdOutlineBloodtype,
} from "react-icons/md";
import { IoMdAnalytics } from "react-icons/io";

import {
  BiBadge,
  BiDonateBlood,
  BiHistory,
  BiLogOut,
  BiLogOutCircle,
} from "react-icons/bi";
import { TfiWrite } from "react-icons/tfi";
import { FaHandshake, FaQuestionCircle } from "react-icons/fa";
import { GiKnightBanner } from "react-icons/gi";

import {
  UserOutlined,
  HistoryOutlined,
  ProfileOutlined,
  TeamOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import { RiPagesLine } from "react-icons/ri";

function getItem(label, key, icon, children, type) {
  return {
    label,
    key,
    icon,
    children,
    type,
  };
}

export const ModeratorOptions = [
  getItem(
    "ANALYTICS",
    "g1",
    <IoMdAnalytics style={{ fontSize: "1.2rem" }} />,

    [getItem("Dashboard", "home", <MdOutlineDashboardCustomize />)]
  ),
  getItem(
    "USERS",
    "g2",
    <UserOutlined style={{ fontSize: "1.2rem" }} />,
    [
      getItem(
        "Users",
        "users",
        <UserOutlined style={{ fontSize: "1.1rem" }} />
      ),
      getItem(
        "Donor List",
        "donor-list",
        <BiDonateBlood style={{ fontSize: "1.1rem" }} />
      ),
    ]
    // "group"
  ),
  {
    type: "divider",
  },

  getItem(
    "BLOOD NEEDS",
    "g4",
    // null,
    <MdOutlineBloodtype style={{ fontSize: "1.3rem" }} />,

    [
      getItem(
        "Blood Needs",
        "blood-needs",
        <MdOutlineBloodtype style={{ fontSize: "1.1rem" }} />
      ),
    ]
    // "group"
  ),
  {
    type: "divider",
  },

  getItem(
    "PARTNERSHIP",
    "g5",
    // null,
    <FaHandshake style={{ fontSize: "1.2rem" }} />,

    [
      getItem(
        "Partners",
        "partners",
        <FaHandshake style={{ fontSize: "1.1rem" }} />
      ),
    ]
    // "group"
  ),

  { type: "divider" },

  getItem("CONTENT", "g6", <ProfileOutlined style={{ fontSize: "1.2rem" }} />, [
    getItem("Blogs", "blogs", <ProfileOutlined />),
    getItem(
      "Banner",
      "banner",
      <GiKnightBanner style={{ fontSize: "1.1rem" }} />
    ),
    getItem("Page", "page", <RiPagesLine style={{ fontSize: "1.1rem" }} />),
    getItem(
      "Testimonial",
      "testimonial",
      <TfiWrite style={{ fontSize: "1.1rem" }} />
    ),

    getItem("FAQ", "faq", <FaQuestionCircle style={{ fontSize: "1.1rem" }} />),
  ]),

  {
    type: "divider",
  },

  getItem(
    "LOGOUT",
    "logout",
    <BiLogOutCircle style={{ fontSize: "1.2rem" }} />
  ),

  getItem("", ""),
];
