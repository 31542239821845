import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getRoles } from "../../features/roleSlice";
import { postUsers } from "../../features/userSlice";
import css from "../../styles/addModal.module.scss";

const AddUsers = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  const roleData = useSelector((state) => state.role.roles);

  const [imgSrc, setImgSrc] = useState("");

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    props.setIsModalOpen(false);

    dispatch(postUsers(data));
    reset();
    setImgSrc("");
  };

  const handleCancel = () => {
    clearErrors();
    setImgSrc("");
    props.setIsModalOpen(false);
  };
  return (
    <form className={css.Container} onSubmit={handleSubmit(onSubmit)}>
      <Row className={css.title}>Add New User</Row>
      <Row gutter={[32, 10]} justify="space-between">
        <Col span={12}>
          <Row gutter={[10, 10]}>
            <Col span={24} className={css.subTitle}>
              Full Name
            </Col>
            <Col span={24}>
              <input
                type="text"
                {...register("username", {
                  required: "This field is required",
                })}
              />
              {<span className={css.error}> {errors?.username?.message}</span>}
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={[10, 10]}>
            <Col span={24} className={css.subTitle}>
              Email
            </Col>
            <Col span={24}>
              <input
                type={"email"}
                {...register("email", {
                  required: "This field is required",
                })}
              />
              {<span className={css.error}> {errors?.email?.message}</span>}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[32, 10]} justify="space-between">
        <Col span={12}>
          <Row gutter={[10, 10]}>
            <div className={css.eachQnDiv}>
              <label htmlFor="confirmed" className={css.subTitle}>
                Confirmed
              </label>
              <input
                type="checkbox"
                id="confirmed"
                {...register("confirmed")}
              />
            </div>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={[32, 10]}>
            <div className={css.eachQnDiv}>
              <label htmlFor="blocked" className={css.subTitle}>
                Blocked
              </label>
              <input type="checkbox" id="blocked" {...register("blocked")} />
            </div>
          </Row>
        </Col>
      </Row>
      <Row gutter={[32, 10]} justify="space-between">
        <Col span={12}>
          <Row gutter={[10, 10]}>
            <Col span={24} className={css.subTitle}>
              Role
            </Col>
            <Col span={24}>
              <select
                id="role"
                {...register("role", { required: "This field is required" })}
              >
                <option selected value={""} disabled>
                  ---Select role---
                </option>
                {roleData?.roles?.map((role) => {
                  return (
                    <option value={role?.id} key={role.id}>
                      {role?.name}
                    </option>
                  );
                })}
              </select>
              {<span className={css.error}> {errors?.role?.message}</span>}
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={[10, 10]}>
            <Col span={24} className={css.subTitle}>
              Password
            </Col>
            <Col span={24}>
              <input
                type="password"
                {...register("password", {
                  required: "This field is required",
                  minLength: {
                    value: 6,
                    message: "Minimum 6 character is required",
                  },
                })}
              />
              {<span className={css.error}> {errors?.password?.message}</span>}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify={"end"} style={{ gap: "1rem" }}>
        <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
          Cancel
        </Button>
        <Button htmlType="submit" type="primary">
          Add
        </Button>
      </Row>
    </form>
  );
};

export default AddUsers;
