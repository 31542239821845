import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../Api";
import { getFCM } from "../components/SendPushNotification";
import { BASEURL } from "../Url";
import { postNotification } from "./notificationSlice";
const qs = require("qs");

const initialState = { loading: false, badges: [] };

export const getBadges = createAsyncThunk("getBadges", async (_, thunkApi) => {
  // const query = qs.stringify({
  //     populate: ['image', 'users'],
  //     sort: ['bonusRewards:asc']
  // });
  try {
    const res = await api.get(`${BASEURL}/badges`);
    // console.log(res.data.data, 'badges')
    return res.data.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data.error.message);
  }
});

// TODO:send reward

export const sendReward = createAsyncThunk(
  "sendReward",
  async ({ badgeId, userId, reason, rewardPoint, body, screen }, thunkApi) => {
    try {
      const res = await api.put(
        `${BASEURL}/users-permissions/users/${userId}/addBadgeAndRewards`,
        {
          badge: badgeId,
          reason,
          rewardPoint,
        }
      );

      thunkApi.dispatch(
        postNotification({
          title: "Congratulations!!!",
          description: `${body}.`,
          toUser: userId,
          isRead: false,
          metaData: {
            description: `${body}`,
            type: "reward",
          },
        })
      );

      getFCM(userId, "Congratulations!!!", body, screen);

      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.error.message);
    }
  }
);

const RewardSlice = createSlice({
  name: "reward",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(sendReward.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(sendReward.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(sendReward.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getBadges.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getBadges.fulfilled, (state, action) => {
      state.loading = false;
      state.badges = action.payload;
    });
    builder.addCase(getBadges.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default RewardSlice.reducer;
