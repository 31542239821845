import { Button, Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addFaq, getFaq } from "../../features/faqSlice";
import css from "../../styles/addModal.module.scss";

const Filter = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onSubmit = (data) => {
    // TODO: sending only data which needs to be filtered
    const filterData = {};
    for (let index = 0; index < Object.keys(data).length; index++) {
      if (data[Object.keys(data)[index]] !== undefined) {
        filterData[Object.keys(data)[index]] = data[Object.keys(data)[index]];
      }
    }
props.setFilteredData(filterData)
    dispatch(
      getFaq({
        params: {
          page: 1,
          pageSize: 8,
        },
        filter: filterData,
      })
    );

    form.resetFields();
    props.setFilterModal(false);
  };

  const handleCancel = () => {
    form.resetFields();
    props.setFilterModal(false);
  };

  return (
    <div className={css.Container}>
      <Row className={css.title}>Filter</Row>
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Row gutter={[32, 0]} justify="space-between">
          {/* <Col span={12}>
            <Form.Item label="Question" name={"question"}>
              <Input />
            </Form.Item>
          </Col> */}
          <Col span={24}>
            <Form.Item
              label="Category"
              name="type"
              rules={[
                {
                  message: "Category is required",
                },
              ]}
            >
              <Select
                options={[
                  {
                    label: "donor",
                    value: "donor",
                  },
                  {
                    label: "partner",
                    value: "partner",
                  },
                  {
                    label: "suggestion",
                    value: "suggestion",
                  },
                  {
                    label: "volunteering",
                    value: "volunteering",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item label="Answer" name={"answer"}>
              <Input />
            </Form.Item>
          </Col> */}
        </Row>

        <Row justify={"end"} style={{ gap: "1rem" }}>
          <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary">
            Filter
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default Filter;
