import { Col, Dropdown, Row } from "antd";
import React from "react";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { VscActivateBreakpoints } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import css from "../../styles/Testimonials/testimonial.module.scss";

const EditDots = (props) => {
  const navigate = useNavigate();

  const settings = [
    {
      label: (
        <>
          {props.from !== "bloodNeeds" && props.from !== "users" && (
            <Row
              gutter={16}
              align={"middle"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (
                  props.from === "bloodNeeds" ||
                  props.from === "donorList" ||
                  props.from === "blogs" ||
                  props.from === "page"
                ) {
                  navigate(`${props.link}/${props?.row?.id}`);
                } else {
                  props.setEditModal(true);
                  props.setEdit({ ...props?.edit, key: props?.row?.id });
                }
              }}
            >
              <Col>
                <BiEdit className={css.settingIcons} />
              </Col>
              <Col>
                <span className={css.settingOption}>Edit</span>
              </Col>
            </Row>
          )}
          {/* <Divider type="horizontal" /> */}
        </>
      ),
      key: "0",
    },
    {
      label: (
        <>
          <Row
            gutter={16}
            align={"middle"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.setEdit({ ...props.edit, key: props?.row?.id });

              if (props.setDeleteModal) {
                props.setDeleteModal(true);
              } else {
                !props.deactivate
                  ? props.setDeactivateModal(true)
                  : props.setActivateModal(true);
              }
            }}
          >
            {props.changePw ? (
              <>
                <Col>
                  <VscActivateBreakpoints className={css.settingIcons} />
                </Col>
                <Col>
                  {!props.deactivate ? (
                    <span className={css.settingOption}>Deactivate</span>
                  ) : (
                    <span className={css.settingOption}>Activate</span>
                  )}
                </Col>
              </>
            ) : (
              <>
                <Col>
                  <RiDeleteBinLine className={css.settingIcons} />
                </Col>
                <Col>
                  <span className={css.settingOption}>Delete</span>
                </Col>
              </>
            )}
          </Row>
          {/* <Divider type="horizontal" /> */}
        </>
      ),
      key: "1",
    },

    // props?.changePw && {
    //   label: (
    //     <>
    //       <Row
    //         gutter={16}
    //         align={"middle"}
    //         style={{ cursor: "pointer" }}
    //         onClick={() => {
    //           props.setEdit({ ...props.edit, key: props?.row?.id });
    //           props.setDeleteModal(true);
    //         }}
    //       >
    //         <Col>
    //           <RiDeleteBinLine className={css.settingIcons} />
    //         </Col>
    //         <Col>
    //           <span className={css.settingOption}>Change Password</span>
    //         </Col>
    //       </Row>
    //     </>
    //   ),
    //   key: "2",
    // },

    props?.changePw && {
      label: (
        <>
          <Row
            gutter={16}
            align={"middle"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.setEdit({ ...props.edit, key: props?.row?.id });
              props.setDeleteModal(true);
            }}
          >
            <Col>
              <RiDeleteBinLine className={css.settingIcons} />
            </Col>
            <Col>
              <span className={css.settingOption}>Delete</span>
            </Col>
          </Row>
        </>
      ),
      key: "2",
    },
  ];

  return (
    <div>
      <Dropdown
        // trigger={["click"]}
        menu={{ items: settings }}
        placement={"bottomRight"}
      >
        <img src={props.icon} alt="edit" className={css.editIcon} />
      </Dropdown>
    </div>
  );
};

export default EditDots;
