import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TopBar from "../../components/Layout/TopBar";
import { useEffect } from "react";
import moment from "moment";
import Loading from "../../components/common/Loading";
import { getBadgeWinners, getbadgesById } from "../../features/badgeSlice";
import BadgeInformation from "../../components/badgeDetail/BadgeInformation";

const BadgeDetail = () => {
  const { badgeId } = useParams();
  const dispatch = useDispatch();



  useEffect(() => {
    dispatch(getbadgesById(badgeId));

  }, [dispatch, badgeId]);




  let badgeData = useSelector((state) => state.badge.singleBadge);
  const loading = useSelector((state) => state.badge.loading);

  return (
    <div>
      <TopBar topic="Badge Detail" />
      {loading ? <Loading /> : <BadgeInformation info={badgeData} badgeId={badgeId} />}
    </div>
  );
};

export default BadgeDetail;
