import { Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useDispatch } from "react-redux";
import { api } from "../../Api";
import { BASEURL } from "../../Url";
import { useNavigate } from "react-router-dom";

const qs = require("qs");

const DonationTab = ({ userId, donationInfo }) => {
  const [info, setInfo] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const getDonationInfo = async () => {
      setLoading(true);
      const query = qs.stringify({
        populate: ["acceptor", "bloodRequest.requestedBy"],
        filters: {
          acceptor: {
            id: userId,
          },
          status: "Donated",
        },
      });
      const res = await api.get(`${BASEURL}/b-request-acceptors?${query}`);
      setLoading(false);
      setInfo(res.data);
    };
    if (donationInfo) {
      setInfo(donationInfo);
    } else {
      getDonationInfo();
    }
  }, [dispatch, userId]);

  const dataSource = info?.data?.map((f) => ({
    id: f?.id,
    userId:
      f?.attributes?.bloodRequest?.data?.attributes?.requestedBy?.data?.id,
    needId: f?.attributes?.bloodRequest?.data?.id,
    requestedBy:
      f?.attributes?.bloodRequest?.data?.attributes?.requestedBy?.data
        ?.attributes?.username,
    units: f?.attributes?.bloodRequest?.data?.attributes?.noOfUnits,
    hospital: f?.attributes?.bloodRequest?.data?.attributes?.hospital,
    requestedAt: f?.attributes?.requestedAt
      ? moment.utc(f?.attributes?.requestedAt).format("YYYY-MM-DD")
      : "N/A",
    donatedAt: f?.attributes?.donatedAt
      ? moment.utc(f?.attributes?.donatedAt).format("YYYY-MM-DD")
      : "N/A",
    acceptedAt: f?.attributes?.acceptedAt
      ? moment.utc(f?.attributes?.acceptedAt).format("YYYY-MM-DD")
      : "N/A",
    cancelledAt: f?.attributes?.cancelledAt
      ? moment.utc(f?.attributes?.cancelledAt).format("YYYY-MM-DD")
      : "N/A",
  }));

  const columns = [
    {
      title: "Requested By",
      // dataIndex: "requestedBy",
      render: (row) => {
        return (
          <p onClick={() => navigate(`/dashboard/blood-needs/${row?.needId}`)}>
            {row?.requestedBy}
          </p>
        );
      },
      // key: "requestedBy",
    },
    {
      title: "Units",
      dataIndex: "units",
      key: "units",
    },
    {
      title: "Hospital",
      dataIndex: "hospital",
      key: "hospital",
    },
    {
      title: "Cancelled At",
      dataIndex: "cancelledAt",
      key: "cancelledAt",
    },
    {
      title: "Requested Date",
      dataIndex: "requestedAt",
      key: "requestedAt",
    },
    {
      title: "Accepted Date",
      dataIndex: "acceptedAt",
      key: "acceptedAt",
    },
    {
      title: "Donated Date",
      dataIndex: "donatedAt",
      key: "donatedAt",
    },
  ];

  return (
    <div>
      <Table
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        bordered
        pagination={{
          responsive: true,
          nextIcon: <GrLinkNext />,
          prevIcon: <GrLinkPrevious />,
          total: info?.data?.length,
          pageSize: 8,
        }}
      />
    </div>
  );
};

export default DonationTab;
