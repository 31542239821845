import {
  AutoComplete,
  Avatar,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Row,
  Select,
  Typography,
  Upload,
} from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import css from "../../styles/addModal.module.scss";
import addCamera from "../../Images/addCamera.svg";
import { useDispatch, useSelector } from "react-redux";
import { postBlog } from "../../features/blogSlice";
import JoditEditor from "jodit-react";
import { BASEURL } from "../../Url";
import { api } from "../../Api";
import TopBar from "../../components/Layout/TopBar";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";
import noImg from "../../Images/Users/userProfile.png";
import ShowFile from "../../components/showFile/ShowFile";

const { Option } = Select;

const AddBlog = () => {
  // TODO: for editor
  const editor = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [detail, setDetail] = useState(null);
  const [formInstance] = Form.useForm();

  const config = useMemo(
    () => ({
      uploader: { insertImageAsBase64URI: true },
      showXPathInStatusbar: false,
      showCharsCounter: false,
      showWordsCounter: false,
      toolbarAdaptive: false,
    }),
    []
  );

  const [slug, setSlug] = useState();

  const [searchUser, setSearchUser] = useState("");

  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const filterUser = async () => {
      const res = await api.get(
        `${BASEURL}/users/?filters[username][$containsi]=${searchUser}`
      );
      const options = res.data?.map((item) =>
      ({
        value: item?.id,
        label: (
          <Row gutter={[10, 10]} align="middle">
            <Col>
              <Avatar src={<Image src={item?.avatar?.url || noImg} />} />
            </Col>
            <Col>{item?.username}</Col>(<Col>{item?.email}</Col>)
          </Row>
        ),
      }));

      setUserList(options);
    };
    if (searchUser.length > 1) {
      filterUser();
    } else {
      setUserList([]);
    }
  }, [dispatch, searchUser]);

  const [imgSrc, setImgSrc] = useState("");
  const [imageError, setImageError] = useState(null)
  const [verified, setVerified] = useState(true);
  const [pin, setPin] = useState(false);

  const onSubmit = (data) => {
    let valid = true;
    if (imgSrc === "") {
      valid = false;
      setImageError("Please Provide image !!")
    }
    if (valid) {
      const newData = {
        ...data,
        slug: slugify(data.title, { lower: true }),
        verified: verified,
        pin: pin,
      };
      const formData = new FormData();

      if (imgSrc !== "") {
        formData.append("files.attachment", imgSrc, imgSrc.name);
      }
      formData.append("data", JSON.stringify(newData));

      dispatch(postBlog(formData));
      setImgSrc("");
      navigate(-1)
    }
  };
  const handleCancel = () => {
    navigate(-1);
    setImgSrc("");
  };

  const loading = useSelector((state) => state.blog.loading);
  return (
    <div>
      <TopBar topic="Add Blog" />
      <div className={css.editContent}>
        <Form layout="vertical" form={formInstance} onFinish={onSubmit}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label="User"
                name="user"
                rules={[
                  {
                    required: true,
                    message: "User is required",
                  },
                ]}
              >
                <AutoComplete
                  options={userList}
                  onSearch={(value) => setSearchUser(value)}
                  placeholder="Search User's Name"
                >
                  {userList?.map((user, i) => {
                    return (
                      <AutoComplete.Option>{user?.label}</AutoComplete.Option>
                    );
                  })}
                </AutoComplete>
              </Form.Item>
            </Col>

            <Col xs={24} md={12} xl={6}>
              <Form.Item
                label="Category"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "category is required",
                  },
                ]}
              >
                <Select
                  options={[
                    {
                      label: "Blog",
                      value: "blog",
                    },
                    {
                      label: "Story",
                      value: "story",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            {/* <Col xs={24} md={12} xl={3}>
              <Form.Item label="Verified" name="verified">
                <Checkbox
                  checked={verified}
                  onChange={() => setVerified(!verified)}
                />
              </Form.Item>
            </Col> */}
            <Col xs={24} md={12} xl={3}>
              <Form.Item label="Pin" name="pin">
                <Checkbox checked={pin} onChange={() => setPin(!pin)} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Blog Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Title is required",
                  },
                ]}
              >
                <Input className={css.input} />
              </Form.Item>
            </Col>

            <Divider />
            <Row justify={"center"}>
              <Row style={{ marginRight: "8px" }}>
                {imgSrc !== "" && <ShowFile imgSrc={imgSrc} />}
              </Row>
              <Col>
                <Upload
                  showUploadList={true}
                  listType="picture-card"
                  accept=".jpeg, .png"
                  beforeUpload={(file) => {
                    setImgSrc({ file }.file);
                    return false;
                  }}
                  onRemove={() => setImgSrc("")}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ display: "flex", gap: "0.5rem" }}>
                      <div className={css.uploadImgDiv}>
                        <img src={addCamera} alt="addCamera" />
                      </div>
                      <span className={css.uploadBtn}>Attachment Upload</span>
                    </div>
                    <span className={css.uploadText}>Upload Picture</span>
                    <span className={css.uploadText}>
                      Only in jpg and png format.
                    </span>
                  </div>
                </Upload>
                <Typography.Text style={{ color: "red" }}>{imageError}</Typography.Text>
              </Col>
            </Row>
            <Divider />

            <Col span={24}>
              <Form.Item label="Blog Description" name="detail">
                <JoditEditor
                  className={css.editor}
                  ref={editor}
                  value={detail}
                  config={config}
                  onChange={(newContent) => setDetail(newContent)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={"end"} style={{ gap: "1rem" }}>
            <Button
              type="primary"
              danger
              htmlType="reset"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button htmlType="submit" type="primary" loading={loading}>
              Add
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default AddBlog;
