import { Button, Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { getUsers } from "../../features/userSlice";
import css from "../../styles/addModal.module.scss";

const Filter = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onSubmit = (data) => {
    // TODO: sending only data which needs to be filtered
    const filterData = {};
    for (let index = 0; index < Object.keys(data).length; index++) {
      if (data[Object.keys(data)[index]] !== undefined) {
        filterData[Object.keys(data)[index]] = data[Object.keys(data)[index]];
      }
    }
    props.setFilterData(filterData);


    dispatch(
      getUsers({
        params: {
          page: 1,
          pageSize: 8,
        },
        filter: filterData,
      })
    );
    props.setFilterModal(false);
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    props.setFilterModal(false);
  };

  return (
    <div className={css.Container}>
      <Row className={css.title}>Filter</Row>
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Row gutter={[32, 0]} justify="space-between">
          {/* <Col span={12}>
            <Form.Item name={"username"} label="Full Name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="City" name={"location"}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Age" name={"age"}>
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Email" name={"email"}>
              <Input type="email" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Phone" name={"phone"}>
              <Input />
            </Form.Item>
          </Col> */}

          <Col span={12}>
            <Form.Item label="Gender" name={"gender"}>
              <Select
                options={[
                  {
                    label: "Male",
                    value: "Male",
                  },
                  {
                    label: "Female",
                    value: "Female",
                  },
                  {
                    label: "Others",
                    value: "Other",
                  },
                ]}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Blood Type" name={"bloodType"}>
              <Select
                options={[
                  {
                    label: "A+",
                    value: "A+",
                  },
                  {
                    label: "B+",
                    value: "B+",
                  },
                  {
                    label: "AB+",
                    value: "AB+",
                  },
                  {
                    label: "O+",
                    value: "O+",
                  },
                  {
                    label: "A-",
                    value: "A-",
                  },
                  {
                    label: "B-",
                    value: "B-",
                  },
                  {
                    label: "AB-",
                    value: "AB-",
                  },
                  {
                    label: "O-",
                    value: "O-",
                  },
                ]}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Phone Verified" name={"phoneVerified"}>
              <Select
                options={[
                  {
                    label: "Verified",
                    value: true,
                  },
                  {
                    label: "UnVerified",
                    value: false,
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Email Verified" name={"emailVerified"}>
              <Select
                options={[
                  {
                    label: "Verified",
                    value: true,
                  },
                  {
                    label: "UnVerified",
                    value: false,
                  },
                ]}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Suspension" name={"blocked"}>
              <Select
                options={[
                  {
                    label: "Suspended",
                    value: true,
                  },
                  {
                    label: "NonSuspended",
                    value: false,
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"end"} style={{ gap: "1rem" }}>
          <Button type="primary" danger htmlType="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary">
            Filter
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default Filter;
