import { Button, Divider, Form, Modal, Row, Space, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import VerifyDonor from "../Layout/VerifyDonor";
import css from "../../styles/Users/users.module.scss";
import DonorIssueModal from "../Layout/DonorIssueModal";
import { getDonorDetail } from "../../features/donorListSlice";

const DonorForm = ({ donorData, userSettings }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [verifyModal, setVerifyModal] = useState(false);
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  return (
    <>
      <Space>
        <Space direction="vertical">
          <Typography.Title level={4}>Information</Typography.Title>
          <Space size={"large"}>
            <Typography.Title level={5} style={{ margin: "0" }}>
              Blood Group:
            </Typography.Title>
            {donorData?.attributes?.bloodGroup?.data?.attributes?.name}
          </Space>

          <Space size={"large"}>
            <Typography.Title level={5} style={{ margin: "0" }}>
              Weight:
            </Typography.Title>
            {`${donorData?.attributes?.weight} kg`}
          </Space>

          <Space size={"large"}>
            <Typography.Title level={5} style={{ margin: "0" }}>
              DOB:
            </Typography.Title>
            {moment.utc(donorData?.attributes?.dob).format("YYYY-MM-DD")}
          </Space>

          <Space size={"large"}>
            <Typography.Title level={5} style={{ margin: "0" }}>
              Gender:
            </Typography.Title>
            {donorData?.attributes?.gender}
          </Space>

          <Space size={"large"}>
            <Typography.Title level={5} style={{ margin: "0" }}>
              Profession:
            </Typography.Title>
            {donorData?.attributes?.occupation}
          </Space>
        </Space>

        <Space direction="vertical">
          {/* <Typography.Title level={4}>
                        Attachments
                    </Typography.Title> */}
          {/* <AttachmentTab attachments={donorData?.attributes?.attachments?.data} /> */}
        </Space>
      </Space>
      <Divider />

      <Typography.Title level={4}>Questionares</Typography.Title>
      <Divider />
      <Space direction="vertical">
        <Space size={"large"}>
          <Typography.Title level={5} style={{ margin: "0" }}>
            Donated blood before:
          </Typography.Title>
          {donorData?.attributes?.questionares?.donatedBloodBefore ? (
            <Typography.Text>Yes</Typography.Text>
          ) : (
            <Typography.Text>No</Typography.Text>
          )}
        </Space>

        <Space size={"large"}>
          <Typography.Title level={5} style={{ margin: "0" }}>
            Ever received blood:
          </Typography.Title>
          {donorData?.attributes?.questionares?.everReceivedBlood ? (
            <Typography.Text>Yes</Typography.Text>
          ) : (
            <Typography.Text>No</Typography.Text>
          )}
        </Space>

        <Space size={"large"}>
          <Typography.Title level={5} style={{ margin: "0" }}>
            Had vaccination last 3 month:
          </Typography.Title>
          {donorData?.attributes?.questionares?.hadVaccinationLast3Month ? (
            <Typography.Text>Yes</Typography.Text>
          ) : (
            <Typography.Text>No</Typography.Text>
          )}
        </Space>

        <Space size={"large"}>
          <Typography.Title level={5} style={{ margin: "0" }}>
            Receive direct call:
          </Typography.Title>
          {donorData?.attributes?.questionares?.receiveDirectCall ? (
            <Typography.Text>Yes</Typography.Text>
          ) : (
            <Typography.Text>No</Typography.Text>
          )}
        </Space>

        <Space size={"large"}>
          <Typography.Title level={5} style={{ margin: "0" }}>
            Receive direct message:
          </Typography.Title>
          {donorData?.attributes?.questionares?.receiveDirectMessage ? (
            <Typography.Text>Yes</Typography.Text>
          ) : (
            <Typography.Text>No</Typography.Text>
          )}
        </Space>

        <Space size={"large"}>
          <Typography.Title level={5} style={{ margin: "0" }}>
            Victim of Any Disease:
          </Typography.Title>
          {donorData?.attributes?.questionares?.victimOfAnyDisease ? (
            <Typography.Text>Yes</Typography.Text>
          ) : (
            <Typography.Text>No</Typography.Text>
          )}
        </Space>
        <Space size={"large"}>
          <Typography.Title level={5} style={{ margin: "0" }}>
            Last Donated Date:
          </Typography.Title>
          <Typography.Text>
            {donorData?.attributes?.questionares?.lastDonatedDate
              ? moment
                  .utc(donorData?.attributes?.questionares?.lastDonatedDate)
                  .format("YYYY-MM-DD")
              : "N/A"}
          </Typography.Text>
        </Space>
      </Space>

      <Row justify={"start"} style={{ gap: "1rem", marginTop: "20px" }}>
        <Button
          disabled={donorData?.attributes?.status === "Verified"}
          type="primary"
          danger
          onClick={() => setIsModalOpen(true)}
        >
          Submit Issue
        </Button>
        <Button
          disabled={donorData?.attributes?.status === "Verified"}
          htmlType="submit"
          type="primary"
          onClick={() => setVerifyModal(true)}
        >
          {donorData?.attributes?.status === "Verified" ? "Verified" : "Verify"}
        </Button>
      </Row>
      {/* TODO: modal */}
      <Modal
        title={"Verify Confirmation"}
        open={verifyModal}
        footer={null}
        centered={true}
        maskClosable={false}
        keyboard={false}
        onCancel={() => setVerifyModal(false)}
      >
        <VerifyDonor
          path="donor-profiles"
          setVerifyModal={setVerifyModal}
          id={donorData?.id}
          getAll={getDonorDetail}
          userId={donorData?.attributes?.user?.data?.id}
          body="Congratulations, your donor profile has been verified."
          screen="ViewDonorProfile"
          donorProfile={true}
          refId={
            donorData?.attributes?.user?.data?.attributes?.referBy?.data?.id
          }
          userSettings={userSettings}
        />
      </Modal>

      <Modal
        maskClosable={false}
        open={isModalOpen}
        footer={null}
        className={css.modalSignup}
        centered={true}
        keyboard={true}
        closable={true}
        onCancel={handleCancel}
      >
        <DonorIssueModal
          setIsModalOpen={setIsModalOpen}
          Id={donorData?.id}
          path="donor-profiles"
          message={donorData?.attributes?.verificationNotes?.message}
          getDetail={getDonorDetail}
          userId={donorData?.attributes?.user?.data?.id}
          body="Kindly revise your donor profile and reapply for consideration."
          screen="editDonorProfile"
          paramId={donorData?.id}
        />
      </Modal>
    </>
  );
};

export default DonorForm;
