import React from "react";
import css from "../../styles/AddCategory/addCategory.module.scss";

const AddCategory = () => {
  return <div className={css.addCategoryContainer}>
    <span className={css.title}>Category Name</span>
    <input type="text" />
  </div>;
};

export default AddCategory;
