import React from "react";
import { Route, Routes } from "react-router-dom";
import Categories from "../components/Layout/Categories";
import Role from "../pages/Admin/Role/Role";
import Team from "../pages/Admin/Team";
import AdminProfile from "../pages/adminSettings/AdminProfile";
import Badge from "../pages/Badge/Badge";
import Banner from "../pages/Banner/Banner";
import Blogs from "../pages/Blog/Blog";
import BloodNeedDetail from "../pages/BloodNeeds/BloodNeedDetail";
import BloodNeeds from "../pages/BloodNeeds/BloodNeeds";
import Edit from "../pages/BloodNeeds/Edit";
import EditDonor from "../pages/DonorList/EditDonor";
import Dashboard from "../pages/Dashboard/Dashboard";
import DonorDetail from "../pages/DonorList/DonorDetail";
import DonorList from "../pages/DonorList/DonorList";
import Faq from "../pages/Faq/Faq";
import Feedback from "../pages/Feedback/Feedback";
import History from "../pages/History/History";
import HistoryDetail from "../pages/History/HistoryDetail";
import Partners from "../pages/Partners/Partners";
import Reviews from "../pages/Reviews/Reviews";
import Testimonials from "../pages/Testimonials/Testimonials";
import UserDetail from "../pages/Users/UserDetail";
import Users from "../pages/Users/Users";
import EditBlog from "../pages/Blog/EditBlog";
import AddBlog from "../pages/Blog/AddBlog";
import BadgeDetail from "../pages/Badge/BadgeDetail";
import FeedbackDetail from "../pages/Feedback/FeedbackDetail";
import Page from "../pages/page/Page";
import Website from "../pages/Admin/Website";
import NotificationSettings from "../pages/Admin/NotificationSettings";
import EditPage from "../pages/page/EditPage";
import AddPage from "../pages/page/AddPage";

const ModeratorRoutes = () => {
  return (
    <Routes>
      <Route path="home" element={<Dashboard />} />
      <Route path="users" element={<Users />} />
      {/* <Route path="History" element={<History />} /> */}
      <Route path="blood-needs" element={<BloodNeeds />} />
      <Route path="donor-list" element={<DonorList />} />
      <Route path="partners" element={<Partners />} />
      <Route path="testimonial" element={<Testimonials />} />
      <Route path="banner" element={<Banner />} />
      <Route path="blogs" element={<Blogs />} />
      <Route path="badge" element={<Badge />} />
      <Route path="faq" element={<Faq />} />
      <Route path="page" element={<Page />} />
      <Route
        path="/partners/category"
        element={<Categories topic="Partner Categories" prevPath="Partners" />}
      />
      <Route
        path="blogs/category"
        element={<Categories topic="Blog Categories" prevPath="Blogs" />}
      />
      <Route
        path="faq/category"
        element={<Categories topic="FAQ Categories" prevPath="FAQ" />}
      />

      {/* <Route path="history/:historyId" element={<HistoryDetail />} />
      <Route path="users/:userId" element={<UserDetail />} />
      <Route path="badge/:badgeId" element={<BadgeDetail />} /> */}
      <Route path="donor-list/:donorId" element={<DonorDetail />} />
      <Route path="blood-needs/:bloodNeedId" element={<BloodNeedDetail />} />
      <Route path="edit-blood-need/:id" element={<Edit />} />
      <Route path="edit-donor-list/:id" element={<EditDonor />} />
      <Route path="edit-blog/:id" element={<EditBlog />} />
      <Route path="edit-page/:id" element={<EditPage />} />
      <Route path="add-blog/" element={<AddBlog />} />
      <Route path="add-page/" element={<AddPage />} />
      {/* <Route path="reviews" element={<Reviews />} />
      <Route path="feedback" element={<Feedback />} />
      <Route path="feedback/:id" element={<FeedbackDetail />} /> */}
    </Routes>
  );
};

export default ModeratorRoutes;
