import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { api } from "../Api";
import { BASEURL } from "../Url";
const qs = require("qs");

const initialState = {
  roles: [],
  loading: false,
  error: "",
};

// TODO: get roles
export const getRoles = createAsyncThunk("getRoles", async () => {
  const res = await api.get(`${BASEURL}/users-permissions/roles?populate=*`);
  return res.data;
});

// TODO: post Roles
export const postRoles = createAsyncThunk(
  "postRoles",
  async (data, thunkApi) => {
    const res = await api.post(`${BASEURL}/users-permissions/roles`, data);
    thunkApi.dispatch(getRoles());
    return res.data;
  }
);

// TODO: update Roles

export const updateRoles = createAsyncThunk(
  "updateRoles",
  async ({ formData, id }, thunkApi) => {
    const res = await api.put(
      `${BASEURL}/users-permissions/roles/${id}`,
      formData
    );
    thunkApi.dispatch(getRoles());
    return res.data;
  }
);

// TODO: delete Roles

export const deleteRoles = createAsyncThunk(
  "deleteRoles",
  async (id, thunkApi) => {
    const res = await api.delete(`${BASEURL}/users-permissions/roles/${id}`);
    thunkApi.dispatch(getRoles());
    return res.data;
  }
);
const roleSlice = createSlice({
  name: "role",
  initialState,
  extraReducers: {
    // TODO: get
    [getRoles.pending]: (state, action) => {
      state.loading = true;
    },
    [getRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.roles = action.payload;
    },
    [getRoles.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: post
    [postRoles.pending]: (state, action) => {
      state.loading = true;
    },
    [postRoles.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Role Added",
        confirmButtonColor: "#4BB543",
        text: "Role is successfully added",
      });
    },
    [postRoles.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: Update
    [updateRoles.pending]: (state, action) => {
      state.loading = true;
    },
    [updateRoles.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Role Updated",
        confirmButtonColor: "#4BB543",
        text: "Role is successfully updated",
      });
    },
    [updateRoles.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: Delete
    [deleteRoles.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteRoles.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Role Deleted",
        confirmButtonColor: "#4BB543",
        text: "Role is successfully deleted",
      });
    },
    [deleteRoles.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },
  },
});

export default roleSlice.reducer;
