import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { api } from "../Api";
import { BASEURL } from "../Url";
const qs = require("qs");

const initialState = {
  loading: false,
  error: "",
};

// TODO: delete file

export const deleteFile = createAsyncThunk(
  "deleteFile",
  async ({ fileId, Id, dis, from, params }, thunkApi) => {
    const res = await api.delete(`${BASEURL}/upload/files/${fileId}`);

    if (
      from === "getBloodNeedsDetail" ||
      from === "getDonorDetail" ||
      from === "getBlogDetail"
    ) {
      thunkApi.dispatch(dis(Id));
    } else {
      thunkApi.dispatch(dis({ params }));
    }
    return res.data;
  }
);

const fileDeleteSlice = createSlice({
  name: "files",
  initialState,
  reducers: {},
  extraReducers: {
    // TODO: Delete
    [deleteFile.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteFile.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteFile.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },
  },
});

export default fileDeleteSlice.reducer;
