import { Avatar, Table } from "antd";
import React, { useEffect, useState } from "react";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useNavigate } from "react-router-dom";

const JoinerTable = ({ eventJoiner, isloading }) => {

  const navigate = useNavigate()


  console.log(eventJoiner, "evnt ")

  const [params, setParams] = useState({
    page: 1,
    pageSize: 8,
  });
  let datasource = eventJoiner?.data?.map((i, index) => ({
    key: i,
    id: i?.id,
    userId: i?.attributes?.user?.data?.id,
    username: i?.attributes?.user?.data?.attributes?.username,
    email: i?.attributes?.user?.data?.attributes?.email,
    gender: i?.attributes?.user?.data?.attributes?.gender || "--",
    location: `${i?.attributes?.user?.data?.attributes?.currentAddress?.city}- ${i?.attributes?.user?.data?.attributes?.currentAddress?.wardNo}, ${i?.attributes?.user?.data?.attributes?.currentAddress?.district}`,
    phone: i?.attributes?.user?.data?.attributes?.phone,
    avatarUrl: i?.attributes?.user?.data?.attributes?.avatar?.data?.attributes?.url
  }));

  const columns = [
    {
      title: "Name",
      key: "username",
      // dataIndex: "username",
      width: "25%",
      render: (row) => {
        return (
          <div
            style={{
              display: "flex",
              gap: '0.5rem',
              alignItems: "center",
            }}

            onClick={() => navigate(`/dashboard/users/${row.id}`)}
          >
            <Avatar src={row?.avatarUrl} />
            <span>{row?.username}</span>
          </div >
        );
      },
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      width: "15%",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      filters: [
        {
          text: "Male",
          value: "Male",
        },
        {
          text: "Female",
          value: "Female",
        },
        {
          text: "Other",
          value: "Other",
        },
      ],
      onFilter: (value, record) => record?.gender === value,
    },
    {
      title: "Phone No.",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
  ];


  return (
    <>
      <Table
        loading={isloading}
        dataSource={datasource}
        bordered
        columns={columns}
        pagination={{
          showTitle: false,
          responsive: true,
          prevIcon: <GrLinkPrevious />,
          nextIcon: <GrLinkNext />,
          defaultCurrent: 1,
          current: params.page,
          total: eventJoiner?.meta?.pagination?.total,
          pageSize: params.pageSize,
          onChange: (page) => {
            setParams({
              ...params,
              page,
            });
          },
        }}
      />
    </>
  );
};

export default JoinerTable;
